import React, { useState, useEffect } from "react";
import {motion} from "framer-motion";
import { doc, updateDoc } from "firebase/firestore";
import {getAuth, updateProfile} from "firebase/auth";
import {db} from "../../firebase/firebase.utils.js";
import {useFormik} from "formik";
import * as Yup from "yup";

import BeatLoader  from "react-spinners/BeatLoader";
import PuffLoader  from "react-spinners/PuffLoader";


function OnboardingStepOne( {onNext}) {
  const auth = getAuth();
  const user = auth.currentUser;
  const [onboardingOption, setOnboardingOption] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const [transitionLoading, setTransitionLoading] = useState(false);

  useEffect(() => {
    setTransitionLoading(true)
    setTimeout(() => {
      setTransitionLoading(false)
    }, 1500)
  }, [])


  const initialValues = {
    username: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(6, "Username must be at least 6 characters")
      .max(50, "Username can't be longer than 50 characters")
      .required(`Make sure you choose a name with at least 6 characters!`),
  });

  const handleUpdate = async () => {
    const onboardingRef = doc(db, "users", `${auth.currentUser.uid}`);
    try {
      await updateDoc(onboardingRef, {
        username: onboardingOption,
      });
      await updateProfile(user, {
        displayName: onboardingOption,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    setOnboardingOption(event.target.value);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const handleContinue = () => {
    setLoading(true); // Set loading state to true

    // Simulate 2-second delay
    setTimeout(async () => {
      await handleUpdate();
      await onNext();
      setLoading(false); // Reset loading state
    }, 500);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleContinue();
    }
  };

return (
<div className="flex flex-col h-full bg-white">

{

transitionLoading ? 
    <div className="flex flex-col justify-center items-center h-full min-h-[500px]">
      <PuffLoader color={'#848717'} loading={transitionLoading} size={50} /> 
    </div> :

  <motion.div     initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={{ delay: 0.2 }} className="min-h-[700px]">
    <div className="flex flex-col justify-center items-center flex-grow h-full min-h-[500px]">
    <div className="text-center">
      {/* Emoji */}
      <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl">😃✍️</span>
      {/* Title */}
      <h1 className="text-center font-headers font-semibold text-black text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mt-2">
        What is your preferred name?
      </h1>
      {/* Input */}
      <div className="flex flex-row flex-wrap justify-center items-center">
        <div className="flex flex-col text-center items-center">
          <label
            htmlFor="username"
            className="rounded mt-4 w-full m-2 border-2 border-gray"
          >
            <input
              type="text"
              id="username"
              name="username"
              {...formik.getFieldProps('username')}
              onChange={handleInputChange}
              value={onboardingOption}
              onKeyDown={handleKeyDown}
              className="p-2 bg-white rounded text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-full focus-outline:none"
            />
          </label>
          <div>
            {/* {formik.touched.username && formik.errors.username && (
              <div className="text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg text-red font-semibold">
                {formik.errors.username}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  </div>

    {/* Bottom Portion */}
    <div className="border-t-2 border-darkgray min-h-[90px] w-full">
      <div className="hidden flex flex-row justify-between mx-auto w-2/3">
        <button
          className={!onboardingOption? "mt-4 capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-gray font-bold hover:text-gray border border-darkgray rounded" : "mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-green font-bold hover:text-white border border-green hover:border-green hover:bg-green rounded"}
          disabled={!onboardingOption}
          onClick={() => { onNext() }}
        >
          Skip
        </button>
      </div>
      <div className="flex flex-row justify-between mx-auto w-2/3">
      <button
        className={!onboardingOption || loading ? "w-36 h-12 mt-4 capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block font-body text-center text-gray font-bold hover:text-gray border border-darkgray rounded flex items-center justify-center" : "w-36 h-12 mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block font-body text-center text-green font-bold hover:text-white border border-green hover:border-green hover:bg-green rounded flex items-center justify-center"}
        disabled={!onboardingOption || loading} // Disable if loading
        onClick={handleContinue} // Call the handleContinue function
      >
        {loading ? <BeatLoader color={'#848717'} loading={loading} size={6} /> : 'Continue'} {/* Show loading text if loading */}
      </button>
      </div>
    </div>
  </motion.div>
  
}
</div>
)
}

export default OnboardingStepOne;
