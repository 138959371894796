import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation} from 'react-router-dom';
import Dashboard from "../../pages/dashboard/dashboard.component.jsx";
import NotFound from "../../pages/404/404.jsx";
import HomePage from "../../pages/homepage/homepage.component.jsx";
import Dictionary from "../../pages/dictionary/dictionary.component.jsx";
import About from "../../pages/about/about.component.jsx";
import Articles from "../../pages/articles/articles.component.jsx";
import ZuluRoadMap from "../../pages/articles/2022/isizulu-roadmap-2022.jsx";
import ImportanceOfLanguageLearning from "../../pages/articles/2023/importance-of-learning-african-languages.jsx"; 
import LanguageLearningBrainBenefits from "../../pages/articles/2023/how-language-learning-benefits-your-brain.jsx";
import LanguageLearningCulturalPreservation from "../../pages/articles/2023/role-of-language-in-preserving-culture.jsx";
import GuideToCommonPhrases from "../../pages/articles/2023/guide-to-common-phrases.jsx";
import Top5Myths from "../../pages/articles/2023/top-5-myths-about-language.jsx";
import IncorporatingLanguageLearning from "../../pages/articles/2023/how-to-incorporate-language-learning.jsx";
import Privacy from "../../pages/privacy/privacy.component.jsx";
import Terms from "../../pages/terms/terms.component.jsx";
import ContentPage from "../../pages/content/content.component.jsx";
import OnboardingPage from "../../pages/onboarding/onboarding.component.jsx";
import LoginPage from "../../pages/login/login.component";
import RegisterPage from "../../pages/register/register.component.jsx";
import Sessions from "../../pages/sessions/sessions.component.jsx";
import ProfilePage from "../../pages/profile/profile.component"
import Settings from "../../pages/settings/settings.component.jsx";
import CoursePage from "../../pages/courses/courses-page.component.jsx";
import Pricing from "../../pages/pricing/pricing.component";
import SandboxPage from "../../pages/sandbox/sandbox.page.jsx";
import CheckoutPage from "../../pages/checkout/checkout.page.jsx"
import CheckoutSuccess from "../../pages/checkout/checkout-success.page.jsx"
import CheckoutFailure from "../../pages/checkout/checkout-failure.page.jsx"
import CheckoutNotify from "../../pages/checkout/checkout-notify.page.jsx"
import ProtectedRoute from "../protected-route/protected-route.component.jsx";
import FFAQ from "../../pages/ffaqs/ffaqs.page.jsx";
import {AnimatePresence } from "framer-motion";
import { getAuth } from "firebase/auth";

function AnimatedRoutes() {
    const domicile = useLocation();
    const auth = getAuth();
    const user = auth.currentUser;
    const [membership, setMembership] = useState(null);
    
    useEffect(() => {
        // Make sure to handle this asynchronously
        const fetchMembership = async () => {
            if (user && user?.reloadUserInfo?.customAttributes) {
              const membership = JSON.parse(user?.reloadUserInfo?.customAttributes)?.membership
              setMembership(membership);
            } else {
              return null
            }
          }
        fetchMembership()
      }, [user]);
    
    return (
        <AnimatePresence>
            <Routes location={domicile} key={domicile.pathname}>
                <Route path="/" element={
                        <HomePage />
                } 
                />
                <Route path="/about" element={
                        <About />
                } 
                />
                <Route path="/articles" element={
                        <Articles />
                } 
                />
                <Route path="/isizulu-roadmap-2022" element={
                        <ZuluRoadMap />
                }
                /> 
                <Route path="/importance-of-african-languages" element={
                        <ImportanceOfLanguageLearning />
                } 
                />
                <Route path="/benefits-for-your-brain" element={
                        <LanguageLearningBrainBenefits />
                } 
                />
                <Route path="/preserving-african-cultures" element={
                        <LanguageLearningCulturalPreservation />
                } 
                />
                <Route path="/common-phrases-in-isizulu" element={
                        <GuideToCommonPhrases />
                } 
                />
                <Route path="/language-learning-myths-debunked" element={
                        <Top5Myths />
                } 
                />
                <Route path="/language-learning-in-busy-schedule" element={
                        <IncorporatingLanguageLearning />
                } 
                />
                <Route path="/pricing" element={
                        <Pricing />
                } 
                />
                <Route path="/privacy" element={
                        <Privacy />
                } 
                />
                <Route path="/terms" element={
                        <Terms />
                } 
                />
                <Route path="/login" element={
                       <LoginPage />
                } 
                />
                <Route path="/register" element={
                    <ProtectedRoute>
                        {<RegisterPage />}
                    </ProtectedRoute>
                } 
                />
                <Route
                path="/dashboard"
                element={
                    <ProtectedRoute>
                        {
                            <Dashboard />
                        }
                    </ProtectedRoute>
                }
                />
                <Route
                path="/content"
                element={
                    <ProtectedRoute>
                        {
                            <ContentPage />
                        }
                    </ProtectedRoute>
                }
                />
                <Route path="/onboarding" element={
                    <ProtectedRoute>
                        {
                           <OnboardingPage />
                        }
                    </ProtectedRoute>
                } />
                {/* <Route path="/sessions" element={
                    <ProtectedRoute>
                        {
                            <Sessions />
                        }
                    </ProtectedRoute>
                } /> */}
                <Route path="/dictionary" element={
                    <ProtectedRoute>
                        {
                            <Dictionary />
                        }
                    </ProtectedRoute>
                } />
                <Route path="/profile" element={
                    <ProtectedRoute>
                        {
                            <ProfilePage />
                        }
                    </ProtectedRoute>
                } />
                <Route path="/settings" element={
                    <ProtectedRoute>
                        {
                            <Settings />
                        }
                    </ProtectedRoute>                    
                    } 
                />
                <Route path="/ffaqs" element={<FFAQ />} />
                <Route path="/courses" element={
                    <ProtectedRoute>
                        {
                            <CoursePage />
                        }
                    </ProtectedRoute>
                } />
                <Route
                path="/sandbox"
                element={
                <ProtectedRoute>
                    {<SandboxPage />}
                </ProtectedRoute>
                }
                />
                {/* {membership === "guest" || membership === "george"  ? <Route
                path="/checkout"
                element=
                    {
                        <ProtectedRoute>
                            {<CheckoutPage />}
                        </ProtectedRoute>
                    }
                /> : null} */}
                {/* <Route
                path="/checkout-success"
                element={
                    <ProtectedRoute>
                    {
                        <CheckoutSuccess />
                    }
                </ProtectedRoute>
                }
                />
                <Route
                path="/checkout-cancel"
                element={
                    <ProtectedRoute>
                    {
                        <CheckoutFailure />
                    }
                </ProtectedRoute>
                }
                />
                <Route
                path="/checkout-notify"
                element={
                    <ProtectedRoute>
                    {
                        <CheckoutNotify />
                    }
                </ProtectedRoute>
                }
                /> */}
                <Route path="*" element={<NotFound />} />
            </Routes> 
        </AnimatePresence>
    )
}

export default AnimatedRoutes