import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";

function NewsFeedCard() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    setUser(auth.currentUser);

    // Optional: If you want to listen for auth changes, you can do so as well
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Cleanup
    return () => unsubscribe();
  }, []);  // Empty dependency array means this useEffect runs once when the component mounts

  return (
    <div className="mx-12 flex flex-col justify-start items-center w-full mt-4 my-4 p-8 bg-white border border-darkgray shadow-2xl rounded african-pattern">
      <div className="w-5/12 m-auto text-center">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">👩🏾‍🏫 👨🏽‍🏫</div>
      </div>
      <div className="pt-1 pr-2 ">
        <h1 className="font-headers text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-black text-center">
          <span className="font-semibold">🔥Your Pan-African Pilgrimage Starts Now!</span>{" "}
        </h1>
        <h3 className="text-sm sm:text-md md:text-lg lg:text-xl text-center font-italic text-black font-light">
          Welcome to our Edutainment Vault {user && user.displayName ? (
            <span className="capitalize"> <b>{user.displayName}</b>!</span>
          ) : null} 📚🎉 Join us and explore original edutainment content that will help you master African languages, culture, and stories at your own pace. 🚀🌱 Whether you're a beginner or a seasoned learner, our diverse collection of resources will engage and inspire you. Let's embark on this educational adventure together.
        </h3>
      </div>
    </div>
  );
}

export default NewsFeedCard;
