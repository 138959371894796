import {default as bot} from "../assets/imgs/flags/bot-gray.png";
import {default as gha} from "../assets/imgs/flags/gha-gray.png";
import {default as ken} from "../assets/imgs/flags/ken-gray.png";
import {default as les} from "../assets/imgs/flags/les-gray.png";
import {default as mw} from "../assets/imgs/flags/mw-gray.png";
import {default as nam} from "../assets/imgs/flags/nam-gray.png";
import {default as nig} from "../assets/imgs/flags/nig-gray.png";
import {default as rsa} from "../assets/imgs/flags/rsa.png";
import {default as rsatwo} from "../assets/imgs/flags/rsa-gray.png";
import {default as tan} from "../assets/imgs/flags/tan-gray.png";
import {default as zam} from "../assets/imgs/flags/zam-gray.png";
import {default as zim} from "../assets/imgs/flags/zim-gray.png";

export const Courses = [
  {
    id: 1,
    name: "isiZulu",
    flag: rsa,
    path: "/onboardingtwo",
    status: "available"
  },
  // {
  //   id: 2,
  //   name: "isiXhosa",
  //   flag: rsatwo,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 3,
  //   name: "Sepedi",
  //   flag: rsatwo,
  //   path: "#",
  //   status: "unavailable"
  // },
  {
    id: 4,
    name: "Chichewa",
    flag: mw,
    path: "#",
    status: "unavailable"
  },
  // {
  //   id: 5,
  //   name: "Sesotho",
  //   flag: les,
  //   path: "#",
  //   status: "unavailable"
  // },
  {
    id: 6,
    name: "Kiswahilli",
    flag: tan,
    path: "#",
    status: "unavailable"
  },
  // {
  //   id: 7,
  //   name: "Ovambo",
  //   flag: nam,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 8,
  //   name: "Shona",
  //   flag: zim,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 9,
  //   name: "Bemba",
  //   flag: zam,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 10,
  //   name: "Igbo",
  //   flag: nig,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 11,
  //   name: "Yoruba",
  //   flag: nig,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 12,
  //   name: "Fante",
  //   flag: gha,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 13,
  //   name: "Twi",
  //   flag: gha,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 14,
  //   name: "Kikuyu",
  //   flag: ken,
  //   path: "#",
  //   status: "unavailable"
  // },
  // {
  //   id: 15,
  //   name: "Setswana",
  //   flag: bot,
  //   path: "#",
  //   status: "unavailable"
  // }
];
