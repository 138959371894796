import React, { useState, useContext } from 'react';
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { getAuth } from "firebase/auth";

import { LearningMaterialsContext } from '../../helpers/contexts';
import { LMContext } from '../../helpers/context3';
import { serverTimestamp } from 'firebase/firestore';


const AnswerFeedbackModal = () => {
  const auth = getAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false); // Add a loading state
  const { learningMaterialId } = useContext(LMContext);
  const { learningMaterialState } = useContext(LearningMaterialsContext);
  const timestamp = serverTimestamp();
  const [submitMessage, setSubmitMessage] = useState('');


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedOptions("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const feedbackRef = collection(db, 'feedback');
      const feedbackData = {
        userId: `${auth.currentUser.uid}`,
        learningMaterialId: learningMaterialId,
        learningMaterialState: learningMaterialState,
        options: selectedOptions,
        timestamp: timestamp,
      };
      await addDoc(feedbackRef, feedbackData);
      setSubmitMessage('Thank you! Your feedback has been received!');
    } catch (error) {
      console.error('Error creating feedback:', error);
      setSubmitMessage('An error occurred while submitting your feedback.');
    }
  };

  const formSubmission = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await handleSubmit(event);  // Submit the form and wait for it to complete
    } catch (error) {
      console.error('Error submitting form:', error);
    }

    setTimeout(() => {
      setLoading(false);
      setSubmitMessage('');
      closeModal();
    }, 500)
  }

  const handleOptionChange = (event) => {
    const option = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
    }
  };

  return (
    <div className=''>
      <div
        className="feedback-icon rounded cursor-pointer"
        onClick={openModal}
      >
        <span className="font-body text-white text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg uppercase font-semibold hover:text-green">Feedback</span>
      </div>
      {isOpen && (
        <div className="modal fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="modal-content bg-white rounded p-6 max-w-md">
            <span className="close absolute top-4 right-4 text-gray-500 text-4xl bg-white cursor-pointer border-2 rounded px-3" onClick={closeModal}>&times;</span>
          {!submitMessage ?
            <form onSubmit={(event) => formSubmission(event)}>
            <label>
                <input
                  type="checkbox"
                  name="feedback-option"
                  value="The audio is missing."
                  checked={selectedOptions.includes('The audio is missing.')}
                  onChange={handleOptionChange}
                  className="mt-2"
                />
                <b> There isn't any audio</b> for the content.
              </label>
              <br/>
              <label>
                <input
                  type="checkbox"
                  name="feedback-option"
                  value="My answer should be accepted."
                  checked={selectedOptions.includes('My answer should be accepted.')}
                  onChange={handleOptionChange}
                  className="mt-2"
                />
                <b> My answer</b> is the correct option.
              </label>
              <br/>
              <label>
                <input
                  type="checkbox"
                  name="feedback-option"
                  value="My screen keeps freezing or crashing."
                  checked={selectedOptions.includes('My screen keeps freezing or crashing.')}
                  onChange={handleOptionChange}
                  className="mt-2"
                />
                <span> My screen</span><b> keeps freezing or crashing.</b> 
              </label>
              <br/>
              <label>
                <input
                  type="checkbox"
                  name="feedback-option"
                  value="There is a spelling or grammar mistake."
                  checked={selectedOptions.includes('There is a spelling or grammar mistake.')}
                  onChange={handleOptionChange}
                  className="mt-2"
                />
                  <span> There</span> is a <b> spelling or grammar mistake.</b>
              </label>
              <br/>
              <label>
                <input
                  type="checkbox"
                  name="feedback-option"
                  value="Something else went wrong."
                  checked={selectedOptions.includes('Something else went wrong.')}
                  onChange={handleOptionChange}
                  className="mt-2"
                />
                <b> Something else</b> went wrong.
              </label>
              <input
                disabled={!selectedOptions.length || loading} // Disable if loading or no options selected
                className='disabled:text-white disabled:bg-gray disabled:cursor-not-allowed mt-2 min-w-1/3 capitalize cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-2 py-2 m-2 font-bold disabled:border-gray text-white bg-green rounded'
                type="submit"
                value={loading ? "Loading..." : "Submit"} // Show "Loading..." if loading
              />
              {/* {loading && <BeatLoader color={'#848717'} loading={loading} size={6} />} BeatLoader */}
            </form> : <div className="message text-green-500">{submitMessage}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerFeedbackModal;
