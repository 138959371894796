import React, {useState, useEffect} from "react";
import { PuffLoader } from "react-spinners";
import {motion} from "framer-motion";

import ZuluBooking from "../../components/sessions/isizulu-booking.component";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";

import RegisterOverlay from "../../components/login-register/register-overlay.component";
import { getAuth } from "firebase/auth";

function Sessions() {
  const [loading, setLoading] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  const [membership, setMembership] = useState(null);

  useEffect(() => {
    document.title = 'Sessions | Fast Fluency Africa';
  }, []);
  
  useEffect(() => {
    // Make sure to handle this asynchronously
    const fetchMembership = async () => {
      if (user && user?.reloadUserInfo?.customAttributes) {
        const membership = JSON.parse(user?.reloadUserInfo?.customAttributes)?.membership
        setMembership(membership);
      } else {
        return null
      }
    }
  fetchMembership()

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [user]);

  useEffect(() => {
    const userDoc = doc(db, 'users', `${auth?.currentUser?.uid}`);

    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Assuming `membership` is a field in the user's Firestore document
        setMembership(docSnapshot.data().membership);
      }
    });

    // Cleanup
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    loading ? (
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} />
      </div>
    ) : 
    (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.2 }} className="bg-white h-screen">
        <NavigationBar />
        {membership === "guest" ? <RegisterOverlay/> : <ZuluBooking />}
      </motion.div>
    )
  );
}

export default Sessions;
