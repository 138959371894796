import React from "react";

import { TestimonialData } from "../../zombiedata/testimonials";

import { useState, useRef, useEffect } from "react";

import {motion} from "framer-motion";



function Testimonials() {
    const [width, setWidth] =  useState(0);
    const carousel = useRef();
    
    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
    }, []);

    return (
        <motion.div ref={carousel} className="hover:cursor-grab overflow-hidden mx-10 mt-6" whileTap={{cursor: "grabbing"}} whileHover={{cursor: "grab"}}>
            <motion.div drag="x" dragConstraints={{right: 0, left: -width }} className="cursor-grab bg-otherwhite" >
                <div className="inline-flex">
                    {TestimonialData.map((item, index) => {
                        return (
                            <div key={index} className="min-w-1/3 m-2 p-2">
                                <div className="bg-otherwhite">
                                    <img src={item.image} key={index} alt="" className="h-1/3 w-full opacity-100 rounded" />
                                    <div className="font-body font-light text-center p-2 m-2">
                                        <p className="italic font-regular text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">{item.quotes}</p>
                                    </div>
                                    <div className="flex flex-col justify-items items-center font-body font-semibold text-center p-2 m-2">
                                        <span className="font-bold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">{item.customer}</span>
                                        <span className="font-bold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">{item.nationality}</span>
                                        <span className="font-semibold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">{item.product}</span>
                                        <span className="font-semibold text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">{item.year}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </motion.div>
        </motion.div>
    )
}

export default Testimonials