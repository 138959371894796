import React from "react";

import {useState, useContext, useEffect} from "react";

import Results from "../../components/dictionary/results.component.jsx";

import * as IoIcons from "react-icons/io5";

import {InputContext} from "../../helpers/context3.js";

import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils.js";

import {motion} from "framer-motion";
import RegisterOverlay from "../../components/login-register/register-overlay.component.jsx";
import { getAuth } from "firebase/auth";
import { debounce } from "lodash";


function Dictionary() {

  useEffect(() => {
    document.title = 'Dictionary | Fast Fluency Africa';
  }, []);

    const [value, setValue] = useState("");
    const { inputValue, setInputValue, resultsLoading, setResultsLoading} = useContext(InputContext);
    const auth = getAuth();
    const user = auth.currentUser
    const [membership, setMembership] = useState(null);
  
    useEffect(() => {
      // Make sure to handle this asynchronously
      const fetchMembership = async () => {
        if (user && user?.reloadUserInfo?.customAttributes) {
          const membership = JSON.parse(user?.reloadUserInfo?.customAttributes)?.membership
          setMembership(membership);
        } else {
          return null
        }
      }
    fetchMembership()
  
      setTimeout(() => {
      }, 1000);
    }, [user]);

    useEffect(() => {
      const userDoc = doc(db, 'users', `${auth?.currentUser?.uid}`);
  
      const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
        if (docSnapshot.exists()) {
          // Assuming `membership` is a field in the user's Firestore document
          setMembership(docSnapshot.data().membership);
        }
      });
  
      // Cleanup
      return () => {
        unsubscribe();
      };
    }, []);

    const allLoader = () => {
      setResultsLoading(true);
      setTimeout(() => {
        setResultsLoading(false)
      }, 2000)
    } 

    const handleSubmit = () => {
      allLoader();
      setInputValue(value);
      setValue(value);
    }
    
    const debouncedSetInputValue = debounce(async (value) => {
      await setResultsLoading(true);
      console.log(resultsLoading);
      await setInputValue(value); // Make sure to await or handle this asynchronously
      await setResultsLoading(false);
    }, 500);


    const handleInputChange = e => {
      const newValue = e.target.value.toLowerCase().trim();
      allLoader();
      setValue(newValue);
      debouncedSetInputValue(newValue); // using debounced function here
    };

    const handleInputKeyDown = (e) => {
      if (e.key === 'Enter') {
        allLoader();
        setInputValue(value);
        setValue(value);
      }
    }

  return (

    <motion.div className="bg-white flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <div className="w-full">
        <NavigationBar />
      </div>
      {membership === "guest" ? <RegisterOverlay/> : <div className="flex flex-col items-center justify-between h-2/5">
        <div className="flex flex-col justify-center items-center relative w-screen " style={{height: "240px"}}>
          <div className="flex flex-col justify-center items-center w-5/6 sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
            <div className="justify-center items-center flex flex-col w-full">
              <div className="mt-8 text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">📖🔍</div>
              <h1 className="text-black text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-semibold font-headers uppercase">Dictionary</h1>
              <br />
                <div className="flex flex-row justify-center items-center w-full mt-4">
                  <div className="flex flex-row justify-center items-center border-black border-2 bg-white shadow-2xl rounded px-4 w-full">
                    <IoIcons.IoSearch className="w-6 h-7 text-black"></IoIcons.IoSearch>
                    <input onKeyDown={handleInputKeyDown} onChange={handleInputChange}  type="text" placeholder="Search for words..." className="outline-none font-light bg-transparent text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl border-white hover:border-white border-2 rounded active:opacity-100 p-2 px-1 w-full mx-2" />
                  </div>
                    <button onClick={handleSubmit} className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl border-black border-2 text-black hover:border-white hover:text-white hover:bg-black cursor-pointer rounded text-left mx-2 px-6 m-1 py-3 font-bold font-headers uppercase shadow-xl">Search</button>
                </div>
            </div>
          </div>
        </div>
        <div className={ `${inputValue !== null ? "display min-h-full w-full" :  "hidden min-h-full" } `}>
          <div className={ `${inputValue !== null ? "display min-h-full w-full" :  "hidden min-h-full" } `}>
              <Results />
          </div>        
        </div>
      </div>}
    </motion.div>
    )
}

export default Dictionary;