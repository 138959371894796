import React, {useState, useContext, useEffect} from "react";
import {db} from "../../../firebase/firebase.utils.js";

import {LMContext, optionChosenContext} from "../../../helpers/context3.js";
import { ModalContext } from "../../../helpers/modalcontext";
import {motion} from "framer-motion";

import BeatLoader from "react-spinners/BeatLoader";

import { collection, getDocs } from "firebase/firestore"; 


function MultipleChoiceQuestions() {
  const {learningMaterialId} = useContext(LMContext);
  const [exercises, setExercises] = useState([]);
  const {optionChosen, setOptionChosen} = useContext(optionChosenContext);
  const {modal} = useContext(ModalContext);
  const exercisesRef = collection(db, "exercises");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getExercises = async () => {
      const data = await getDocs(exercisesRef);
      setExercises(data.docs.map(doc => ({...doc.data(), id: doc.id})));
    };
    getExercises();
  });

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  return (
    <motion.div className="h-full bg-white w-full m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
    {loading ? 
        <div className="flex flex-col justify-center items-center h-full min-h-[500px]">
          <BeatLoader color={'#848717'} loading={loading} size={15} /> 
        </div> :
      <div className={modal !== true ? "flex flex-col justify-start  items-center text-black min-h-[500px]" : "hidden"} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.1}}>
        <div className="text-5xl">❎ | ✅</div>
        {exercises.filter(exercises => exercises.day === learningMaterialId && exercises.type === "mcq")
        .map((exercises) => {
            return (
              <h1 className="font-headers font-bold text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mt-4">
                {/* {exercises.title.toUpperCase()} */}
              </h1>
            );
        })}
        {exercises.filter(exercises => exercises.day === learningMaterialId && exercises.type === "mcq")
        .map((exercises) => {
            return (
              <h3 className="font-headers font-semibold text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mt-8 text-center px-1 normal-case">
                {exercises.question.toUpperCase()}
              </h3>
            );
        })}
        <form
          action=""
          className="w-4/4 flex flex-col justify-start items-center min-h-[340px]"
        > 
          <div className="flex w-4/4 flex-col text-center items-center justify-center">
            <div className="">
              {exercises.filter(exercises => exercises.day === learningMaterialId && exercises.type === "mcq")
        .map((exercises, index) => {
                  return (
                    <label key={index}>
                      <input
                        disabled={optionChosen === "disabled" ? true : false}
                        className="mt-4 capitalize bg-white text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body shadow-lg text-center rounded px-2 py-4 m-2 border border-black cursor-pointer focus:border-green focus:border-1 focus:text-green hover:text-gray hover:border-gray"
                        id="answer"
                        onClick={() => setOptionChosen(exercises.options[0])}
                        type="button"
                        value={exercises.options[0].toUpperCase()}
                      />
                    </label>
                  );
              })}
            </div>
            <div className="">
              {exercises.filter(exercises => exercises.day === learningMaterialId && exercises.type === "mcq")
        .map((exercises, index) => {
                  return (
                    <label key={index}>
                      <input
                        disabled={optionChosen === "disabled" ? true : false}
                        className="mt-4 capitalize bg-white text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body shadow-lg text-center rounded px-2 py-4 m-2 border border-black cursor-pointer focus:border-green focus:border-1 focus:text-green hover:text-gray hover:border-gray"
                        id="answer"
                        onClick={() => setOptionChosen(exercises.options[1])}
                        type="button"
                        value={exercises.options[1].toUpperCase()}
                      />
                    </label>
                  );
              })}
            </div>
            <div className="">
              {exercises.filter(exercises => exercises.day === learningMaterialId && exercises.type === "mcq")
        .map((exercises, index) => {
                  return (
                    <label key={index}>
                      <input
                        disabled={optionChosen === "disabled" ? true : false}
                        className="mt-4 capitalize bg-white text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body shadow-lg text-center rounded px-2 py-4 m-2 border border-black cursor-pointer focus:border-green focus:border-1 focus:text-green hover:text-gray hover:border-gray"
                        id="answer"
                        onClick={() => setOptionChosen(exercises.options[2])}
                        type="button"
                        value={exercises.options[2].toUpperCase()}
                      />
                    </label>
                  );
              })}
            </div>
            <div className="">
              {exercises.filter(exercises => exercises.day === learningMaterialId && exercises.type === "mcq")
        .map((exercises, index) => {
                  return (
                    <label key={index}>
                      <input
                        disabled={optionChosen === "disabled" ? true : false}
                        className="mt-4 capitalize bg-white text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body shadow-lg text-center rounded px-2 py-4 m-2 border border-black cursor-pointer focus:border-green focus:border-1 focus:text-green hover:text-gray hover:border-gray"
                        id="answer"
                        onClick={() => setOptionChosen(exercises.options[3])}
                        type="button"
                        value={exercises.options[3].toUpperCase()}
                      />
                    </label>
                  );
              })}
            </div>
          </div>
          <hr />
          <br />
        </form>
      </div>}
    </motion.div>
  );
}

export default MultipleChoiceQuestions;
