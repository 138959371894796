import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import Footer from "../../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../../components/navigation-bar/navbar-primary.component";

import {motion} from "framer-motion";

import {default as fire} from "../../../assets/imgs/blog/campfire-digital-art.png";

import { Link } from "react-router-dom";


function Top5Myths() {

  useEffect(() => {
    document.title = 'Top 5 Myths About Language Learning Debunked | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">Top 5 Myths About Language Learning Debunked</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container max-w-5xl mx-auto mt-16">
            <div className="ps-media mt-6">
                <div className="flex items-center justify-center flex-col">
                    <div className="ps-media-img-container">
                    <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                    </a>
                    </div>
                    <div id="about-us mt-6 p-8 flex items-center justify-center">
                    <p className="m-auto w-12/12 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl px-2 font-headers font-bold">
                    Top 5 Myths About Language Learning Debunked
                    </p>
                    <p className="w-12/12 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-2 font-body font-semibold my-3 text-right">Tribe Contributor | June 4th, 2023 ⏱️ 2 min</p>
                    </div>
                    <img src={fire} alt="" width="75%" className="m-auto mt-2" />                    
                    <span className="hidden font-bold  mt-2 sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg 3xl:text-xl italic">Placeholder</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap justify-center items-center">
                <div className="mt-4">
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Language learning is a journey that opens up new worlds, both literally and metaphorically. However, many aspiring language learners often find themselves hesitating, held back by widely circulated myths and misconceptions about the process. If you've been on the fence about taking up a new language, this article aims to debunk the top 5 myths that could be keeping you from enriching your life through language learning.</p>
                </div>
            </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Myth 1: You Must Be Young to Learn a New Language</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">The Reality:</h3>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The idea that language learning is a young person's game is one of the most pervasive myths. While it's true that younger people may have certain advantages, such as better memory retention (1), adults are fully capable of learning a new language and even excelling at it. Adult learners often have stronger self-discipline, clearer objectives, and better analytical skills, which can accelerate the learning process (2).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Myth 2: You Need to Be in a Country Where the Language is Spoken</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">The Reality:</h3>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">While immersion is a powerful tool for language learning (3), it's not the only one. Thanks to the internet and modern technology, you can create an immersive environment right in your own home. Streaming services offer movies and TV shows in various languages, and apps like Duolingo and Rosetta Stone provide structured lessons. You can also engage with native speakers online through platforms like iTalki or language exchange communities (4).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Myth 3: You Must Have a Natural Talent for Languages</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">The Reality:</h3>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The belief that you need to have a "gift" for languages to succeed is not only misleading but also discouraging for many potential learners. Language learning is a skill, much like learning to play an instrument or mastering a sport (5). While some people might have a natural aptitude, consistent practice and exposure are far more critical to becoming proficient (6).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Myth 4: The More Words You Know, The Better</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">The Reality:</h3>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">While having an extensive vocabulary is beneficial (7), it's not the sole indicator of language proficiency. Knowing the rules of grammar, understanding cultural nuances, and being able to engage in conversations are just as important. Quality and context often trump sheer quantity. After all, knowing thousands of words won't help if you can't string a coherent sentence together (8).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Myth 5: You Can Become Fluent in Just a Few Months</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">The Reality:</h3>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The timeline for language learning varies greatly depending on several factors, including the learner's native language, the complexity of the target language, and the amount of time dedicated to practice (9). Programs that promise fluency in a few months often set unrealistic expectations. Becoming proficient in a new language is usually a long-term commitment, but it’s one that offers endless rewards (10).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Why These Myths Persist</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">These myths continue to circulate for various reasons. In some cases, they stem from outdated beliefs or misunderstandings about cognitive science (11). In other cases, they're propagated by marketing tactics that promise quick and easy results (12). Regardless of their origin, it's essential to approach language learning with realistic expectations and a commitment to continual improvement.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Final Thoughts</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Learning a new language is a fulfilling, intellectually stimulating, and often career-enhancing endeavor. Don't let myths and misconceptions hold you back from starting this journey. Equipped with the right information and tools, anyone—regardless of age, location, or prior experience—can learn a new language and enrich their life in countless ways. <br /> <br /> So, throw those myths out the window, and <Link to="/login" className="text-red underline font-semibold cursor-pointer">embark on your language learning adventure today!</Link></p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-∫bold" id="section-two">References</h2>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">1. Lenneberg, E. (1967). Biological Foundations of Language. New York: John Wiley & Sons.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">2. Dörnyei, Z. (2005). The Psychology of the Language Learner. Mahwah, NJ: Lawrence Erlbaum.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">3. Krashen, S. (1982). Principles and Practice in Second Language Acquisition. Oxford: Pergamon.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">4. Lantolf, J. P., & Thorne, S. L. (2006). Sociocultural Theory and the Genesis of Second Language Development. Oxford: Oxford University Press.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">5. DeKeyser, R. (2007). Skill Acquisition Theory. In B. VanPatten & J. Williams (Eds.), Theories in Second Language Acquisition: An Introduction (pp. 97-114). Mahwah, NJ: Lawrence Erlbaum.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">6. Ellis, R. (1994). The Study of Second Language Acquisition. Oxford: Oxford University Press.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">7. Laufer, B. (1998). The Development of Passive and Active Vocabulary in a Second Language: Same or Different? Applied Linguistics, 19(2), 255-271.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">8. Nation, I. S. P. (2001). Learning Vocabulary in Another Language. Cambridge: Cambridge University Press.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">9. Long, M. H. (1996). The role of the linguistic environment in second language acquisition. In W. C. Ritchie & T. K. Bhatia (Eds.), Handbook of Second Language Acquisition (pp. 413-468). San Diego: Academic Press.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">10. Lightbown, P. M., & Spada, N. (2006). How Languages are Learned (3rd ed.). Oxford: Oxford University Press.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">11. Pinker, S. (1994). The Language Instinct. New York: HarperCollins.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">12. Dörnyei, Z., & Ushioda, E. (2011). Teaching and Researching: Motivation (2nd ed.). Harlow: Longman.</p>
                </div>
        </div>
      </body>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
    )
}

export default Top5Myths;