import React, {useState, useContext} from "react";

import { Link } from "react-router-dom";

import { ModalContext } from "../../helpers/modalcontext";


function ExitModal() {

const [toggled] = useState(false);
const {setModal} = useContext(ModalContext);

const closeModal = () => {
    setModal(false);
    document.body.style.overflow = ''; // Enable scrolling
  };


    return(
        <div className="bg-white h-screen">
            <div className={toggled !== true ? "bg-white opacity-100 h-full absolute top-0 left-0" : "hidden"}>
                <div className="w-screen flex flex-col justify-center items-center h-full bg-white ">
                    <div className="text-center">
                        <h1 className="text-7xl">🤨</h1>
                        <h2 className="font-bold font-headers text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">Are you sure you want to quit?</h2>
                        <p className="font-lighter text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-5xl font-body">All your progress from this session will be lost.</p>
                    </div>
                    <div className="flex flex-row justify-between items-center m-2">
                        <div>
                            <label>
                                <input className="mt-4 px-6 my-4 py-2 border-1 rounded w-full text-center cursor-pointer m-auto block pt-2 pb-2 pl-4 pr-4  border-2  text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out font-body font-bold bg-white  text-gray hover:text-gray border-gray hover:border-darkgray hover:bg-darkgray" type="button" value="No" onClick={() => {closeModal()}} />
                            </label>
                        </div>
                        <Link className="mx-2" to="/content">
                            <label>
                                <input className="mt-4 px-6 my-4 py-2 border-1 rounded w-full text-center cursor-pointer m-auto block pt-2 pb-2 pl-4 pr-4  border-2  text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out font-body font-bold bg-green text-white hover:text-white border-green hover:border-greenthree hover:bg-greenthree" type="button" value="Yes" onClick={() => {closeModal()}} />
                            </label>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExitModal