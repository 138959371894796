
import React, {useState} from "react";
import {default as mailoptin} from "../../assets/imgs/5-minute-muti-lrg.jpg";
import {addDoc, collection, serverTimestamp} from "firebase/firestore";
import {db} from '../../firebase/firebase.utils';
import { BeatLoader } from 'react-spinners';


import {useFormik} from "formik";
import * as Yup from "yup";

function MailOptinForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [hovered, setHovered] = useState(false);

  // Function to submit the form data to Firestore and Mailchimp
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);

    try {
      // Add a timestamp to the data before sending it to Firestore
      const dataWithTimestamp = {
        ...values,
        timestamp: serverTimestamp(),
      };

      await addDoc(collection(db, "newsletter"), dataWithTimestamp);

      setSubmissionSuccess(true);
      resetForm({});
      setSubmitting(false);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setSubmitting(false);
      setIsLoading(false);
    }
  };
    
      
    const formik = useFormik({
      initialValues: {
        name: "",
        email: ""
      },
      validationSchema: Yup.object({
        name: Yup.string().required("Required!"),
        email: Yup.string()
          .email("Invalid email address")
          .required("Required!"),
      }),
      onSubmit: handleSubmit,
    });

    return (
        <div className="flex flex-col justify-center items-start bg-white w-10/12">
            <div className="mb-4 w-full">
              <img src={mailoptin} alt="Mail Optin" className="m-auto w-full" />
              <h2 className="text-center text-green text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl my-2 font-headers font-semibold">Join the Lekgotla</h2>
              <p className="text-center text-black text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">
              Unlock the secrets to mastering African languages with our exclusive PDF guide, <b>"The 5 Minute Muti Method,"</b> available for <b>free</b> when you sign up to our Lekgotla. Turn mundane minutes into magical moments with our holistic approach that fuses linguistic learning with cultural wisdom. Never feel lost or overwhelmed again—whether you're diving into the depths of isiZulu, Kiswahilli, or isiXhosa, this guide has got you covered. Subscribe now and begin a journey that transcends mere words. Your key to a richer linguistic experience is just a click away!
              </p>
            </div>
          <div className="bg-white rounded p-4 m-2 flex flex-col w-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <label className="block text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-gray leading-tight focus:outline-none focus:shadow-outline text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl"
                  placeholder="Name"
                />
              </div>
              <div className="mb-6">
                <label className="block text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="shadow appearance-none border rounded w-3/5 py-2 px-3 text-gray leading-tight focus:outline-none focus:shadow-outline text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl"
                  placeholder="Email Address"
                />
              </div>
              <div className="flex items-center justify-start">
                <button
                  type="submit"
                  disabled={isLoading}
                  onMouseEnter={() => setHovered(true)} // Set hovered to true on mouse enter
                  onMouseLeave={() => setHovered(false)} // Set hovered to false on mouse leave
                  className=" box-border mt-2 px-6 py-2 border-1 w-min-1/2 text-center cursor-pointer w-min-lg text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4 hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green text-black font-medium  leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  {isLoading ? <BeatLoader color={hovered ? '#FCFCEE' : '#000000'} size={6} /> : "Subscribe"}
                </button>
              </div>
            </form>
            {error && <p className="text-red text-xs italic mt-2">{error}</p>}
            {submissionSuccess && <p className="text-green text-center text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl italic mt-2">Congratulations! You have successfully subscribed to our newsletter!</p>}
          </div>
        </div>
      );
}

export default MailOptinForm