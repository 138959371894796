import React, { useState, useEffect, useContext } from "react";
import CheckoutStep1 from "../../components/checkout/checkout-1.component";
import CheckoutStep2 from "../../components/checkout/checkout-2.component";
import CheckoutStep3 from "../../components/checkout/checkout-3.component";
import CheckoutStep4 from "../../components/checkout/checkout-4.component";
import CheckoutStep5 from "../../components/checkout/checkout-5.component";
import { motion } from "framer-motion";
import { UserAuth } from "../../helpers/UserAuthContextProvider";
import { useNavigate } from "react-router-dom";
import { CheckoutContext } from "../../helpers/context3";
import { PuffLoader } from "react-spinners";


function CheckoutPage() {

  useEffect(() => {
    document.title = 'Checkout | Fast Fluency Africa';
  }, []);

  const {checkout, setCheckout} = useContext(CheckoutContext);
  const [currentStep, setCurrentStep] = useState(0);
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    if (currentStep === 5 ) {
      navigate("/dashboard");
    }
  }, [currentStep, navigate]);

  // Array of checkout steps
  const checkoutSteps = [
    <CheckoutStep1 onLast={handlePreviousStep} onNext={handleNextStep} />,
    <CheckoutStep2 onLast={handlePreviousStep} onNext={handleNextStep} />,
    <CheckoutStep3 onLast={handlePreviousStep} onNext={handleNextStep} />,
    <CheckoutStep4 onLast={handlePreviousStep} onNext={handleNextStep} />,
    <CheckoutStep5 onLast={handlePreviousStep} onNext={handleNextStep} />
  ];

  return (
    loading ? 
    <div className="flex flex-row justify-center items-center h-screen">
      <PuffLoader color={'#991616'} loading={loading} size={100} /> 
    </div>
    : 
    <motion.div
      className="h-screen bg-gradient-to-b from-gold to-red"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <CheckoutContext.Provider value={{checkout, setCheckout}}>
        {checkoutSteps[currentStep]}
      </CheckoutContext.Provider>
    </motion.div>
  );
}
export default CheckoutPage;
