import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  useEffect(() => {
    document.title = '404 Not Found | Fast Fluency Africa';
  }, []);

  return (
    <div className='flex flex-col items-center justify-center w-screen h-screen'>
      <h1 className='text-center text-2xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-headers'>404 </h1>
      <h2 className='text-center text-lg xs:text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl'>🧐🤨🔍</h2>
      <h3 className='text-center text-lg xs:text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-body'>Ooops... It seems we can't find the page you're looking for</h3>
      <h3 className='text-center text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-body'>Please check the URL again or let us take you back to the homepage</h3>
      <button className='mt-4 px-6 mx-6 my-4 py-2 border-1 rounded-xl  text-center cursor-pointer w-min-xl
              m-auto
              block pt-2 pb-2 pl-4 pr-4  text-black font-bold hover:text-white border-2 border-black hover:border-white hover:bg-black
              text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl
              leading-tight
              uppercase
              shadow-md
              hover:shadow-lg
              focus:shadow-lg focus:outline-none focus:ring-0
              active:shadow-lg
              transition
              duration-150
              ease-in-out'><Link to="./">Back to Homepage</Link></button>
    </div>
  );
};

export default NotFound;
