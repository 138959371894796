import React, {useEffect, useState} from "react";

import Footer from "../../components/footer/footer.component";
import Login from "../../components/login-register/login.component";

import {motion} from "framer-motion";
import { getAuth } from "firebase/auth";
import PuffLoader from "react-spinners/PuffLoader";



function LoginPage() {

  useEffect(() => {
    document.title = 'Login | Fast Fluency Africa';
  }, []);

    const [loading, setLoading] = useState(false);
    // const {user} = UserAuth();
    const auth = getAuth();
    const user = auth.currentUser;
  
    useEffect(() => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }, [])
  
    return (
        loading ? 
        <div className="flex flex-row justify-center items-center h-screen">
          <PuffLoader color={'#991616'} loading={loading} size={100} /> 
        </div>
        : 
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
            <div className="bg-gradient-to-b from-gold to-white">
                <Login />
            </div>
            <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
                <Footer />
            </footer>
        </motion.div>
    )
}

export default LoginPage