import { createContext, useContext, useState } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInAnonymously,
  getAuth, 
  deleteCurrentUser,
  EmailAuthProvider, 
  linkWithCredential,
  linkWithPopup,
  linkWithRedirect,
  deleteUser,
  sendEmailVerification
} from 'firebase/auth';
import { auth } from "../firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, deleteDoc, getDoc, setDoc } from "firebase/firestore";
import { db } from '../firebase/firebase.utils';
import { wait } from '@testing-library/react';


const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const auth = getAuth();


  const handleUpdate = async () => {
    const onboardingRef = doc(db, 'users', `${auth.currentUser.uid}`)
    try {
      await updateDoc(onboardingRef, {
        membership: "george",
        email: auth.currentUser.email
      });
      await auth.currentUser.reload();
      await auth.currentUser.getIdToken(true);
    } catch (err) {
      console.log(err)
    }
  }

  const checkFirstTimeUser = async (userId) => {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      return docSnap.data().isFirstTime; // assume you have an "isFirstTime" field
    } else {
      await setDoc(docRef, { isFirstTime: true });
      return true;
    }
  };

  const getMembership = (user) => {
    try {
      return JSON.parse(user.reloadUserInfo.customAttributes).membership;
    } catch (error) {
      console.warn("Error parsing customAttributes:", error);
      return null;
    }
  };

  const waitForClaimUpdate = async (user, timeout = 5000, interval = 1000) => {
    const startTime = Date.now();
  
    const checkClaim = async () => {
      try {
        await user.getIdToken(true);
        const tokenResult = await user.getIdTokenResult();
        const membership = tokenResult.claims.membership;
        if (membership === "george") {
          return membership;
        }
  
        // If the claim hasn't been updated yet and we haven't timed out, check again
        if (Date.now() - startTime < timeout) {
          return new Promise((resolve) => {
            setTimeout(() => resolve(checkClaim()), interval);
          });
        } else {
          throw new Error("Timed out waiting for custom claim update.");
        }
      } catch (error) {
        console.warn("Error getting custom claims:", error);
        throw error;
      }
    };
  
    return checkClaim();
  };

  const createUser = async (email, password) => {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      const usercred = await linkWithCredential(auth.currentUser, credential);
      const user = usercred.user;
      // setUser(user);
      await sendEmailVerification(auth.currentUser);
      await handleUpdate();
      await waitForClaimUpdate(auth.currentUser);
      await localStorage.setItem("isAuthenticated", "true");
    } catch (error) {
      console.log("Error upgrading anonymous account", error);
    }
  };
  

  const deleteCurrentUser = async () => {    
      if (!auth.currentUser) {
        console.error("No user is currently signed in.");
        return;
      }
    
      const userId = auth.currentUser.uid;
    
      const userRef = doc(db, "users", userId); // Assuming a 'users' collection
    
      try {
        await deleteDoc(userRef);
      } catch (error) {
        console.error("Error deleting user data from Firestore:", error);
      }
    
      try {
        await deleteUser(auth.currentUser);
        console.log("User account deleted from Firebase Auth");
      } catch (error) {
        console.error("Error deleting user from Firebase Auth:", error);
      }
    };


  const googleRegistration = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    const auth = getAuth();
      try {
      //     await linkWithRedirect(auth.currentUser, googleAuthProvider);
      
      //     const result = await getRedirectResult(auth);
      //     const credential = GoogleAuthProvider.credentialFromResult(result);
      //     if (credential) {
      //       const user = result.user;
      //       console.log("Google account successfully linked", user);
      //       navigate("/dashboard");
      //       handleUpdate();
      //     } else {
      //       console.log("Google account link operation failed");
      //     }
      //   } catch (error) {
      //     console.log("Error linking Google account", error);
      //   }
      // }

        await linkWithPopup(auth.currentUser, googleAuthProvider);
        await sendEmailVerification(auth.currentUser)

        // After successful login
        
        
        
        // Accounts successfully linked.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const user = result.user;
        
        // Redirect to the dashboard route after successful linking
        await handleUpdate();
        const membership = await waitForClaimUpdate(auth.currentUser);
        await navigate("/dashboard"); // Replace "/dashboard" with your actual dashboard route
        await localStorage.setItem("isAuthenticated", "true");
      } catch (error) {
        console.log("Error upgrading anonymous account", error);
      }
  };

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // You can add any additional logic here, such as navigation or setting user state
    } catch (error) {
      console.error('Error signing in:', error);
      throw error; // This will pass the error to the catch block in the onSubmit function
    }
  }
  

  const googleSignIn = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, googleAuthProvider);
      const user = auth.currentUser;
      setUser(user); // assuming setUser is defined to set the user in your state
      
      // After successful login
      localStorage.setItem("isAuthenticated", "true");

  
      const isFirstTime = await checkFirstTimeUser(user.uid);
      // Define a maximum number of attempts and an interval (in milliseconds)
      const maxAttempts = 10;
      const interval = 1000; // 1 second
  
      // Polling logic: try to get membership maxAttempts times, waiting interval ms between attempts
      let attempts = 0;
      let membership;
      do {
        await new Promise((resolve) => setTimeout(resolve, interval)); // Wait for the interval
        await user.reload(); // Reload user to get updated attributes
  
        membership = getMembership(user);
        attempts++;
      } while (!membership && attempts < maxAttempts);
  
      await onAuthStateChanged(auth, async (user) => {
        if (isFirstTime) {
          await sendEmailVerification(user);
          await waitForClaimUpdate(user);
          navigate("/onboarding");
        } else {
          const membership = getMembership(user); // assuming getMembership is defined
          if (membership === "guest") {
            navigate("/onboarding");
          } else {
            navigate("/dashboard");
          }
        }
      });
    } catch (error) {
      console.log("Error logging in with Google", error);
    }
  };

  const guestLogin = async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      const user = userCredential.user;
  
      // Define a maximum number of attempts and an interval (in milliseconds)
      const maxAttempts = 10;
      const interval = 1000; // 1 second
  
      // Polling logic: try to get membership maxAttempts times, waiting interval ms between attempts
      let attempts = 0;
      let membership;
      do {
        await new Promise((resolve) => setTimeout(resolve, interval)); // Wait for the interval
        await user.reload(); // Reload user to get updated attributes
  
        membership = getMembership(user);
        attempts++;
      } while (!membership && attempts < maxAttempts);
  
      if (membership) {
        localStorage.setItem("isAuthenticated", "true");
  
        if (membership === "george") {
          navigate("/dashboard");
        } else {
          navigate("/onboarding");
        }
      } else {
        console.warn('customAttributes not available after polling');
        // Handle this case as needed
      }
    } catch (error) {
      console.error('Error logging in as a guest:', error);
    }
  };
  
  const logout = () => {
  
    if (auth.currentUser) {
      signOut(auth)
        .then(() => {
          setUser(false);
          // On logout
          localStorage.removeItem("isAuthenticated");
          navigate('/login'); // Redirect to the login page after logout
        })
        .catch((error) => {
          console.error("Error logging out:", error);
        });
    }
  };

  return (
    <UserContext.Provider value={{ createUser, user, deleteCurrentUser, guestLogin, logout, signIn, googleSignIn, googleRegistration }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
