import React, {useEffect} from "react";

import Footer from "../../components/footer/footer.component";
import Register from "../../components/login-register/register.component";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component";

import {motion} from "framer-motion";


function RegisterPage(){

    useEffect(() => {
        document.title = 'Registration | Fast Fluency Africa';
      }, []);

    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
            <div>
                <NavigationBar/>
            </div>
            <div className="mt-4">
                <Register />
            </div>
            <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
                <Footer />
            </footer>
        </motion.div>
    )
}

export default RegisterPage