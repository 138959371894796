import React from "react";
import {useState, useEffect, useContext} from "react";
import {db} from "../../../firebase/firebase.utils.js";
import { IoCloseCircleOutline, IoChevronForwardCircleOutline, IoLanguage } from "react-icons/io5";
import { ScoreContext } from "../../../helpers/contexts.js";
import {LMContext} from "../../../helpers/context3.js";
import { ModalContext } from "../../../helpers/modalcontext.js";
import {motion} from "framer-motion";
import { collection, getDocs, doc, getDoc} from "firebase/firestore"; 
import { getAuth } from "firebase/auth";
import { SkillsData } from "../../../zombiedata/skills.js";
import { Link } from "react-router-dom";

import ExitModal from "../../modals/caution-exit-modal.component.jsx";

import Joyride, { STATUS } from 'react-joyride';


const Dialogue = ({onDialogueComplete, ids}) => {
    const auth = getAuth();
  const {learningMaterialId, setLearningMaterialId} = useContext(LMContext);
  const [dialogues, setDialogues] = useState([]);
  const dialoguesRef = collection(db, "dialogues");
  const {setScore} = useContext(ScoreContext);
  const {modal, setModal} = useContext(ModalContext);
  const [toggled, toggle] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hasPassed, setHasPassed] = useState(false);
  const [enrollment, setEnrollment] = useState({});





    useEffect(() => {
        const getDialogues = async () => {
            const data = await getDocs(dialoguesRef);
            setDialogues(data.docs.map(doc => ({...doc.data(), id: doc.id})));
        };
        getDialogues();
    },);

    const openModal = () => {
        setModal(true);
        document.body.style.overflow = 'hidden'; // Disable scrolling
    };
    
    useEffect(() => {
        const getCurrUser = async () => {
          if (auth.currentUser) {
            const docRef = doc(db, "users", `${auth.currentUser.uid}`);
            const docSnap = await getDoc(docRef);
      
            if (docSnap.exists()) {
              setEnrollment({ ...docSnap.data(), id: docSnap.id });
              setIsSubscribed(docSnap.data().membership); // or whatever your field name is
            } else {
              console.log("No such document!");
            }
          }
        };
        getCurrUser();
      }, [auth.currentUser]);

      useEffect(() => {
        if (enrollment.currentEnroll) {
          const currentIndex = SkillsData.findIndex(item => item.id === learningMaterialId);
          const currentItem = SkillsData[currentIndex];
          const currentCourse = SkillsData[currentIndex].course; // Replace this with the actual current course if necessary
          
          let allGrades = enrollment.grades ? enrollment.grades : {};
          let courseGrades = allGrades[currentCourse] ? allGrades[currentCourse] : [];
          let hasPassed = false;
          
          for(let i = 0; i < courseGrades.length; i++) {
            if(courseGrades[i].id === currentItem.id) {
              hasPassed = courseGrades[i].highScore >= 50;
              break;
            }
          }
          
          setHasPassed(hasPassed);
        }
      }, [enrollment, learningMaterialId]);
      



  return dialogues.map((dialogues, index, array) => {
    if (dialogues.no === learningMaterialId) {
      return (
        <div key={dialogues.no}>
            <div className={modal === true ? "display h-screen " : "hidden"}>
                <ExitModal />
            </div>
    <motion.div className={modal !== true ? "mb-1 py-1 w-4/5 m-auto bg-white" : "hidden" } initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.1}}>
            <div initial={{opacity: 0}} animate={{opacity: 1}}> 
                <div className="px-1 pl-2 pr-2 pb-4 pt-4 w-5/5 m-auto">
                    <div className="border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-black rounded px-1 m-auto shadow-2xl">
                        <div className="flex flex-row justify-between items-center p-3">
                            <span id={ids[0]} className="cursor-pointer text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl hover:text-green text-center">
                                <button className="cursor-pointer" onClick={() => {
                                        setLearningMaterialId(1);
                                        openModal();
                                    }}>
                                    <IoCloseCircleOutline className="w-full text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl"/>
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl uppercase"></p>
                                </button>
                            </span>
                            <span>
                                <div className="relative inline-block">
                                    <button id={ids[1]} 
                                        className={`cursor-pointer text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-center ${isSubscribed === "mabena" || hasPassed ? "hover:text-green" : "text-gray"}`} 
                                        onClick={(e) => {
                                        if (isSubscribed === "mabena" || hasPassed) {
                                            toggle(toggled => !toggled);
                                        } else {
                                            e.preventDefault();
                                        }
                                        }}
                                    >
                                        <IoLanguage className="w-full text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl" />
                                        <p className="text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl uppercase relative">
                                        {toggled ? "Hide Translations" : "Show Translations"}
                                        {(isSubscribed !== "mabena" && !hasPassed) && (
                                            <span className="absolute z-10 w-64 p-2 text-sm text-black bg-white border border-black rounded shadow-lg opacity-0 hover:opacity-100 transition-all duration-200 bottom-full left-1/2 transform -translate-x-1/2 translate-y-10 font-semibold">
                                                🔒 Upgrade to a <Link to="/checkout"><b className="hover:text-green">Mabena Membership</b></Link> or conquer this lesson to unlock this feature!
                                            </span>
                                        )}
                                        </p>
                                    </button>
                                </div>
                            </span>
                            <span id={ids[2]} className="cursor-pointer text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl hover:text-green text-center flex flex-col">
                                <button className="cursor-pointer text-center items-center" onClick={() => {onDialogueComplete(); setScore(0)}}>
                                    <IoChevronForwardCircleOutline className="w-full text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl" />
                                <p className="text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl uppercase"></p>
                                </button>
                            </span>
                        </div>
                        <div>
                            <div className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-center">{dialogues.icon}</div>
                            <h1 className="text-center sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl p-2 font-bold font-headers ">
                            {dialogues.title.toUpperCase()}
                            </h1>
                            {/* <p className="text-center truncate font-body font-normal sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-justify italic pl-2 pr-2 pb-2 pt-2 normal-case">
                            {dialogues.description}
                            </p> */}
                        </div>
                    </div>
                </div>
            </div> 
                <div className="mt-4 justify-center items-center m-auto w-5/5 px-1 font-body">
                    {dialogues.speechInfo.map((speechInfo, index, array) => {
                        if (speechInfo.turn === 1) {
                            return (
                                <div key={index} className="text-center flex justify-center items-center pt-1">
                                    <div className="mt-4 border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-red rounded-tr-none rounded-tl rounded-bl rounded-br w-full shadow-2xl h-full bg-white text-red  pl-2 pr-2 pb-2 pt-2">
                                        <p className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl  p-0 font-bold  ">
                                            {speechInfo.text}
                                        </p>
                                        <p className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl italic font-body    p-0">
                                            {toggled === true ? speechInfo.translation : null}
                                        </p>
                                    </div>
                                    {speechInfo.speaker === "null" ? null :
                                    <div className="mt-4 mr-2 ml-2 text-center items-end w-3/12 m-auto">
                                        <div className=" justify-center items-center m-auto text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-other">{speechInfo.turn === 1 && speechInfo.gender === "male" ? "‍👨🏾" : "👩🏾"} </div>
                                        {/* <img src={speechInfo.turn == 1 && speechInfo.gender == "male" ? avatarm1 : avatarw1} className="border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-red justify-center items-center m-auto m-auto shadow-lg w-12 md:w-20 lg:w-24 xl:w-28 2xl:w-32 3xl:w-36 h-12 md:h-20 lg:h-24 xl:h-28 2xl:h-32 3xl:h-36 rounded-tl-full rounded-tr-full rounded-br-full bg-white text-other" /> */}
                                        <span className="text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl text-red font-bold  ">
                                            {speechInfo.speaker}
                                        </span>
                                    </div> 
                                    }
                                </div>
                            )}
                        else if (speechInfo.turn === 2) {
                            return (
                                <div key={index} className="text-center flex justify-center items-center pt-1">
                                    {speechInfo.speaker === "null" ? null :
                                    <div className="mt-4 mr-2 ml-2 text-center items-end w-3/12 m-auto">
                                        <div className=" justify-center items-center m-auto text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-other">{speechInfo.turn === 2 && speechInfo.gender === "male" ? "🧑🏾" : "👧🏾"} </div>
                                        {/* <img src={speechInfo.turn == 2 && speechInfo.gender == "male" ? avatarm3 : avatarw2} className="border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-green justify-center items-center m-auto m-auto shadow-lg w-12 md:w-20 lg:w-24 xl:w-28 2xl:w-32 3xl:w-36 h-12 md:h-20 lg:h-24 xl:h-28 2xl:h-32 3xl:h-36 rounded-tl-full rounded-tr-full rounded-bl-full bg-white text-other" /> */}
                                        <span className="text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl text-green font-bold  ">
                                            {speechInfo.speaker}
                                        </span>
                                    </div> 
                                    }
                                    <div className="mt-4 border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-green rounded-tl-none rounded-bl rounded-tr rounded-br  pl-2 pr-2 pb-2 pt-2 w-full shadow-2xl h-full bg-white text-green">
                                        <p className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl  p-0 font-bold  ">
                                        {speechInfo.text}
                                        </p>
                                        <p className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl italic font-body    p-0">
                                        {toggled === true ? speechInfo.translation : null}
                                        </p>
                                    </div>
                                </div>
                            )}
                        else if (speechInfo.turn === 3) {
                            return (
                                <div key={index} className="text-center flex justify-center items-center pt-1">
                                    <div className="mt-4 border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-gold rounded-tr-none rounded-tl rounded-bl rounded-br w-full shadow-2xl h-full bg-white text-gold  pl-2 pr-2 pb-2 pt-2">
                                        <p className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl  p-0 font-bold  ">
                                            {speechInfo.text}
                                        </p>
                                        <p className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl italic font-body    p-0">
                                            {toggled === true ? speechInfo.translation : null}
                                        </p>
                                    </div>
                                    {speechInfo.speaker === "null" ? null :
                                    <div className="mt-4 mr-2 ml-2 text-center items-end w-3/12 m-auto">
                                        <div className=" justify-center items-center m-auto text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-other">{speechInfo.turn === 3 && speechInfo.gender === "male" ? "👦🏾" : "👩🏾"} </div>
                                        {/* <img src={speechInfo.turn == 3 && speechInfo.gender == "male" ? avatarm5 : avatarw3} className="border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-gold justify-center items-center m-auto m-auto shadow-lg w-12 md:w-20 lg:w-24 xl:w-28 2xl:w-32 3xl:w-36 h-12 md:h-20 lg:h-24 xl:h-28 2xl:h-32 3xl:h-36 rounded-tl-full rounded-tr-full rounded-br-full bg-white text-other" /> */}
                                        <span className="text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl text-gold font-bold  ">
                                            {speechInfo.speaker}
                                        </span>
                                    </div> 
                                    }
                                </div>
                            )}
                        else if (speechInfo.turn === 4) {
                            return (
                                <div key={index} className="text-center flex justify-center items-center pt-1">
                                    {speechInfo.speaker === "null" ? null :
                                    <div className="mt-4 mr-2 ml-2 text-center items-end w-3/12 m-auto">
                                        <div className="justify-center items-center m-auto text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-other">{speechInfo.turn === 4 && speechInfo.gender === "male" ? "🧔🏾" : "👩🏾‍🦱"} </div>
                                        {/* <img src={speechInfo.turn == 4 && speechInfo.gender == "male" ? avatarm5 : avatarw4} className="border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-black justify-center items-center m-auto m-auto shadow-lg w-12 md:w-20 lg:w-24 xl:w-28 2xl:w-32 3xl:w-36 h-12 md:h-20 lg:h-24 xl:h-28 2xl:h-32 3xl:h-36 rounded-tl-full rounded-tr-full rounded-bl-full bg-white text-other" /> */}
                                        <span className="text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl text-black font-bold  ">
                                            {speechInfo.speaker}
                                        </span>
                                    </div> 
                                    }
                                    <div className="mt-4 border md:border-2 lg:border-4 xl:border-6 2xl:border-8 3xl:border-8 border-black rounded-tl-none rounded-bl rounded-tr rounded-br  pl-2 pr-2 pb-2 pt-2 w-full shadow-2xl h-full bg-white text-black">
                                        <p className="text-xl  sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl  p-0 font-bold  ">
                                        {speechInfo.text}
                                        </p>
                                        <p className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl italic font-body    p-0">
                                        {toggled === true ? speechInfo.translation : null}
                                        </p>
                                    </div>
                                </div>)
                            } 
                        return null;
                        })}
                </div>
            </motion.div>
        </div>
      );
    }
    return null;
  });
}

export default Dialogue;
