import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import {default as power} from "../../assets/pfm-logo-2-min.png";

import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";

import {motion, AnimatePresence} from "framer-motion";

import Footer from "../../components/footer/footer.component.jsx";

import { useInView } from 'react-intersection-observer';


function About() {
  const [visibleText, setVisibleText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const baseText = 'Since 2016 we have been the only African mother tongue edutainment community for ';
  const words = ['Language', 'Culture', 'Stories'];
  const fullText = `${baseText}${words.slice(0, -1).join(', ')}, and ${words.slice(-1)}`;

  useEffect(() => {
    const textInterval = setInterval(() => {
      if (currentIndex < fullText.length) {
        setVisibleText(fullText.slice(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      } else {
        clearInterval(textInterval);
      }
    }, 45);

    return () => {
      clearInterval(textInterval);
    };
  }, [fullText, currentIndex]);

  const renderColoredText = (text, words, colors) => {
    let elements = [];
    let lastIndex = 0;

    words.forEach((word, index) => {
      const wordIndex = text.indexOf(word, lastIndex);
      if (wordIndex !== -1) {
        elements.push(<span key={wordIndex}>{text.slice(lastIndex, wordIndex)}</span>);
        elements.push(<span key={word} className={colors[index]}>{word}</span>);
        lastIndex = wordIndex + word.length;
      }
    });

    if (lastIndex < text.length) {
      elements.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return elements;
  };

  useEffect(() => {
    document.title = 'About us | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [ref5, inView5] = useInView();

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
                <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                  
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl uppercase font-bold">About</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container w-screen max-w-5xl mx-auto mt-16">
            <div className="px-6">
              <div className="flex items-center justify-center flex-col">
                <div className="ps-media-img-container">
                  <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                      <img alt="powerfm"  src={power} className="h-12 text-center text-sm sm:text-md md:text-lg lg:text-lg xl:text-lg 2xl:text-lg" />
                  </a>
                </div>
                <div id="about-us mt-6 flex items-center justify-center">
                  <p className="m-auto text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center font-headers font-bold">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      {renderColoredText(visibleText, words, ['text-red', 'text-gold', 'text-green'])}
                    </motion.div>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-center items-center">
              <div className="border border-darkgray shadow-2xl  rounded text-black text-center m-5 p-5">
                  <span className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-bold">2016</span>
                  <br />
                  <span className="font-body font-light sm:text-xs md:text-sm lg:text-md xl:text-xl 2xl:text-2xl 3xl:text-3xl">founded</span>
              </div>
              <div className="border border-darkgray shadow-2xl  rounded text-black text-center m-5 p-5">
                  <span className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-bold">50+</span>
                  <br />
                  <span className="font-body font-light sm:text-xs md:text-sm lg:text-md xl:text-xl 2xl:text-2xl 3xl:text-3xl">subscriptions</span>
              </div>
              <div className="border border-darkgray shadow-2xl  rounded text-black text-center m-5 p-5">
                  <span className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-bold">200+</span>
                  <br />
                  <span className="font-body font-light sm:text-xs md:text-sm lg:text-md xl:text-xl 2xl:text-2xl 3xl:text-3xl">coaching hours</span>
              </div>
              <div className="border border-darkgray shadow-2xl  rounded text-black text-center m-5 p-5">
                  <span className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-bold">3000+</span>
                  <br />
                  <span className="font-body font-light sm:text-xs md:text-sm lg:text-md xl:text-xl 2xl:text-2xl 3xl:text-3xl">resources</span>
              </div>
            </div>
            <div ref={ref1}  className="mt-4 mx-10">
              <motion.h2             initial={{opacity: 0}}
            animate={{opacity: inView1 ? 1 : 0}}
            transition={{delay: inView1 ? 0.25 : 0}} className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-semibold">Vision & mission</motion.h2>
              <motion.div             initial={{opacity: 0}}
            animate={{opacity: inView1 ? 1 : 0}}
            transition={{delay: inView1 ? 0.25 : 0}} className="mt-4">
                <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">To be the only African edutainment community by creating fun ways to know <b>all</b> African languages, cultures, and stories by 2050.</p>
              </motion.div>
            </div>
            <div ref={ref2}  className="mt-4 mx-10">
              <motion.h2             initial={{opacity: 0}}
            animate={{opacity: inView2 ? 1 : 0}}
            transition={{delay: inView2 ? 0.25 : 0}} className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-semibold">Origins</motion.h2>
              <motion.div             initial={{opacity: 0}}
            animate={{opacity: inView2 ? 1 : 0}}
            transition={{delay: inView2 ? 0.25 : 0}} className="mt-4">
                <motion.p             initial={{opacity: 0}}
            animate={{opacity: inView2 ? 1 : 0}}
            transition={{delay: inView2 ? 0.25 : 0}} className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Language is deeper than words; it's the key to building an identity. Our humble beginnings started with our founders, who experienced the struggle of language barriers firsthand, not just in their native languages (Chichewa & Setswana), but also across different cultural landscapes. These challenges led them to a simple yet effective framework for language learning, focusing on vocabulary and grammar. Now, we're committed to helping others overcome similar challenges in a fun, natural, and immersive way.</motion.p>
              </motion.div>
            </div>
            <div ref={ref3}  className="mt-4 mx-10">
              <motion.h2             initial={{opacity: 0}}
            animate={{opacity: inView3 ? 1 : 0}}
            transition={{delay: inView3 ? 0.25 : 0}} className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-semibold">Approach</motion.h2>
              <motion.p             initial={{opacity: 0}}
            animate={{opacity: inView3 ? 1 : 0}}
            transition={{delay: inView3 ? 0.25 : 0}} className="mt-2 text-md sm:text-md md:text-lg lg:text-xl text-justify">We believe that education should be fun. We combine education and entertainment—Edutainment—to create immersive learning experiences that resonates with diverse audiences. Our Edutainment Vault is a one-of-a-kind web application featuring a variety of resources such as <b>quality course materials</b>, <b>African language dictionaries</b>, <b>access to subject matter experts</b>, and much much more.</motion.p>
            </div>
            <div ref={ref4}  className="mt-4 mx-10">
              <motion.h2             initial={{opacity: 0}}
            animate={{opacity: inView4 ? 1 : 0}}
            transition={{delay: inView4 ? 0.25 : 0}} className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-semibold">Values</motion.h2>
              <motion.div             initial={{opacity: 0}}
            animate={{opacity: inView4 ? 1 : 0}}
            transition={{delay: inView4 ? 0.25 : 0}} className="flex flex-wrap justify-center items-center">
                <motion.div className="border border-darkgray shadow-2xl rounded text-black text-center m-5 p-5">
                    <motion.span className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-bold">COMMUNITY</motion.span>
                </motion.div>
                <motion.div className="border border-darkgray shadow-2xl rounded text-black text-center m-5 p-5">
                  <motion.span className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-bold">FUN</motion.span>
                </motion.div>
                <motion.div className="border border-darkgray shadow-2xl rounded text-black text-center m-5 p-5">
                  <motion.span className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-bold">SPEED</motion.span>
                </motion.div>
                <motion.div className="border border-darkgray shadow-2xl rounded text-black text-center m-5 p-5">
                  <motion.span className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-bold">QUALITY</motion.span>
                </motion.div>
                <motion.div className="border border-darkgray shadow-2xl rounded text-black text-center m-5 p-5">
                  <motion.span className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-bold">ORIGINALITY</motion.span>
                </motion.div>
              </motion.div>
              <motion.h3 className="mt-2 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-center"> <span className="font-semibold">Please note:</span> <em> We still haven't fully figured out what these values truly mean to us yet but they do sound appropriate for now.</em> 🤓 </motion.h3>
              <motion.p className="mt-4 text-md sm:text-md md:text-lg lg:text-xl text-justify">We are guided by the principles of Community, Fun, Speed, Quality, and Originality. Although we're still defining these values, they represent our commitment to delivering an exceptional experience to our various stakeholders.</motion.p>
          </div>
          <div ref={ref5}  className="mt-4 mx-10">
            <motion.h2             initial={{opacity: 0}}
            animate={{opacity: inView5 ? 1 : 0}}
            transition={{delay: inView5 ? 0.25 : 0}} className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-headers font-semibold">Work with us</motion.h2>
            <motion.p             initial={{opacity: 0}}
            animate={{opacity: inView5 ? 1 : 0}}
            transition={{delay: inView5 ? 0.25 : 0}} className="mt-2  text-md sm:text-md md:text-lg lg:text-xl text-justify">We are a 100% remote and largely asynchronous workplace, offering flexibility without compromising on quality. We're devoted to our community, pledging 35% of our equity to enhance communal well-being through our Lekgotla.</motion.p>
            <motion.p             initial={{opacity: 0}}
            animate={{opacity: inView5 ? 1 : 0}}
            transition={{delay: inView5 ? 0.25 : 0}} className="mt-2 text-md sm:text-md md:text-lg lg:text-xl text-justify">We invite you to be a part of a community that values learning as a life long journey. With FFA, learning is not just a one time affair but a lifestyle. </motion.p>
            <motion.p             initial={{opacity: 0}}
            animate={{opacity: inView5 ? 1 : 0}}
            transition={{delay: inView5 ? 0.25 : 0}} className="mt-2  text-md sm:text-md md:text-lg lg:text-xl text-justify">
            If you're tired of a traditional work environment and want to be a part of something groundbreaking, <a href="https://bit.ly/ffarecruitment" target="_blank" rel="noreferrer"><span className="underline font-semibold text-red">apply today!</span></a></motion.p>
          </div>
        </div>
      </body>
      {/* <div className="mt-6 flex flex-row flex-wrap justify-center items-center">
        <MailOptinForm /> 
      </div>  */}
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
    )
}

const getColorClass = (index) => {
  switch (index) {
    case 0:
      return "text-red";
    case 1:
      return "text-gold";
    case 2:
      return "text-green";
    default:
      return "";
  }
};

export default About;