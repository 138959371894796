import {initializeApp} from "@firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import "firebase/firestore";
import "firebase/auth";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: "ffa-beta",
  storageBucket: "ffa-beta.appspot.com",
  messagingSenderId: "145349147628",
  appId: "1:145349147628:web:4d62b27ed44c18e4dddec9",
  measurementId: "G-75G5J9MLPC"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const messaging = getMessaging(app);

export const requestToken = () => {
  return getToken(messaging, {vapidKey: `${process.env.REACT_APP_CLOUD_MESSAGE_VAPID_KEY}`}).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client set:',);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });