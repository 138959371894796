import React, {useEffect, useState} from "react";
import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";

import NewsFeedCard from "../../components/cards/news-feed.component.jsx";
import CurrentEnrolmentsCard from "../../components/cards/current-enrolments-2.component.jsx";
import RegistrationCard from "../../components/cards/register-card.component.jsx";
import InviteFriends from "../../components/referrals/inviteFriends.component.jsx";
import BetaCard from "../../components/cards/beta-version-card.component.jsx";

import { getAuth } from "firebase/auth";
import {db} from "../../firebase/firebase.utils.js";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";

import {motion} from "framer-motion";
import PuffLoader from "react-spinners/PuffLoader";

import Joyride, { STATUS } from 'react-joyride';

function Dashboard() {

  const auth = getAuth();
  const user = auth.currentUser;

  const handleOnboardingUpdate = async () => {
    const onboardingRef = doc(db, 'users', `${auth.currentUser.uid}`)
    try {
      await updateDoc(onboardingRef, {
        dashboardOnboardingCompleted: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const [completedWalkthrough, setCompletedWalkthrough] = useState(false);

  useEffect(() => {
    const userDoc = doc(db, 'users', `${auth?.currentUser?.uid}`);
  
    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setCompletedWalkthrough(docSnapshot.data().dashboardOnboardingCompleted);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const [run, setRun] = useState(null);
  const [steps, setSteps] = useState([
    {
      target: '#news',
      content: 'Stay up to date with announcements, promotions, and other major developments whenever we release new features.',
    },
    {
      target: '#current-enrollments',
      content: 'View the latest lessons that you have yet to complete and easily get back to mastery.',
    },
    {
      target: '#invite-friends',
      content: 'Use this personal referral link we generated just for you to invite your friends and family.',
    },
    {
      target: '#ffaqs',
      content: 'If you need a bit of guidance, click on this emoji to easily access various resources that will help you get the most out of your app experience!',
    },
]);

const handleJoyrideCallback = (data) => {
  const { status } = data;

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setRun(false);
    handleOnboardingUpdate();
  }
};

const startTour = () => {
  setRun(true);
};

const goFFAQ = (route) => {
  const win = window.open(route, "_blank");
  win.focus();
}

  useEffect(() => {
    document.title = 'Home | Fast Fluency Africa';
  }, []);
  const [loading, setLoading] = useState(false);
  
  const [membership, setMembership] = useState(null);

  useEffect(() => {
    const userDoc = doc(db, 'users', `${auth?.currentUser?.uid}`);

    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Assuming `membership` is a field in the user's Firestore document
        setMembership(docSnapshot.data().membership);
      }
    });

    // Cleanup
    return () => {
      unsubscribe();
    };
  }, []);
  
  useEffect(() => {
    // Make sure to handle this asynchronously
    const fetchMembership = async () => {
      if (user && user?.reloadUserInfo?.customAttributes) {
        const membership = JSON.parse(user?.reloadUserInfo?.customAttributes)?.membership
        setMembership(membership);
      } else {
        return null
      }
    };

    fetchMembership();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [user]);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])


  return (
    loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
      <motion.div className="bg-white h-screen" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.1}}>
        <div className="bg-white">
        {!completedWalkthrough ? <div id="onboarding-trigger" onClick={startTour} className="feedback-icon text-white flex flex-col items-center justify-center rounded w-12 h-12 fixed bottom-40 right-8 cursor-pointer hover:bg-green animate-bounce">
                  <span className='text-3xl' title='onboarding'>🚀</span>
                </div> : null}
          <div
            id="ffaqs" className="feedback-icon text-white flex flex-col items-center justify-center rounded w-12 h-12 fixed bottom-28 right-8 cursor-pointer hover:bg-green"
            onClick={() => {goFFAQ('/ffaqs')}}
          >
            <span className='text-3xl' title='ffaqs'>🙋🏾‍♀️</span>
          </div>
          <NavigationBar className="" />
          <div className="m-auto w-9/12">
          {
            user && (membership === "guest") ? (
              <div id="sign-up" className="flex flex-col w-5/5 items-center">
                <RegistrationCard className="text-center" />
              </div>
            ) : null
          }
          <div className="flex flex-col w-5/5 items-center">
            <BetaCard className="text-center"/>
          </div>
            <h1 className="mt-4 sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-headers font-bold text-left py-1 px-2 w-5/5">
              News
            </h1>
            <div id="news" className="flex flex-col w-5/5 items-center">
              <NewsFeedCard className=" text-center" />
            </div>
            <h1 className="mt-4 sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-headers font-bold text-left py-1 px-2 w-5/5">
              Current Enrolments
            </h1>
            <div id="current-enrollments" className="flex flex-col w-5/5 items-center">
              <CurrentEnrolmentsCard className=" text-center"/>
            </div> 
            <h1 className="mt-4 sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-headers font-bold text-left py-1 px-2 w-5/5">
              Invite friends and family
            </h1>
            <div id="invite-friends" className="flex flex-col w-full sm:w-3/5 items-center">
              <InviteFriends className=" text-center" />
            </div>
              <h1 className="mt-4 text-sm font-body font-bold text-left py-1 px-12">{null}</h1>
          </div>
          <h1 className="mt-4 text-sm font-body font-bold text-left py-1 px-12">{null}</h1>
          <div className="py-2"></div>
        </div>
        <Joyride
          steps={steps}
          run={run}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              arrowColor: '#848717',
              backgroundColor: '#FCFCEE',
              overlayColor: 'rgba(79, 26, 0, 0.1)',
              primaryColor: '#000',
              textColor: '#000000',
              width: 500,
              zIndex: 1000,
              fontFamily: "Nunito",
            }
          }}
        />
      </motion.div>
  );
};

export default Dashboard;
