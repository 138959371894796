import React, { useContext, useEffect, useState } from "react";
import { ScoreContext } from "../../../helpers/contexts";
import { useNavigate } from "react-router-dom";
import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import { LMContext } from "../../../helpers/context3";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../../firebase/firebase.utils";
import Confetti from "react-confetti"; // Import Confetti

import { SkillsData } from "../../../zombiedata/skills";
import PuffLoader from "react-spinners/PuffLoader";


function ExerciseSummary() {


  const navigate = useNavigate();

  const auth = getAuth();
  const user = auth.currentUser;

  const {score} = useContext(ScoreContext);
  const percentScore = (score / 10) * 100;
  const roundedPercentScore = percentScore.toFixed(0);

  const count = useMotionValue(0);
  const rounded = useTransform(count, latest => Math.round(latest));

  const {learningMaterialId} = useContext(LMContext);
  const [loading, setLoading] = useState(false);

  const [enrollment, setEnrollment] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false); // Add state to control confetti display

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])


  useEffect(() => {
    const controls = animate(count, 50)
    return controls.stop
  })

  useEffect(() => {
    if (roundedPercentScore >= 75) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 60000); // Hide the confetti after 2 seconds
    }
  }, [roundedPercentScore]);


  useEffect(() => {
    const getCurrUser = async () => {
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          setEnrollment({ ...docSnap.data(), id: docSnap.id });
        } else {
          console.log("No such document!");
        }
      }
    };
    getCurrUser();
  }, [user]);


  const handleUpdate = async () => {
    const currentEnrollmentRef = doc(db, "users", `${auth.currentUser.uid}`);
    const currentItem = SkillsData.find(item => item.id === learningMaterialId);
    const currentIndex = SkillsData.findIndex(item => item.id === learningMaterialId);
    const nextItem = SkillsData[currentIndex + 1];
    const lastItem = SkillsData[currentIndex - 1];
    const currentCourse = SkillsData[currentIndex].course; // Retrieve the actual current course from the data
  
    if (roundedPercentScore >= 50) { // Check if the score is 50 or higher
      if (nextItem) { 
        try {
          const docSnap = await getDoc(currentEnrollmentRef);
    
          if (docSnap.exists()) {
            let allGrades = docSnap.data().grades ? docSnap.data().grades : {};
            let courseGrades = allGrades[currentCourse] ? allGrades[currentCourse] : [];
            let found = false;
    
            for(let i = 0; i < courseGrades.length; i++) {
              if(courseGrades[i].id === currentItem.id) {
                found = true;
                if(roundedPercentScore > courseGrades[i].highScore) {
                  courseGrades[i].highScore = roundedPercentScore;
                } else {
                }
                break;
              }
            }
    
            if(!found) {
              courseGrades.push({
                id: currentItem.id,
                highScore: roundedPercentScore
              });
            }
    
            allGrades[currentCourse] = courseGrades;
    
          if (currentItem.id === enrollment.currentEnroll.id) {
              await updateDoc(currentEnrollmentRef, {
                currentEnroll: {
                  id: nextItem.id,
                  title: nextItem.name,
                  course: currentCourse,
                },
                grades: allGrades
              });
            } else {
              await updateDoc(currentEnrollmentRef, {
                  grades: allGrades  
              });
            }
    
            navigate('/content');
          } else {
            console.log("No such document!");
            navigate('/content');
          }
    
        } catch (err) {
          console.log(err);
          navigate('/content');
        }
      } 
    } else {
      navigate('/content');
    }
  };
  
  
  
  


  return (
    <motion.div className="text-2xl mt-1 ml-1" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
    {
      loading ? 
        <div className="flex flex-row justify-center items-center h-screen bg-white">
          <PuffLoader color={'#991616'} loading={loading} size={100} /> 
        </div>
        : 
      <div className="h-screen flex flex-col justify-center items-center">
      {showConfetti && <Confetti className="w-screen " initialVelocityY={5} gravity={0.4} wind={0.2} />} {/* Render confetti when showConfetti is true */}
          <h1 className=" mt-8 text-black text-center font-headers font-bold text-2xl uppercase">
            {roundedPercentScore >= 100 ? "Congratulations!" :
              roundedPercentScore >= 75 ? "Well done! Keep trying until you get a perfect score" :
              roundedPercentScore >= 50 ? "Not bad, but we both know you can do better!" :
              "Looks like you need more practice" 
              }
          </h1>
          {roundedPercentScore >= 100 ? (<div className="text-center text-6xl text-green" >🎊🎉</div>) : 
           roundedPercentScore >= 75 ? (<div className="text-center text-6xl text-gold" >😀🏋🏾‍♀️</div>) :
           roundedPercentScore >= 50 ? (<div className="text-center text-6xl text-red" >🧐😏</div>) :
           (<div className="text-center text-6xl text-red mb-4"  >😞🤦‍♀️</div>)
          }
          <h3 className={roundedPercentScore >= 100 ? "mt-8 text-center font-semibold font-body text-xl uppercase rounded border-4 px-4 py-4 m-2 border-green shadow-lg" : roundedPercentScore >= 75 ? "mt-8 text-center font-semibold font-body text-2xl uppercase rounded border-4 px-4 py-4 m-2 border-gold shadow-lg" : "mt-8 text-center font-semibold font-body text-2xl rounded border-4 px-4 py-4 m-2 border-red uppercase shadow-lg"}>
            <div className={roundedPercentScore >= 100 ? "px-4 py-6 bg-green" : roundedPercentScore >= 75 ? "px-4 py-6 bg-gold" : roundedPercentScore >= 50 ? "px-4 py-6 bg-red" : "px-4 py-6 bg-red"}>
              <div className="text-white px-4 py-6">{roundedPercentScore >= 100 ? "EXCELLENT" : roundedPercentScore >= 75 ? "GOOD" : roundedPercentScore >= 50 ? "AVERAGE" : "POOR"}</div>
            </div>
          </h3>
          <br />
          <br />
          <br />
          <div className="text-center mt-4">
            <button onClick={handleUpdate} className="mt-8 px-6 my-4 py-2 border-2 rounded w-full text-center m-auto block pt-2 pb-2 pl-4 pr-4 text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out cursor-pointer focus:border-green focus:border-2 focus:text-green  hover:border-darkgray hover:text-gray">
              Continue
            </button>
          </div>
      </div>
}
    </motion.div>
  );
}

export default ExerciseSummary;
