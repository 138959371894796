import React from "react";
import { Link } from "react-router-dom";

import {TiSocialInstagramCircular,TiSocialTwitterCircular } from "react-icons/ti";
import { TbBrandWhatsapp } from "react-icons/tb";
import { IoLogoFacebook, IoLogoWhatsapp, IoLogoTwitter, IoLogoInstagram } from "react-icons/io";


import {default as skyline} from "../../assets/imgs/skyline-africa.svg";

function Footer() { 

return (
        <div className="flex flex-col justify-center items-center mt-16">
            <Link to="/ffaqs">
                <button
                className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl hover:text-green cursor-pointer"
                rel="nonreferrer"
                >
                FFAQs
                </button>
            </Link>
            <Link to="/about">
                <button
                className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl hover:text-green cursor-pointer uppercase"
                rel="nonreferrer"
                >
                About
                </button>
            </Link>
            <Link to="/articles">
                <button
                className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl hover:text-green cursor-pointer uppercase"
                rel="nonreferrer"
                >
                Articles
                </button>
            </Link>
            <a href="https://bit.ly/ffarecruitment" target="_blank" rel="noreferrer" >
                <button
                className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl hover:text-green cursor-pointer uppercase"
                rel="nonreferrer"
                >
                Join the Tribe
                </button>
            </a>
            <Link to="/privacy">
                <button
                className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl hover:text-green cursor-pointer uppercase"
                >
                Privacy Policy
                </button>
            </Link>
            <Link to="/terms">
                <button
                className="sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl hover:text-green cursor-pointer uppercase"
                >
                Terms & Conditions
                </button>
            </Link>
            <>
                <button
                className="italic font-bold sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl hover:text-green cursor-pointer uppercase"
                >
                the fourth moment of the sun
                </button>
            </>
            <div className="flex flex-row justify-content items-center">
                <a href="https://www.instagram.com/fastfluencyza" target="_blank" rel="noreferrer"  className="flex justify-center items-center">
                   <button>
                        <TiSocialInstagramCircular className="hover:text-green cursor-pointer text-3xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" />
                    </button>
                </a>
                <a href="https://wa.me/27836663487?text=Hello%20I%20would%20like%20to%20book%20an%20information%20session%20with%20you" target="_blank" rel="noreferrer"  className="flex justify-center items-center">
                   <button className="">
                        <TbBrandWhatsapp className="hover:text-green cursor-pointer text-2xl sm:text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl" />
                    </button>
                </a>
                <a href="https://www.twitter.com/fastfluencyza" target="_blank" rel="noreferrer"  className="flex justify-center items-center">
                   <button className="">
                        <TiSocialTwitterCircular className="hover:text-green cursor-pointer text-3xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" />
                    </button>
                </a>
            </div>
            <span className="uppercase italic text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-headers font-semibold  w-full text-center">
                #savekhotso 
            </span>
            <img className="w-full sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg 3xl:text-xl" src={skyline} alt="skyline" />
            <span className="bg-white text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md font-headers font-semibold  w-full text-center">
                COPYRIGHT FAST FLUENCY AFRICA (PTY) LTD ©2016 - 2023 
            </span>
        </div>
    )
}

 export default Footer