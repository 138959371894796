import React from "react";
import {Courses} from "../../zombiedata/courses";
import {Link} from "react-router-dom";

import {default as rsatwo} from "../../assets/imgs/flags/rsa.png";

const CourseCard = () => {
  return (
    <div className="grid grid-rows-5 grid-cols-3">
      {Courses.map((item, index) => {
        return (
          <Link to="/content">
            <button
              className={
                item.status === "unavailable"
                  ? "hidden cursor-none"
                  : "rounded-2xl m-auto cursor-pointer text-black"
              }
              disabled={item.status === "unavailable" ? true : false}
            >
              <img alt="course-flag" src={rsatwo}
                className={
                  item.status === "unavailable"
                    ? "hidden cursor-none"
                    : " m-auto text-4xl h-24 cursor-pointer p-4"
                }
              />
              <div className="">
                <h4 className="text-body font-bold font-xl uppercase">
                  {item.name}
                </h4>
              </div>
            </button>
          </Link>
        );
      })}
    </div>
  );
};

export default CourseCard;
