import {default as blog} from "../assets/imgs/1.jpg";
import {default as blog1} from "../assets/imgs/blog/5.jpg";
import {default as blog2} from "../assets/imgs/blog/6.jpg";
import {default as blog3} from "../assets/imgs/blog/7.jpg";
import {default as blog4} from "../assets/imgs/blog/8.jpg";
import {default as blog5} from "../assets/imgs/blog/9.jpg";
import {default as blog6} from "../assets/imgs/blog/10.jpg";
import {default as cheatsheet} from "../assets/imgs/2.jpg";
import {default as phrasebookcomingsoon} from "../assets/imgs/3.jpg";

export const Articles = [
    {
        image: blog6,
        heading: "Incorporating Language Learning Into Your Busy Schedule",
        description: "Struggling to find time for language learning? We offer practical tips to integrate learning into your daily life, turning 'too busy' into 'just right.'",
        calltoaction: "READ MORE",
        path: "/language-learning-in-busy-schedule",
        target: ""
    },  
    {
        image: blog5,
        heading: "Top 5 Myths About Language Learning Debunked",
        description: "Think you're too old or too busy to learn a new language? We dispel common myths that may be holding you back from unlocking your full linguistic potential.",
        calltoaction: "READ MORE",
        path: "/language-learning-myths-debunked",
        target: ""
    },    
    {
        image: blog2,
        heading: "How Learning a New Language Benefits Your Brain",
        description: "Unlock the neuroscience behind language learning. Enhance cognitive functions, boost memory, and open your mind to new ways of thinking and seeing.",
        calltoaction: "READ MORE",
        path: "/benefits-for-your-brain",
        target: ""
    },
    {
        image: blog3,
        heading: "The Role of Language in Preserving African Cultures",
        description: "Learn how language serves as a cornerstone in preserving African heritage. It’s more than dialect—it's an embodiment of values, traditions, and identity.",
        calltoaction: "READ MORE",
        path: "/preserving-african-cultures",
        target: ""
    },
    {
        image: blog1,
        heading: "Importance of Learning African Languages",
        description: "Discover why learning African languages is more than just words—it's an entryway into rich cultures and shared histories that foster global unity.",
        calltoaction: "READ MORE",
        path: "/importance-of-african-languages",
        target: ""
    },
    {
        image: blog4,
        heading: "Guide to Common Phrases in isiZulu",
        description: "Navigate the basics of isiZulu with confidence. Our guide gives you key phrases that are essential for daily interactions, making your journey smoother.",
        calltoaction: "READ MORE",
        path: "/common-phrases-in-isizulu",
        target: ""
    },  
    {
        image: blog,
        heading: "Fast Fluency Road Map: isiZulu",
        description: "Our step by step guide on how to get started with your language learning journey today. Many have tried, but many more have failed, and we think it might be because they didn't bother reading this first.",
        calltoaction: "READ MORE",
        path: "/isizulu-roadmap-2022",
        target: ""
    },
    // {
    //     image: cheatsheet,
    //     heading: "Click Sounds Tongue Twisters",
    //     description: "Master the click sounds with our Tongue Twisters like nothing you will find anywhere. We can almost guarantee that after you give these tongue twisters a try the Q's, X's, and C's will stop stressing you.",
    //     calltoaction: "DOWNLOAD",
    //     path: "https://mailchi.mp/c99901b31394/click-sounds",
    //     target: "_blank"
    // },
    // {
    //     image: phrasebookcomingsoon,
    //     heading: "isiZulu Socials Phrasebook",
    //     description: "Dive into the most comprehensive isiZulu Phrasebook available anywhere online, absolutely for free. Make sure you're never in a tight spot when you're out in these streets chopping it up with friends and fam.",
    //     calltoaction: "DOWNLOAD",
    //     path: "https://mailchi.mp/da3d51619f4c/iszulu-phrasebook-socials",
    //     target: "_blank"
    // },
]