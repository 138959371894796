import React, {useState, useEffect} from "react";
import axios from 'axios';
import {MD5} from "crypto-js";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase/firebase.utils";
import { doc, updateDoc, getDoc} from "firebase/firestore";
import { BeatLoader } from "react-spinners";

function SubscriptionsCard() {
  const auth = getAuth();
  const user = auth.currentUser;
  const uid = user.uid;
  const [subscription, setSubscription] = useState("");
  const [subscriptionToken, setSubscriptionToken] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);


  const handleCancellation = async () => {
    const onboardingRef = doc(db, 'users', `${auth.currentUser.uid}`)
    try {
      await updateDoc(onboardingRef, {
        membership: "george",
        email: auth.currentUser.email
      });
      await auth.currentUser.reload();
      await auth.currentUser.getIdToken(true);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const getSubscriptionToken = async () => {
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const token = docSnap.data().membershipToken;
          setSubscriptionToken(token);
        } else {
          console.log("No such document!");
        }
      }
    };
    getSubscriptionToken();
  }, [uid, subscriptionToken, user]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const merchantId = process.env.REACT_APP_PAYFAST_MERCHANT_ID;
    const version = "v1";
    const passphrase = process.env.REACT_APP_PAYFAST_SECURITY_PASSPHRASE;
  
    const createSignature = (timestamp) => {
        const params = {
          "merchant-id": merchantId,
          timestamp: timestamp,
          token: subscriptionToken,
          version: "v1",
        };
      
        // Convert the params object to an array of key-value pairs
        const paramArray = Object.entries(params);
      
        // Sort the array by the keys (i.e., the first element of each pair)
        const sortedParamArray = paramArray.sort((a, b) => {
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        });
      
        // Convert the sorted array back into a string
        let paramString = sortedParamArray
          .map((pair) => `${pair[0]}=${encodeURIComponent(pair[1])}`)
          .join("&");
      
        paramString += `&passphrase=${encodeURIComponent(passphrase)}`;
      
        const signature = MD5(paramString).toString();
        return signature
      };
      
      // ... rest of the code remains the same ...
      function getCurrentTimestamp() {
        const date = new Date();
        let timestamp = date.toISOString().split('.')[0];
      
        // Extract the timezone offset in minutes
        const timezoneOffset = date.getTimezoneOffset();
        const timezoneSign = timezoneOffset < 0 ? '+' : '-';
        const timezoneHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const timezoneMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
      
        // Append the timezone offset to the timestamp
        timestamp += timezoneSign + timezoneHours + ':' + timezoneMinutes;
        return timestamp;
      }
      
      const viewSubscription = () => {
        setLoading(true);
        getCurrentTimestamp();
        const date = new Date();
        let timestamp = date.toISOString().split('.')[0];
      
        // Extract the timezone offset in minutes
        const timezoneOffset = date.getTimezoneOffset();
        const timezoneSign = timezoneOffset < 0 ? '+' : '-';
        const timezoneHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const timezoneMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
      
        // Append the timezone offset to the timestamp
        timestamp += timezoneSign + timezoneHours + ':' + timezoneMinutes;
      
        const signature = createSignature(timestamp);
      
        user.getIdToken(true)
          .then((idToken) => {
            const headers = {
              Authorization: `Bearer ${idToken}`,
              "merchant-id": merchantId,
              "signature": signature,
              "timestamp": timestamp,
              "version": version,
            };
      
            return axios.get('https://us-central1-ffa-beta.cloudfunctions.net/payfastSubscriptions/fetchSubscription', {headers, withCredentials: true});
          })
          .then((response) => {
            setSubscription(response);
            setLoading(false);
            setError(false);
          })
          .catch((err) => {
            setError("An error occurred while fetching your subscription info.");
            setLoading(false);
            if (err && err.response) {
              console.log(err.response); // Detailed error information from the server
            }
          });
      };


      const cancelSubscription = () => {
        setLoading(true);
        getCurrentTimestamp();
        const date = new Date();
        let timestamp = date.toISOString().split('.')[0];
      
        // Extract the timezone offset in minutes
        const timezoneOffset = date.getTimezoneOffset();
        const timezoneSign = timezoneOffset < 0 ? '+' : '-';
        const timezoneHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const timezoneMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
      
        // Append the timezone offset to the timestamp
        timestamp += timezoneSign + timezoneHours + ':' + timezoneMinutes;
        // const timestamp = new Date().toISOString();
        
        const signature = createSignature(timestamp);

        user.getIdToken(true)
          .then((idToken) => {
            const headers = {
              Authorization: `Bearer ${idToken}`,
              "merchant-id": merchantId,
              "signature": signature,
              "timestamp": timestamp,
              "version": version,
            };
      
            return axios.put('https://us-central1-ffa-beta.cloudfunctions.net/payfastSubscriptions/cancelSubscription', {}, {headers: headers});
          })
          .then((response) => {
            setSubscription(response);
            setLoading(false);
            handleCancellation();
          })
          .catch((err) => {
            setError("An error occurred while fetching the subscription.");
            setLoading(false);
            if (err && err.response) {
              console.log(err.response); // Detailed error information from the server
            }
          });
      };
      
    return (
        <div id="subscriptions" className="flex flex-col justify-center items-center mt-4 bg-white">
            <div className="flex flex-col justify-center items-center w-2/3">
                <h1 className="text-left w-full font-bold font-headers text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">Subscriptions</h1>
                <div className="bg-white w-full">
                    <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Manage your subscriptions here.</p>
                    <div className="flex flex-col justify-center items-center mt-4 bg-white">
                        {!subscription ? <button onClick={viewSubscription} className="mt-4 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-4/6 pt-2 pr-2 inline rounded box-border px-6 py-2 border-1 text-center cursor-pointer w-min-xl
                              m-auto cursor:pointer pb-2 pl-4 font-bold  hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green text-black
                              leading-tight
                              uppercase
                              shadow-md
                              hover:shadow-lg
                              focus:shadow-lg focus:outline-none focus:ring-0
                              active:shadow-lg
                              transition
                              duration-150
                              ease-in-out"><span>📑 </span>View Subscription
                        </button> : null}
                        {loading && <p className="mt-4"><BeatLoader color={'#848717'} loading={loading} size={8} /></p>}
                        {!subscription && error && <p className="mt-4">{error}</p>}
                      {subscription && <div className="w-full">
                        <h1 className="font-headers font-bold my-2">Current Plan</h1>
                        <hr />
                        <h3 className="font-semibold my-2">{subscription && subscription.data.amount === 24700 ? "Mabena Membership (Monthly)" : "Mabena Membership (Annual)" }</h3>
                        <h2 className="font-bold my-2"> ZAR {subscription && subscription.data.amount === 24700 ? `${(subscription.data.amount / 100).toFixed(2)} per month` : `${(subscription.data.amount / 100).toFixed(2)} per year`}
                        </h2>
                        <p className="my-2">Your subscription will be billed on <b>{subscription && subscription.data.run_date && subscription.data.run_date.split('T')[0]}</b></p>
                        <p className="my-2">This plan is currently <span className={subscription.data.status_text === "ACTIVE" ? "text-green" : "text-red"}>{subscription && subscription.data.status_text}</span></p>
                        <div className="w-full m-auto flex flex-col items-center">
                          <button onClick={() => {setSubscription(false); setShowConfirm(false)}} className="mt-4 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-4/6 pt-2 pr-2 inline rounded box-border px-6 py-2 border-1 text-center cursor-pointer w-min-xl
                              m-auto cursor:pointer pb-2 pl-4 font-bold  hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green text-black
                              leading-tight
                              uppercase
                              shadow-md
                              hover:shadow-lg
                              focus:shadow-lg focus:outline-none focus:ring-0
                              active:shadow-lg
                              transition
                              duration-150
                              ease-in-out"><span>🙈 </span>Hide Subscription
                          </button>
                          <button onClick={() => setShowConfirm(true)} className="mt-4 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-4/6 pt-2 pr-2 inline rounded box-border px-6 py-2 border-1 text-center cursor-pointer w-min-xl
                              m-auto cursor:pointer pb-2 pl-4 font-bold  hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green text-black
                              leading-tight
                              uppercase
                              shadow-md
                              hover:shadow-lg
                              focus:shadow-lg focus:outline-none focus:ring-0
                              active:shadow-lg
                              transition
                              duration-150
                              ease-in-out"><span>❌ </span>Cancel Subscription
                          </button>
                          { showConfirm &&
                          <button onClick={cancelSubscription} 
                            className="mt-4 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-4/6 pt-2 pr-2 inline rounded box-border px-6 py-2 border-1 text-center cursor-pointer w-min-xl
                            m-auto cursor:pointer pb-2 pl-4 font-bold  hover:text-white border-2 border-red bg-white hover:border-red hover:bg-red text-black
                            leading-tight
                            uppercase
                            shadow-md
                            hover:shadow-lg
                            focus:shadow-lg focus:outline-none focus:ring-0
                            active:shadow-lg
                            transition
                            duration-150
                            ease-in-out">
                            <span className="font-bold">⚠️ </span>Are you sure you want to cancel? This action is irreversible!
                          </button>
                          }
                        </div>
                      </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionsCard;
