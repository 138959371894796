import React from "react";

import CourseCard from "../../components/cards/course-card.component.jsx";

import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";

import {motion} from "framer-motion";


const CoursePage = () => (
  <motion.div className="bg-white h-screen" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
    <NavigationBar className="" />
    <div className="w-9/12 m-auto">
      <h1 className="text-center font-bold text-xl font-body mt-4">Courses</h1>
      <div className="mt-4">
        <CourseCard />
      </div>
    </div>
  </motion.div>
);

export default CoursePage;
