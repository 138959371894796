import {UnitsData} from "../../../zombiedata/units";
import Skills from "../skills/skills-primary.component";

function Units({id}) {
  return (
    <div className="flex flex-row flex-wrap justify-center items-center">
      {UnitsData.map((unit, index) => (
        <div key={unit.id}>
          <div id={id} className="flex flex-col flex-wrap justify-center items-center mt-4 p-4 m-2">
            <h1 className="text-7xl px-4 py-2 text-red font-bold rounded-full">{unit.emoji}</h1>
            {/* <div className="font-headers font-bold mt-2 text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-black text-center">{unit.name}</div> */}
            <div className="font-light font-body mt-2 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-black text-center">{unit.description}</div>
          </div>
          <Skills unitId={unit.id} />
        </div>
      ))}
    </div>
  );
}

export default Units