import React, {useState} from "react";
import {db} from "../../firebase/firebase.utils";
import {doc, updateDoc } from "firebase/firestore";
import {useFormik} from "formik";
import * as Yup from "yup";
import { getAuth, updateEmail, updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";


function AccountCard() {
  const [profileStatus, setProfileStatus] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;  
  const initialValues = {
    username: '',
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string(),
    email: Yup.string().email('Invalid email address'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required!'),
  });
    
  const promptForCredentials = () => {
    const email = prompt('Please enter your email:');
    const password = prompt('Please enter your password:');
    return EmailAuthProvider.credential(email, password);
  };

  const reauthenticateUser = async () => {
    try {
      // Prompt the user for their credentials
      const credential = promptForCredentials();
  
      // Reauthenticate the user with the provided credentials
      await reauthenticateWithCredential(user, credential);
  
      // User has been successfully reauthenticated
      
      // Perform the sensitive action (e.g., updating the user profile)
      // Implement your logic here
  
    } catch (error) {
      // An error occurred during reauthentication
      console.error('Error during reauthentication:', error);
    }
  };
    
    const handleSubmit = async (values, {resetForm}) => {

      resetForm();
      setIsSubmitted(true);
          
      if (user) {
        try {
          // Update user profile
          await updateProfile(user, {
            displayName: formik.values.username,
          });
    
          // Update email and password
          await updateEmail(user, formik.values.email);
          await updatePassword(user, formik.values.password);
    
          // Update user collection in Firestore
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            username: formik.values.username,
            email: formik.values.email,
          });
    
          setProfileStatus('Your profile has successfully been updated!');
        } catch (error) {
          if (error.code === "auth/requires-recent-login") {
            reauthenticateUser();
          }
          console.error('Error updating profile and collection:', error);
        }
      }
    };

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: handleSubmit,
    });

    return (
        <div className="flex flex-col justify-center items-center mt-4">
                <div className="flex flex-col justify-center items-center w-2/3">
                    {/* <img src="https://ui-avatars.com/api/?background=random&name=Isi+Zulu&color=#000000" alt="" className="rounded-full" /> */}
                    <h1 className="font-headers text-left mt-4 w-full font-bold text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">Profile</h1>
                    <p className="text-left w-full text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Update your user information to keep your credentials up to date and tailored to you.</p>
                    <form action="" className="w-full" onSubmit={formik.handleSubmit}>
                        <div className="flex flex-col">
                            <label htmlFor="username" className="font-semibold mt-2 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Name</label>
                            <div className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-full pr-2 inline rounded box-border bg-white focus:outline-none font-bold">{user && user.displayName ? user.displayName : null}</div>
                            <input type="text" id="username" name="username" {...formik.getFieldProps('username')} className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-full pt-2 pr-2 inline rounded box-border bg-white px-2 py-2 border mt-2 font-bold leading-tight focus:outline-none focus:shadow-outline shadow appearance-none " placeholder="Select a new username" />
                            {formik.touched.username && formik.errors.username && <div className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-red">{formik.errors.username}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="email" className="font-semibold mt-2 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Email</label>
                            <div className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-full pr-2 inline rounded box-border bg-white font-bold">{user && user.email ? user.email : null}</div>
                            <input type="email" id="email" name="email" {...formik.getFieldProps('email')} className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-full pt-2 pr-2 inline rounded box-border bg-white px-2 py-2 border mt-2 font-bold leading-tight focus:outline-none focus:shadow-outline shadow appearance-none " placeholder="Set a new email address" />
                            {formik.touched.email && formik.errors.email && <div className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-red">{formik.errors.email}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="password" className="mt-2">Password</label>
                            <input type="password" id="password" name="password" {...formik.getFieldProps('password')} className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-full pr-2 px-2 py-2 inline rounded box-border bg-white border focus:outline-none mt-2 font-bold leading-tight focus:outline-none focus:shadow-outline shadow appearance-none" placeholder="Type in a new password  " />
                            {formik.touched.password && formik.errors.password && <div className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-red">{formik.errors.password}</div>}
                        </div>
                        <div className="mt-4 flex flex-col items-center justify-center w-full">
                            <button type="submit"
                              onClick={formik.handleSubmit} value="update profile" className="mt-4 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-4/6 pt-2 pr-2 inline rounded box-border px-6 py-2 border-1 text-center cursor-pointer w-min-xl
                              m-auto cursor:pointer pb-2 pl-4 font-bold  hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green text-black
                              leading-tight
                              uppercase
                              shadow-md
                              hover:shadow-lg
                              focus:shadow-lg focus:outline-none focus:ring-0
                              active:shadow-lg
                              transition
                              duration-150
                              ease-in-out" placeholder="Update profile">Update profile</button>
                              {isSubmitted ? <span className="mt-4 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-green font-semibold">{profileStatus}</span> : null}
                        </div>
                    </form>
                </div>
            <div className="flex flex-col flex-wrap justify-center items-center mt-4">
                
            </div>
        </div>
    )
}

export default AccountCard