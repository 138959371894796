import React from "react";
import { useState, useRef, useEffect } from "react";
import { Features } from "../../zombiedata/features";
import { motion } from "framer-motion";

function Feature() {
  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  const scrollLeft = () => {
    carousel.current.scrollLeft -= 150; // change 200 to desired scroll amount
  };

  const scrollRight = () => {
    carousel.current.scrollLeft += 150; // change 200 to desired scroll amount
  };

  return (
    <div className="relative">
      {/* Left Chevron */}
      <button
        onClick={scrollLeft}
        className="absolute z-10 left-0 top-1/2 transform -translate-y-1/2 text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5x ml-4"
      >
        ◀️
      </button>

      <motion.div
        animate={{ opacity: 1, x: 0 }}
        initial={{ opacity: 0, x: "-50vw" }}
        transition={{ delay: 1, type: "tween" }}
        ref={carousel}
        className="hover:cursor-grab overflow-hidden mt-6"
        whileTap={{ cursor: "grabbing" }}
        whileHover={{ cursor: "grab" }}
        style={{ overflow: "hidden" }}
      >
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          className="cursor-grab bg-otherwhite"
        >
          <div className="inline-flex w-screen">
            {Features.map((item, index) => {
              return (
                <motion.div key={index} className="min-w-1/3 m-2 cursor-grab">
                  <img src={item.image} className="" alt="feature" />
                  <div className="flex flex-col items-center">
                    <div className="flex text-6xl">
                      {/* {item.icon} */}
                    </div>
                    <span className="mt-6 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-headers font-bold">
                      {item.heading}
                    </span>
                    <br />
                    <span className="text-center mt-2 font-body font-light text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
                      {item.description}
                    </span>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </motion.div>
      </motion.div>

      {/* Right Chevron */}
      <button
        onClick={scrollRight}
        className="absolute z-10 right-0 top-1/2 transform -translate-y-1/2 text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5x mr-4"
      >
        ▶️
      </button>
    </div>
  );
}

export default Feature;
