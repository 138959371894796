import React, {useState, useEffect} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { requestToken, messaging } from '../../firebase/firebase.utils';  // Make sure you've imported messaging correctly
import { onMessage } from 'firebase/messaging';

const Notification = () => {
  const [notification, setNotification] = useState({title: '', body: ''});
  const notify = () => toast(<ToastDisplay/>);

  function ToastDisplay() {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    requestToken();

    const onMessageListener = () => new Promise((resolve, reject) => {
      onMessage(messaging, (payload) => {
        // console.log("payload", payload);
        resolve(payload);
      });
    });

    onMessageListener()
      .then((payload) => {
        setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
      })
      .catch((err) => console.log('failed: ', err));

    if (notification?.title) {
      notify();
    }
  }, [notification]);

  return (
    <Toaster />
  );
}

export default Notification;
