import React from "react";

import {Articles} from "../../zombiedata/articles-hero";

import { Link } from "react-router-dom";

function ArticleHero() {

    return (
            <div className="grid sm:grid-cols-3 gap-6 mt-6 mb-6" >            
                {Articles.map((item, index) => {
                    return (
                        <div key={index} className="w-10/12 h-full relative rounded text-black shadow-xl m-8 m-auto mt-3">
                            <div className="bg-otherwhite">
                                <img src={item.image} key={index} alt="" className="h-1/3 w-full opacity-100 rounded" />
                                <div className="text-center p-4 m-2">
                                    <h1 className="mt-6 text-center mt-2 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center font-headers font-bold">{item.heading}</h1>
                                    <p className="mt-6 text-center mt-2 font-body font-light text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-regular ">{item.description}</p>
                                </div>
                                <div className="flex flex-col justify-items items-center font-body font-semibold text-center p-2 m-2">
                                    <button href="#" className="">
                                            {
                                                item.calltoaction === "DOWNLOAD" ? 
                                                <a href={item.path} target={item.target} className={item.path === "#" ? "cursor-not-allowed text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4  text-black font-bold rounded hover:text-white border-2 border-red hover:border-black hover:bg-red" : "cursor-pointer w-full mt-4 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-1/2 pt-2 inline rounded box-border mt-2 px-6 py-2 border-1 rounded text-center cursor-pointer w-min-xl m-auto px-6 py-2.5 cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green hover:border-black rounded text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out" }>
                                                {item.calltoaction}
                                                </a> :
                                                <Link to={item.path} target={item.target} className={item.path === "#" ? "cursor-not-allowed text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4  text-black font-bold rounded hover:text-white border-2 border-red hover:border-black hover:bg-red" : "cursor-pointer w-full mt-4 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl w-1/2 pt-2 inline rounded box-border mt-2 px-6 py-2 border-1 rounded text-center cursor-pointer w-min-xl m-auto px-6 py-2.5 cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green hover:border-black rounded text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out" }>
                                                {item.calltoaction}
                                                </Link>
                                            }
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
    );
}

export default ArticleHero