import React, {useState, useEffect, useContext} from "react";
import {db} from "../../../firebase/firebase.utils.js";

import {LMContext} from "../../../helpers/context3.js";
import {ScoreContext} from "../../../helpers/contexts.js"
import {motion} from "framer-motion";
import { ModalContext } from "../../../helpers/modalcontext.js";
import BeatLoader from "react-spinners/BeatLoader";
import { optionChosenContext, feedbackContext } from "../../../helpers/context3.js";

import { query, where, collection, getDocs } from "firebase/firestore"; 


function MatchingB() {
  const {learningMaterialId} = useContext(LMContext);
  const {score, setScore} = useContext(ScoreContext);
  const [optionStates, setOptionStates] = useState(Array(5).fill({ chosen: 0, class: false }));
  const {setOptionChosen} = useContext(optionChosenContext);
  const {setFeedback} = useContext(feedbackContext);
  const {modal} = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const [, setAttempts] = useState(0);
  const [firstOptionIndex, setFirstOptionIndex] = useState(null);
  const [headings, setHeadings] = useState(null);

  
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  useEffect(() => {
    const getExercises = async () => {
      try {
        const q = query(
          collection(db, "exercises"),
          where("day", "==", learningMaterialId),
          where("type", "==", "matchingb")
        );
  
        const data = await getDocs(q);
        const newExercises = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setHeadings(newExercises);
        console.log(newExercises);
  
        // Check if newExercises is empty
        if (newExercises.length === 0) {
          return;
        }
  
        setFeedback(false);
  
        // Flatten the options and link them to their respective exercises.
        let newOptionStates = [];
        newExercises.forEach((exercise, exerciseIndex) => {
          exercise.options.forEach((option, optionIndex) => {
            // For each option, push twice: once for valueA, once for valueB
            // with the same pairId but different uniqueIds.
            newOptionStates.push({
              chosen: 0,
              class: false,
              isCorrect: false,
              isDisabled: false,
              value: option.a, // First entry with valueA
              pairId: `${exerciseIndex}-${optionIndex}`,
              uniqueId: `${exerciseIndex}-${optionIndex}-A`,
            });
            newOptionStates.push({
              chosen: 0,
              class: false,
              isCorrect: false,
              isDisabled: false,
              value: option.b, // Second entry with valueB
              pairId: `${exerciseIndex}-${optionIndex}`,
              uniqueId: `${exerciseIndex}-${optionIndex}-B`,
            });
          });
        });
  
        // Shuffle the newOptionStates array
        shuffleArray(newOptionStates);
  
        setOptionStates(newOptionStates);
  
      } catch (error) {
        console.error("Failed to fetch exercises:", error);
      }
    };
  
    getExercises();
  }, [learningMaterialId]);
  
  
    // Dependency ensures this runs whenever `learningMaterialId` changes
  
    const handleOptionClick = (clickedUniqueId) => {      
      const clickedOption = optionStates.find(option => option.uniqueId === clickedUniqueId);
  
    
      // If clicked option is already disabled, do nothing
      if (clickedOption.isDisabled) {
        return;
      }
    
      // If first option has not been selected yet, set it
      if (firstOptionIndex === null) {
        setOptionStates((prevState) => prevState.map((option) => {
          if (option.uniqueId === clickedUniqueId) {
            return {
              ...option,
              isSelected: true,
            };
          }
          return option;
        }));
        setFirstOptionIndex(clickedUniqueId);
        return;
      }
    
      // Reset all isSelected flags
      setOptionStates((prevState) => prevState.map((option) => {
        return {
          ...option,
          isSelected: false,
        };
      }));
    
      const firstOption = optionStates.find(option => option.uniqueId === firstOptionIndex);
    
      if (firstOption.pairId === clickedOption.pairId && clickedUniqueId !== firstOptionIndex) {
        // Update the state of both options to correct and disabled
        setOptionStates((prevState) => prevState.map((option) => {
          if (option.uniqueId === clickedUniqueId || option.uniqueId === firstOptionIndex) {
            return {
              ...option,
              isCorrect: true,
              isDisabled: true,
              isSelected: false,
            };
          }
          return option;
        }));

        // setFeedback("correct");
      } else {
        // Options do not match
        setAttempts((prevAttempts) => {
          if (prevAttempts >= 3) {
            setOptionChosen("disabled");
            setFeedback("incorrect");
          }
          return prevAttempts + 1;
        });
      }
    
      // Reset the first option index for the next turn
      setFirstOptionIndex(null);
    };

    useEffect(() => {
      const allPairsMatched = optionStates.every((option) => option.isDisabled);
      if (allPairsMatched) {
        setFeedback("correct");
        setScore(score + 1);
      }
    }, [optionStates]);


const ExerciseOption = ({ option, handleOptionClick }) => {
  return (
    <div className="exercise-option">
      <label>
        <input
          className={
            option?.isCorrect // Using ?. to avoid undefined issues
              ? "mt-4 capitalize cursor-not-allowed bg-darkgray text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body text-center px-4 py-4 m-2 text-gray font-bold hover:text-gray border border-darkgray rounded" : 
              option?.isSelected 
              ? "mt-4 capitalize bg-white text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body shadow-lg text-center rounded px-4 py-4 m-2 border border-green cursor-pointer focus:border-green focus:border-1 text-green hover:text-gray hover:border-gray"
              : "mt-4 capitalize bg-white text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body shadow-lg text-center rounded px-4 py-4 m-2 border border-black cursor-pointer hover:text-gray hover:border-gray"
          }
          id="answer"
          onClick={() => handleOptionClick(option.uniqueId)}
          type="button"
          value={option?.value?.toUpperCase()}
          disabled={option?.isDisabled}
        />
      </label>
    </div>
  );
};

useEffect(() => {
  setLoading(true)
  setTimeout(() => {
    setLoading(false)
  }, 750)
}, [])


return (
  <motion.div className="h-full w-full bg-white m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
    {loading ? 
    <div className="flex flex-col justify-center items-center h-full min-h-[500px]">
      <BeatLoader color={'#848717'} loading={loading} size={15} /> 
    </div> :
    <div className={modal !== true ? "flex flex-col justify-start items-center text-black min-h-[500px]" : "hidden"} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.1}}>
      <div className="text-5xl hidden">🤹🏾‍♂️</div>
      {headings?.filter(headings => headings.day === learningMaterialId && headings.type === "matchingb")
      .map((headings, index, array) => {
          return (
            <h3 className="font-headers font-semibold text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-center px-1 normal-case">
              {headings.question.toUpperCase()} 
              <p></p>
            </h3>
          );
      })}
      <form
        action=""
        className="w-3/4 flex flex-col justify-start items-center"
      >
        <div className="flex w-3/4 flex-row flex-wrap justify-center text-center">
          {optionStates.map((option) => (
            <ExerciseOption
              key={option.uniqueId}
              option={option}
              handleOptionClick={handleOptionClick} 
            />
          ))}
        </div>
        <hr />
        <br />
      </form>
    </div>}
  </motion.div>
);
}

export default MatchingB;