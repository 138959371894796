
export const SkillsData = [
    {
        name: "Greetings",
        emoji: "👋🏿",
        id: 1,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Grocery Shopping",
        emoji: "🛒",
        id: 2,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Meet My Family",
        emoji: "👨‍👧",
        id: 3,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Preparing My CV",
        emoji: "📄",
        id: 4,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "School",
        emoji: "👩🏾‍🏫",
        id: 5,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Asking for Directions",
        emoji: "🌴",
        id: 6,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Leisure Time",
        emoji: "📆",
        id: 7,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Conversation Starters",
        emoji: "🤝",
        id: 8,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "What's Cooking?",
        emoji: "🍛",
        id: 9,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Meet My Family II",
        emoji: "👨‍👩‍👧‍👦",
        id: 10,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Acing The Interview",
        emoji: "👔",
        id: 11,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Academics I",
        emoji: "🚌",
        id: 12,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Giving Directions",
        emoji: "🪧",
        id: 13,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Let's Play Netball",
        emoji: "🏐",
        id: 14,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Gratitude & Apologies",
        emoji: "🙌",
        id: 15,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Meals of the Day",
        emoji: "🍜",
        id: 16,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Friendship",
        emoji: "👯",
        id: 17,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Job Training",
        emoji: "👷🏾‍♂️",
        id: 18,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Academics II",
        emoji: "📚",
        id: 19,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Climate Chats",
        emoji: "☁️",
        id: 20,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Peer Pressure",
        emoji: "😰",
        id: 21,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Well-Being",
        emoji: "😶",
        id: 22,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "My Favourite Recipe",
        emoji: "🍱",
        id: 23,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "My Future",
        emoji: "🔮",
        id: 24,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Working with Clients",
        emoji: "🤝",
        id: 25,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Extra-Curriculars",
        emoji: "⛹🏻",
        id: 26,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Taxi",
        emoji: "🚍",
        id: 27,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Sports II",
        emoji: "🏸",
        id: 28,
        course: "isiZuluSocial",
        unitId: 1
    },
    {
        name: "Crime",
        emoji: "🚓",
        id: 29,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Cooking Utensils",
        emoji: "🍴",
        id: 30,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Doing Chores",
        emoji: "🧹",
        id: 31,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "I Got A Job",
        emoji: "🎉",
        id: 32,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Did You Do Your Homework?",
        emoji: "📝",
        id: 33,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Cab",
        emoji: "📝",
        id: 34,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Travel II",
        emoji: "🌍",
        id: 35,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Medical",
        emoji: "🚑",
        id: 36,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Dieting And Nutrition",
        emoji: "🥦",
        id: 37,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Me, Myself & I",
        emoji: "🤳",
        id: 38,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "I Need Money",
        emoji: "💵",
        id: 39,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "School & Work II",
        emoji: "🏢",
        id: 40,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Ride Hailing",
        emoji: "🚘",
        id: 41,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "The Sports I Like",
        emoji: "🏆",
        id: 42,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Fire",
        emoji: "🔥",
        id: 43,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Food III",
        emoji: "🍔",
        id: 44,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Making New Friends",
        emoji: "🤗",
        id: 45,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "No Refund",
        emoji: "❌",
        id: 46,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "School & Work III",
        emoji: "📅",
        id: 47,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Bus",
        emoji: "🚌",
        id: 48,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "The Music I Like",
        emoji: "🎵",
        id: 49,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Emergency: Flood",
        emoji: "🌊",
        id: 50,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Food IV",
        emoji: "🍕",
        id: 51,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Me, Myself & I II",
        emoji: "🤳",
        id: 52,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Unemployment",
        emoji: "😔",
        id: 53,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Education I",
        emoji: "🎓",
        id: 54,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Train",
        emoji: "🚂",
        id: 55,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "Movies & Series I Like",
        emoji: "🎬",
        id: 56,
        course: "isiZuluSocial",
        unitId: 2
    },
    {
        name: "The Zulu Alphabet",
        emoji: "🔤",
        id: 57,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Food V",
        emoji: "🍝",
        id: 58,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "My Morning Routine",
        emoji: "⏰",
        id: 59,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Chatting With Colleagues",
        emoji: "🗣️",
        id: 60,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Education II",
        emoji: "📘",
        id: 61,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Train",
        emoji: "🚄",
        id: 62,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Gold Reef City",
        emoji: "🌆",
        id: 63,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Music Discussions",
        emoji: "🎧",
        id: 64,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Food VI",
        emoji: "🍩",
        id: 65,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "My Peers",
        emoji: "👭",
        id: 66,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Economy I",
        emoji: "💹",
        id: 67,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Education III",
        emoji: "📗",
        id: 68,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Ship",
        emoji: "🚢",
        id: 69,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Colors",
        emoji: "🌈",
        id: 70,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Doctor's Appointment",
        emoji: "🩺",
        id: 71,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Eating Out",
        emoji: "🍽️",
        id: 72,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Surprise Date",
        emoji: "❤️",
        id: 73,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Economy II",
        emoji: "💸",
        id: 74,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Education IV",
        emoji: "📙",
        id: 75,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Airplane",
        emoji: "✈️",
        id: 76,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "30 Seconds",
        emoji: "⏲️",
        id: 77,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Telling Time",
        emoji: "⌚",
        id: 78,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Ordering Food",
        emoji: "📞",
        id: 79,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Cleaning & Personal Hygiene",
        emoji: "🛁",
        id: 80,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Economy III",
        emoji: "📉",
        id: 81,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Education V",
        emoji: "📕",
        id: 82,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Car",
        emoji: "🚗",
        id: 83,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name: "Mall of Africa",
        emoji: "🛍️",
        id: 84,
        course: "isiZuluSocial",
        unitId: 3
    },
    {
        name:  "Descriptions",
        emoji:  "🗣️",
        id:  85,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Organizing a Braai",
        emoji:  "🔥",
        id:  86,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Importance of Mothers",
        emoji:  "👩‍👧",
        id:  87,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Apartment Hunting",
        emoji:  "🏙️",
        id:  88,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "School Ground",
        emoji:  "🏫",
        id:  89,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Geography I",
        emoji:  "🌍",
        id:  90,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Gym Membership",
        emoji:  "🏋️",
        id:  91,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Occupations",
        emoji:  "👔",
        id:  92,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Zulu Cuisine",
        emoji:  "🍛",
        id:  93,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Babysitting",
        emoji:  "👶",
        id:  94,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Starting a Business",
        emoji:  "💼",
        id:  95,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Grade R to Uni",
        emoji:  "🎓",
        id:  96,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Gone Camping",
        emoji:  "⛺",
        id:  97,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Going Out For A Drink",
        emoji:  "🍻",
        id:  98,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Giving and Asking for Information",
        emoji:  "📢",
        id:  99,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Lunch Date",
        emoji:  "🍴",
        id:  100,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Talking to Family",
        emoji:  "👪",
        id:  101,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Attending a Meeting",
        emoji:  "📅",
        id:  102,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "School Subjects",
        emoji:  "📚",
        id:  103,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Weather",
        emoji:  "⛅",
        id:  104,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Apartheid Museum",
        emoji:  "🏛️",
        id:  105,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Holidays, Days, Months, Weeks, Years",
        emoji:  "📆",
        id:  106,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Making a Reservation",
        emoji:  "📞",
        id:  107,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Where Were You Yesterday?",
        emoji:  "🕰️",
        id:  108,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Negotiating a Raise",
        emoji:  "💰",
        id:  109,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Back To School",
        emoji:  "🎒",
        id:  110,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Taxi II",
        emoji:  "🚖",
        id:  111,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Beach",
        emoji:  "🏖️",
        id:  112,
        course:  "isiZuluSocial",
        unitId:  4
    },
    {
        name:  "Making Plans",
        emoji:  "📝",
        id:  113,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Livestock",
        emoji:  "🐄",
        id:  114,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Tour of The House",
        emoji:  "🏡",
        id:  115,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Giving Feedback",
        emoji:  "📈",
        id:  116,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Back To School II",
        emoji:  "🎒",
        id:  117,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Cab II",
        emoji:  "🚕",
        id:  118,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Market",
        emoji:  "🛍️",
        id:  119,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Earthquake",
        emoji:  "🪨",
        id:  120,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Breakfast",
        emoji:  "🍳",
        id:  121,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Blind Date",
        emoji:  "❤️",
        id:  122,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Post Office",
        emoji:  "📬",
        id:  123,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Teaching Children",
        emoji:  "👨‍🏫",
        id:  124,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Ride Hailing",
        emoji:  "🚗",
        id:  125,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Arts & Recreation I",
        emoji:  "🎨",
        id:  126,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Police Report",
        emoji:  "🚓",
        id:  127,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Lunch",
        emoji:  "🍽️",
        id:  128,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "1st Date",
        emoji:  "❤️",
        id:  129,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Barbershop / Salon",
        emoji:  "💇",
        id:  130,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Talking to the Teacher",
        emoji:  "👩‍🏫",
        id:  131,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Bus II",
        emoji:  "🚌",
        id:  132,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Arts & Recreation II",
        emoji:  "🎭",
        id:  133,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Opening a Case",
        emoji:  "🔍",
        id:  134,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Dinner",
        emoji:  "🍲",
        id:  135,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Double Date",
        emoji:  "💓",
        id:  136,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Groceries",
        emoji:  "🛒",
        id:  137,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Talking to the Class",
        emoji:  "🎙️",
        id:  138,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Train II",
        emoji:  "🚄",
        id:  139,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Arts & Recreation III",
        emoji:  "🎵",
        id:  140,
        course:  "isiZuluSocial",
        unitId:  5
    },
    {
        name:  "Current Affairs",
        emoji:  "📰",
        id:  141,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Just Like Mama Used To Make It",
        emoji:  "🥘",
        id:  142,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Dad Is Coming Home",
        emoji:  "👨‍👧",
        id:  143,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Laundry",
        emoji:  "🧺",
        id:  144,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Speaking to Friends",
        emoji:  "🗣️",
        id:  145,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Train II",
        emoji:  "🚅",
        id:  146,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Arts & Recreation IV",
        emoji:  "🎬",
        id:  147,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Over the Phone",
        emoji:  "📞",
        id:  148,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Trying Out A New Diet",
        emoji:  "🥦",
        id:  149,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Lovers",
        emoji:  "❤️",
        id:  150,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Clothes",
        emoji:  "👗",
        id:  151,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Detention",
        emoji:  "⛓️",
        id:  152,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Ship II",
        emoji:  "🚢",
        id:  153,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Football Practice",
        emoji:  "⚽",
        id:  154,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Social Media",
        emoji:  "📱",
        id:  155,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Drive Through",
        emoji:  "🍔",
        id:  156,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Why Work, Everyday?",
        emoji:  "💼",
        id:  157,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Mechanic",
        emoji:  "🔧",
        id:  158,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Studying with Friends",
        emoji:  "📖",
        id:  159,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Airplane II",
        emoji:  "✈️",
        id:  160,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Arts & Recreation V",
        emoji:  "🎸",
        id:  161,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Complaints",
        emoji:  "🗣️",
        id:  162,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Card Declined",
        emoji:  "💳",
        id:  163,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Brotherly Advice",
        emoji:  "👦",
        id:  164,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Economy IV",
        emoji:  "💹",
        id:  165,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Graduation",
        emoji:  "🎓",
        id:  166,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Car II",
        emoji:  "🚗",
        id:  167,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name:  "Arts & Recreation VI",
        emoji:  "🎤",
        id:  168,
        course:  "isiZuluSocial",
        unitId:  6
    },
    {
        name: "Numbers: 1 - 10",
        emoji: "1️⃣",
        id: "169",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Vegetables",
        emoji: "🥦",
        id: "170",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Birthdays",
        emoji: "🎂",
        id: "171",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Economy V",
        emoji: "💹",
        id: "172",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Post Graduation",
        emoji: "🎓",
        id: "173",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Rush Hour Traffic",
        emoji: "🚦",
        id: "174",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Going to the Gym",
        emoji: "🏋️",
        id: "175",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Questions",
        emoji: "❓",
        id: "176",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Fruits",
        emoji: "🍎",
        id: "177",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Family Reunion",
        emoji: "👨‍👩‍👧‍👦",
        id: "178",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Connecting The Water & Electricity",
        emoji: "💧",
        id: "179",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Online Learning",
        emoji: "💻",
        id: "180",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Weather II",
        emoji: "🌦️",
        id: "181",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Arts & Recreation VII",
        emoji: "🎨",
        id: "182",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Life Events",
        emoji: "📅",
        id: "183",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Meat",
        emoji: "🥩",
        id: "184",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Back From School",
        emoji: "🎒",
        id: "185",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Registering Your Business",
        emoji: "💼",
        id: "186",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Online Learning II",
        emoji: "💻",
        id: "187",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Politics",
        emoji: "🗳️",
        id: "188",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Netballing",
        emoji: "🏐",
        id: "189",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Preferences",
        emoji: "👍",
        id: "190",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Starch",
        emoji: "🥔",
        id: "191",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Running Errands",
        emoji: "🏃‍♂️",
        id: "192",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Pitching Your Business Idea",
        emoji: "🗣️",
        id: "193",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Education VIII",
        emoji: "📚",
        id: "194",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Municipality of Ethekwini",
        emoji: "🏢",
        id: "195",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Last Nights Game",
        emoji: "🏀",
        id: "196",
        course: "isiZuluSocial",
        unitId: 7
    },
    {
        name: "Body Parts",
        emoji: "👂",
        id: "197",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Snacks",
        emoji: "🍪",
        id: "198",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Moving out the House",
        emoji: "📦",
        id: "199",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Bank",
        emoji: "🏦",
        id: "200",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Easiest Subjects",
        emoji: "✅",
        id: "201",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Taxi III",
        emoji: "🚕",
        id: "202",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Radio Stations",
        emoji: "📻",
        id: "203",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Making An Appointment",
        emoji: "📅",
        id: "204",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Gardening",
        emoji: "🌷",
        id: "205",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Breaking Up",
        emoji: "💔",
        id: "206",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Foreign Exchange",
        emoji: "💱",
        id: "207",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Cramming",
        emoji: "⏳",
        id: "208",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Pollution",
        emoji: "💨",
        id: "209",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Watching A Sports Event",
        emoji: "📺",
        id: "210",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Regular Check Up",
        emoji: "🩺",
        id: "211",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Children's Diet",
        emoji: "👶",
        id: "212",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Family Function",
        emoji: "🎉",
        id: "213",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Chatting WIth Colleages II",
        emoji: "👥",
        id: "214",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Public vs Private School",
        emoji: "🏫",
        id: "215",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "How Long As A Driver?",
        emoji: "⌛",
        id: "216",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Soapies",
        emoji: "🎭",
        id: "217",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Numbers: 11 - 25",
        emoji: "1️⃣",
        id: "218",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Weight Loss & Gain",
        emoji: "⚖️",
        id: "219",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Moving In Together",
        emoji: "🏠",
        id: "220",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Post Office II",
        emoji: "📬",
        id: "221",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Homework",
        emoji: "🏡",
        id: "222",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Pros & Cons",
        emoji: "🚌",
        id: "223",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "YouTube",
        emoji: "📹",
        id: "224",
        course: "isiZuluSocial",
        unitId: 8
    },
    {
        name: "Numbers: 26 - 50",
        emoji: "2️⃣",
        id: "225",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Healthy vs Unhealthy Foods",
        emoji: "🥦",
        id: "226",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Marriage Proposal",
        emoji: "💍",
        id: "227",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Sim Swap",
        emoji: "↔️",
        id: "228",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Discipline",
        emoji: "🚫",
        id: "229",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Longest Journey",
        emoji: "🚄",
        id: "230",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Artists",
        emoji: "🎤",
        id: "231",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Numbers: 51 - 100",
        emoji: "5️⃣",
        id: "232",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "GMOs vs Organic Foods",
        emoji: "🆚",
        id: "233",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Lobola Negotiations",
        emoji: "💍",
        id: "234",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Spaza Shop",
        emoji: "🏪",
        id: "235",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Choosing a University",
        emoji: "🤔",
        id: "236",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Travel Purposes",
        emoji: "🧳",
        id: "237",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Hiking",
        emoji: "🥾",
        id: "238",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Regular Check Up II",
        emoji: "🩺",
        id: "239",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Beverages",
        emoji: "🥤",
        id: "240",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Wedding",
        emoji: "💍",
        id: "241",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Bakery",
        emoji: "🥖",
        id: "242",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Why Univeristy?",
        emoji: "❓",
        id: "243",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Trade Ordered Goods",
        emoji: "🚢",
        id: "244",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Drama",
        emoji: "🎭",
        id: "245",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Common Words",
        emoji: "🔤",
        id: "246",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Sphatlo",
        emoji: "🥪",
        id: "247",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Funeral",
        emoji: "⚰️",
        id: "248",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Barbershop / Salon II",
        emoji: "💇‍♂️",
        id: "249",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Dropping Out",
        emoji: "🚫",
        id: "250",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Airplane III",
        emoji: "✈️",
        id: "251",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Karaoke",
        emoji: "🎵",
        id: "252",
        course: "isiZuluSocial",
        unitId: 9
    },
    {
        name: "Problems",
        emoji: "🔧",
        id: "253",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Butchery",
        emoji: "🥩",
        id: "254",
        course: "isiZuluSocial",
        unitId: 10
    },
    // {
    //     name: "Brotherly Love",
    //     emoji: "👬",
    //     id: "255",
    //     course: "isiZuluSocial",
    //     unitId: 10
    // },
    // {
    //     name: "Buying Furniture",
    //     emoji: "🛋️",
    //     id: "256",
    //     course: "isiZuluSocial",
    //     unitId: 10
    // },
    {
        name: "School Trips",
        emoji: "🚸",
        id: "257",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Geography VI",
        emoji: "🌍",
        id: "258",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Getting a Personal Trainer",
        emoji: "🏋️",
        id: "259",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Protest / Strike",
        emoji: "✊",
        id: "260",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Processed & Natural Foods",
        emoji: "🆚",
        id: "261",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Relationships IV",
        emoji: "💑",
        id: "262",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Talking To Clients",
        emoji: "🗣️",
        id: "263",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Doing a Group Assignment",
        emoji: "👥",
        id: "264",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Weekend Getaway",
        emoji: "🛄",
        id: "265",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "News",
        emoji: "📰",
        id: "266",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Car Breakdown",
        emoji: "💥",
        id: "267",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Drinks",
        emoji: "🍹",
        id: "268",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Out With Friends",
        emoji: "🍻",
        id: "269",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Parliament",
        emoji: "🏛️",
        id: "270",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Cheating On A Test",
        emoji: "👀",
        id: "271",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Trip To See Gogo",
        emoji: "👵",
        id: "272",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Movies",
        emoji: "🎬",
        id: "273",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Mistaken Identity",
        emoji: "❓",
        id: "274",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Favourite Food For Life",
        emoji: "🍕",
        id: "275",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "How Was Your Day?",
        emoji: "🌅",
        id: "276",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Bank II",
        emoji: "🏦",
        id: "277",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Assembly",
        emoji: "👥",
        id: "278",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Fully Loaded",
        emoji: "🚖",
        id: "279",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "Concert",
        emoji: "🎤",
        id: "280",
        course: "isiZuluSocial",
        unitId: 10
    },
    {
        name: "People & Things",
        emoji: "🧍",
        id: "281",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Dessert",
        emoji: "🍰",
        id: "282",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Relationships VII",
        emoji: "🔒",
        id: "283",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "New Ideas",
        emoji: "💡",
        id: "284",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Applying for University",
        emoji: "📝",
        id: "285",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Global Warming",
        emoji: "🌍",
        id: "286",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Poetry",
        emoji: "🖋️",
        id: "287",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Telling Stories",
        emoji: "📖",
        id: "288",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Braai II",
        emoji: "🔥",
        id: "289",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Relationships VIII",
        emoji: "🥰",
        id: "290",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Resignation",
        emoji: "✉️",
        id: "291",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Changing Courses",
        emoji: "🔄",
        id: "292",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Renewable Energy",
        emoji: "🔌",
        id: "293",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Negotiating Prices",
        emoji: "💲",
        id: "294",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Personal Events",
        emoji: "📖",
        id: "295",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Dine & Dash",
        emoji: "🏃",
        id: "296",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Relationships IX",
        emoji: "👩‍❤️‍👨",
        id: "297",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Filing A Complaint",
        emoji: "📝",
        id: "298",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Deregistering a Module",
        emoji: "📖",
        id: "299",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "ESKOM",
        emoji: "⚡️",
        id: "300",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Safari",
        emoji: "🦁",
        id: "301",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Asking For A Favour",
        emoji: "🙏",
        id: "302",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Favourite Recipe II",
        emoji: "🍲",
        id: "303",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Relationships X",
        emoji: "💞",
        id: "304",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Meeting II",
        emoji: "📅",
        id: "305",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Campus Life",
        emoji: "🏫",
        id: "306",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Littering",
        emoji: "🚯",
        id: "307",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Shisa Nyama",
        emoji: "🍗",
        id: "308",
        course: "isiZuluSocial",
        unitId: 11
    },
    {
        name: "Possibilities",
        emoji: "💭",
        id: "309",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "This Meal",
        emoji: "🍽️",
        id: "310",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Divorce",
        emoji: "💔",
        id: "311",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Laundry II",
        emoji: "👕",
        id: "312",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Borrowing A Book",
        emoji: "📚",
        id: "313",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "On My Way Home",
        emoji: "🚉",
        id: "314",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Wine Tasting",
        emoji: "🍷",
        id: "315",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Abilities",
        emoji: "💪",
        id: "316",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Dinner Date",
        emoji: "🕯️",
        id: "317",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Anniversary",
        emoji: "❤️",
        id: "318",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Talking To The Landlord",
        emoji: "🏠",
        id: "319",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Disciplinary Hearing",
        emoji: "⚖️",
        id: "320",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Stranded At Sea",
        emoji: "🛳️",
        id: "321",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Marathon",
        emoji: "🏃‍♂️",
        id: "322",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Comparisons",
        emoji: "🍊",
        id: "323",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Food Poisoning",
        emoji: "🤢",
        id: "324",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Dating",
        emoji: "🍷",
        id: "325",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Mechanic II",
        emoji: "🔧",
        id: "326",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Speaking to Friends II",
        emoji: "👫",
        id: "327",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Airplane IV",
        emoji: "✈️",
        id: "328",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Art Show",
        emoji: "🖼️",
        id: "329",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Talking About Locations",
        emoji: "📍",
        id: "330",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Home Cooking vs Restaurant",
        emoji: "🍳",
        id: "331",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Relationship Roles",
        emoji: "👰",
        id: "332",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Unemployment II",
        emoji: "🏢",
        id: "333",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Consulting a Lecturer",
        emoji: "👩‍🏫",
        id: "334",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Car Rental",
        emoji: "🔑",
        id: "335",
        course: "isiZuluSocial",
        unitId: 12
    },
    {
        name: "Festival",
        emoji: "🎉",
        id: "336",
        course: "isiZuluSocial",
        unitId: 12
    }
]