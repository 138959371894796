export const FFAQData = [
  {
    question: "What does fluency even mean for us?",
    answer:
      "According to Barkhuizen there are three elements to what most people understand as 'fluency,' namely: Accuracy, Complexity, & Fluency. Therefore, 'fluency' is the ability to process the 2nd language with 'native-like' rapidity or the extent to which the language produced in performing a task manifests pausing, hesitation, or reformation. However, our definition is not limited to languages but is a more broad concept that speaks to mastery in a specific skill set or area of knowledge which we believe is more expansive and suited to our objectives."
  },
  {
    question: "How long until I become fluent?",
    answer:
      "Generally speaking the answer is: it depends. Many academics have have researched this question under varying conditions and the consensus is that it really is up to you as an individual and how much time you spend deliberately practicing your target language."
  },
  {
    question: "What resources do I get access to?",
    answer:
      "Study Guides, Texts (Dialogues, Short Stories, Folk Tales, etc.), Exercises, Coaching Sessions, Vocabulary Lists & many more as we keep producing new kinds of learning material literally every day."
  },
  {
    question: "How does the learning take place?",
    answer:
      "It is up to you to get the most out of what we have to offer by booking regular coaching sessions with our mother tongue speaking language coaches or to incorporate our electronic lesson materials into your own self-study schedules. Either way you will have access to all of our materials geared towards getting you speaking in little to no time at all. Once you are given access to our materials you will have to complete a certain number of modules before you can be considered fluent."
  },
  {
    question:
      "What objective measure or criteria do we use to determine when you are 'fluent?''",
    answer:
      "OK so earlier we spoke about what fluency means for us and what are some sub components of the general academically accepted definition of fluency click here if you missed that. Bearing all of the above in mind we’ll use an analogy to explain here. So our neighbours down in Europe have an evaluation criteria specifically for this purpose when it comes to assessing the oral proficiency of speakers of various European languages known as the CEFR standard you can find out more about this on the official CEFR website —— but basically we did a lot of research and couldn’t find any reliable equivalent for our African Languages so we decided we would build our own system."
  },
  {
    question: "How much does it all cost?",
    answer:
      "Our entry level subscription plan is free. Paid members get access to more advanced features. For more information please consult our Pricing page."
  },
  {
    question: "How will I be assessed against the different learning outcomes?",
    answer:
      "We created our own assessment methods that we use here at FFA in order to measure your level of progress. Firstly we use our native mother tongue speaking language coaches to do objective assessments of your proficiency at any given time and we also employ a variety of assessment based tasks and challenges that we measure against the standard of fluency we talked about earlier."
  },
  {
    question: "Why is the continent of Africa in your logo updside down?",
    answer: "Is it? 🤨🧐🤔🙆‍♂️🤯 "
  },
  {
    question:
      "The language I want to learn isn't available yet - what can I do?",
    answer:
      "If your target language is currently unavailable you can always notify us so that we can gauge the existing demand for the language and if there is enough interest (at least 10 - 25 other requests) we will prepare a beta program just for you, with limited resources primarily centred around coaching sessions, for you to start learning the language you want as soon as you can."
  },
  {
    question: "How do we use or handle your personal data that we get from 3rd party providers like Google?",
    answer:
      "Fast Fluency Africa's use of information received from Google APIs adheres to Google's API Services User Data Policy, including the Limited Use requirements."
  },
  {
    question: "How many languages do you currently provide courses for?",
    answer:
      "At the moment we are focusing all of our energies on finalizing our isiZulu product lines (academic and vocational) so that we have a solid pedagogical foundation to build from when we expand to other African languages beyond the South African borders i.e. Kiswahili, Igbo, Yoruba, Amharic, Kikuyu, Chichewa, Shona etc."
  },
  {
    question: "Do we offer courses for specific learning needs?",
    answer:
      "Yes, we are actively developing learning materials for three specific demographics, in particular people with An academic requirement, A social motivation or, A work related or vocational incentive. Right now we currently only have the social product line ready for use specifically for isiZulu but during the 2023/2024 financial year we will be piloting our academic line starting with Grades R - 6 for isiZulu, aligned with the CAPS documents."
  },
  {
    question: "What positions does your company still need to fill?",
    answer:
      "We are still in our very early days so we have quite a number of vacancies to fill. At our Governance or Executive level we have vacancies in the Council of Elders, COO, CFO, & CEO positions. In our Tech department we are looking for: Front End Developers, UI / UX Designers, Back End Developers, Mobile Developers, & DevOps. Concerning the Language Unit, we currently need: a Language Morena, Head of Service Delivery, Head of Instructional Design, Language Coaches, Instructional Designers, Translators, Voice Over Actors, Proofreaders, & Editors. Lastly, in our Sales & Marketing department we need Sales Reps."
  },
  {
    question:
      "What kind of background or prerequisites do you look for when hiring?",
    answer:
      "We love working with people from all walks of life. If you've never used a computer before it's fine, we'll teach you how. If you barely speak English it's fine, we'll learn your mother tongue. If you have 7 degrees, it's also fine we'll forgive you for knowing too much. If you are fresh out of high school, that is also fine you can come get some work experience with us. Our only real non-negotiable prerequisite is that you must have access to a reliable Internet connection. As we are a remote work inclined organization we rarely do any work in person, so if you can't guarantee your access to internet we won't be able to provide you with a fruitful working experience."
  },
  {
    question: "How can I join the Tribe?",
    answer:
      "Although we are always actively looking for new talent, our experiences in the past have also taught us that sometimes we're not always ready for great talent. So to avoid disappointment we now prefer to only hire annually at the beginning of each new financial year when we have a clearer picture of our staffing needs for the year. If you miss our regular intake period you'll unfortunately just have to wait another year. If you would like to apply so that we have your details when our hiring season kicks in you can always submit your details at the 'Join the Tribe' link in the footer below, but please note that we'll only review your application when our hiring season begins."
  },
  {
    question: "What do you mean by 'edutainment?'",
    answer:
      "Obviously, this is a portmanteau of education and entertainment. Our interpretation of this concept is to create educational experiences which have entertainment value. This means that even in our purely pedagogical products we aspire to make the content as entertaining as possible so that even if you aren't interested in learning you'll still show up just for the entertainment value."
  },
  {
    question: "Does your platform only cater for languages?",
    answer:
      "For now yes, our long term goal is geared towards mother tongue education which is much broader but in order to get there we have decided to start with language acquisition which is the first skill any aspiring learner must master before they can begin acquiring new knowledge."
  },
  {
    question: "What is your long term strategy?",
    answer:
      "You'll see."
  },
];
