
export const DYKData = [
    {
        id: 1,
        type: "dyk",
        factoid: "Mali's Great Mosque of Djenné is the world's largest mud-brick building.",
        emoji: "🕌"
    },
    {
        id: 2,
        type: "dyk",
        factoid: "Sudan's ancient Nubians had a unique script called Meroitic.",
        emoji: "📜"
    },
    {
        id: 3,
        type: "dyk",
        factoid: "The legendary Queen of Sheba ruled the ancient kingdom of Saba (Ethiopia and Yemen).",
        emoji: "👑"
    },
    {
        id: 4,
        type: "dyk",
        factoid: "A powerful West African kingdom existed from the 6th to 13th centuries.",
        emoji: "🏰"
    },
    {
        id: 5,
        type: "dyk",
        factoid: "Mali's 14th-century ruler, Mansa Musa, was history's richest person due to gold wealth.",
        emoji: "💰"
    },
    {
        id: 6,
        type: "dyk",
        factoid: "Present-day Ethiopia and Eritrea's Aksum embraced Christianity early on.",
        emoji: "⛪"
    },
    {
        id: 7,
        type: "dyk",
        factoid: "Zimbabwe's ruins testify to an advanced ancient civilization.",
        emoji: "🏛️"
    },
    {
        id: 8,
        type: "dyk",
        factoid: "Queen Nzinga of Angola resisted Portuguese colonization in the 17th century.",
        emoji: "👸"
    },
    {
        id: 9,
        type: "dyk",
        factoid: "Ethiopian forces triumphed over Italian colonial forces in 1896.",
        emoji: "⚔️"
    },
    {
        id: 10,
        type: "dyk",
        factoid: "Tunisia's ancient city of Carthage rivaled Rome in the Punic Wars.",
        emoji: "🏛️"
    },
    {
        id: 11,
        type: "dyk",
        factoid: "The Ishango bone, a mathematical instrument, was discovered in the Congo Basin and dates back to roughly 20 000 BCE.",
        emoji: "🦴",
    },
    {
        id: 12,
        type: "dyk",
        factoid: "Africa is the second-largest continent, both in land area and population.",
        emoji: "🌍"
    },
    {
        id: 13,
        type: "dyk",
        factoid: "The African elephant is the largest land animal on Earth.",
        emoji: "🐘"
    },
    {
        id: 14,
        type: "dyk",
        factoid: "The Nile River in Africa is the longest river in the world.",
        emoji: "🌊"
    },
    {
        id: 15,
        type: "dyk",
        factoid: "Africa is home to the fastest land animal, the cheetah.",
        emoji: "🐆"
    },
    {
        id: 16,
        type: "dyk",
        factoid: "Mount Kilimanjaro in Tanzania is the tallest freestanding mountain in the world.",
        emoji: "🌋"
    },
    {
        id: 17,
        type: "dyk",
        factoid: "The Congo Basin rainforest in Central Africa is the second-largest tropical rainforest in the world.",
        emoji: "🌴"
    },
    {
        id: 18,
        type: "dyk",
        factoid: "Africa is home to the world's largest population of hippos.",
        emoji: "🦛"
    },
    {
        id: 19,
        type: "dyk",
        factoid: "The Nile crocodile, found in Africa, is one of the largest reptiles on Earth.",
        emoji: "🐊"
    },
    {
        id: 20,
        type: "dyk",
        factoid: "Africa is the most linguistically diverse continent, with over 2,000 languages spoken.",
        emoji: "🌍"
    },
    {
        id: 21,
        type: "dyk",
        factoid: "The ancient city of Timbuktu in Mali was a center of learning and trade in the medieval period.",
        emoji: "🏺"
    },
    {
        id: 22,
        type: "dyk",
        factoid: "The ancient Egyptians prized elephants and used them in warfare and ceremonial processions.",
        emoji: "🐘"
    },
    {
        id: 23,
        type: "dyk",
        factoid: "Lake Victoria, located in East Africa, is the largest lake on the continent.",
        emoji: "🌊"
    },
    {
        id: 24,
        type: "dyk",
        factoid: "The Sahara Desert in Africa is the world's largest hot desert.",
        emoji: "🐫"
    },
    {
        id: 25,
        type: "dyk",
        factoid: "The mysterious stone statues of Great Zimbabwe are a UNESCO World Heritage site.",
        emoji: "🗿"
    },
    {
        id: 26,
        type: "dyk",
        factoid: "The African lion is one of the most iconic animals and a symbol of strength.",
        emoji: "🦁"
    },
    {
        id: 27,
        type: "dyk",
        factoid: "The Namib Desert in Namibia is one of the oldest deserts on the planet.",
        emoji: "🏜️"
    },
    {
        id: 28,
        type: "dyk",
        factoid: "The Blue Nile and White Nile converge in Sudan to form the Nile River.",
        emoji: "🌊"
    },
    {
        id: 29,
        type: "dyk",
        factoid: "The ancient Egyptians used giraffes as exotic pets and symbols of status.",
        emoji: "🦒"
    },
    {
        id: 30,
        type: "dyk",
        factoid: "The medieval Swahili city-states along the East African coast thrived on trade.",
        emoji: "💸"
    },
    {
        id: 31,
        type: "dyk",
        factoid: "The baobab tree, found in Africa, is known as the 'tree of life' due to its ability to store water.",
        emoji: "🌳"
    },
    {
        id: 32,
        type: "dyk",
        factoid: "Ancient Egyptians worshipped the Nile crocodile as a sacred animal.",
        emoji: "🐊"
    },
    {
        id: 33,
        type: "dyk",
        factoid: "The rock-hewn churches of Lalibela in Ethiopia are a UNESCO World Heritage site.",
        emoji: "⛪"
    },
    {
        id: 34,
        type: "dyk",
        factoid: "The Dogon people of Mali have a rich astronomical and mythological tradition.",
        emoji: "🌌"
    },
    {
        id: 35,
        type: "dyk",
        factoid: "The Zulu Kingdom, under King Shaka, became a powerful force in Southern Africa.",
        emoji: "🛡️"
    },
    {
        id: 36,
        type: "dyk",
        factoid: "The Great Mosque of Kairouan in Tunisia is one of the oldest mosques in Africa.",
        emoji: "🕌"
    },
    {
        id: 37,
        type: "dyk",
        factoid: "The Khoikhoi people of Southern Africa have a unique clicking language.",
        emoji: "🗣️"
    },
    {
        id: 38,
        type: "dyk",
        factoid: "The Swahili language, spoken in East Africa, has a rich Arabic influence.",
        emoji: "🗣️"
    },
    {
        id: 39,
        type: "dyk",
        factoid: "The Kalahari Desert in Southern Africa is home to the San people, also known as Bushmen.",
        emoji: "🏜️"
    },
    {
        id: 40,
        type: "dyk",
        factoid: "The Kingdom of Kush, located in present-day Sudan, was a major center of trade and civilization.",
        emoji: "🏛️"
    },
    {
        id: 41,
        type: "dyk",
        factoid: "The Maasai people of East Africa are known for their vibrant traditional clothing and distinctive jumping dance.",
        emoji: "👗"
    },
    {
        id: 42,
        type: "dyk",
        factoid: "The Great Zimbabwe civilization thrived as a trading and political hub in Southern Africa.",
        emoji: "🗿"
    },
    {
        id: 43,
        type: "dyk",
        factoid: "The Tuareg people of the Sahara Desert are renowned for their distinctive indigo-colored veils and nomadic lifestyle.",
        emoji: "👳"
    },
    {
        id: 44,
        type: "dyk",
        factoid: "The Battle of Isandlwana in 1879 was a significant conflict between the British Empire and the Zulu Kingdom.",
        emoji: "⚔️"
    },
    {
        id: 45,
        type: "dyk",
        factoid: "The Nok civilization, located in what is now Nigeria, produced intricate terracotta sculptures around 500 BCE.",
        emoji: "🏺"
    },
    {
        id: 46,
        type: "dyk",
        factoid: "The Berber people of North Africa have a rich cultural heritage and are known for their traditional music and art.",
        emoji: "🎶"
    },
    {
        id: 47,
        type: "dyk",
        factoid: "The Kingdom of Axum in ancient Ethiopia was a major power in the Red Sea region.",
        emoji: "🏛️"
    },
    {
        id: 48,
        type: "proverb",
        factoid: "Knowledge without wisdom is like water in the sand.",
        emoji: "🏜️"
    },
    {
        id: 49,
        type: "proverb",
        factoid: "Ingratitude is sooner or later fatal to its author.",
        emoji: "💔"
    },
    {
        id: 50,
        type: "proverb",
        factoid: "The laughter of a child lights up the house.",
        emoji: "🏠😄"
    },
    {
        id: 51,
        type: "proverb",
        factoid: "Even an ant can hurt an elephant.",
        emoji: "🐜🐘💔"
    },
    {
        id: 52,
        type: "proverb",
        factoid: "Examine what is said, not him who speaks.",
        emoji: "🗣️"
    },
    {
        id: 53,
        type: "proverb",
        factoid: "One camel does not make fun of the other camel’s hump.",
        emoji: "🐪"
    },
    {
        id: 54,
        type: "proverb",
        factoid: "If you educate a man you educate one individual, but if you educate a woman you educate a family.",
        emoji: "👩‍🎓👨‍🎓👨‍👩‍👧‍👦"
    },
    {
        id: 55,
        type: "proverb",
        factoid: "The sun never sets without fresh news.",
        emoji: "☀️📰"
    },
    {
        id: 56,
        type: "proverb",
        factoid: "The good mother knows what her children will eat.",
        emoji: "👩‍🍳🍽️"
    },
    {
        id: 57,
        type: "proverb",
        factoid: "Even as the archer loves the arrow that flies, so too he loves the bow that remains constant in his hands.",
        emoji: "🏹❤️"
    },
    {
        id: 58,
        type: "proverb",
        factoid: "Rich people cook their food in a potsherd.",
        emoji: "🍲💰"
    },
    {
        id: 59,
        type: "proverb",
        factoid: "Rising early makes the road short.",
        emoji: "🌄🛤️"
    },
    {
        id: 60,
        type: "proverb",
        factoid: "The elephant does not limp when walking on thorns.",
        emoji: "🐘🚶‍♂️🌵"
    },
    {
        id: 62,
        type: "proverb",
        factoid: "A person with too much ambition cannot sleep in peace.",
        emoji: "😴💭❌🌟"
    },
    {
        id: 63,
        type: "proverb",
        factoid: "There is no return, worse luck for could I return, I would foresee what has come into the country.",
        emoji: "🔄🌍❌🔮"
    },
    {
        id: 64,
        type: "proverb",
        factoid: "Around a flowering tree there are many insects.",
        emoji: "🌳🐝🐜"
    },
    {
        id: 65,
        type: "proverb",
        factoid: "He is like a drum which makes a lot of noise but is hollow inside.",
        emoji: "🥁🔊🔇"
    },
    {
        id: 66,
        type: "proverb",
        factoid: "Coffee and love taste best when hot.",
        emoji: "☕️❤️🔥"
    },
    {
        id: 67,
        type: "proverb",
        factoid: "When the food is cooked there is no need to wait before eating it.",
        emoji: "🍲⌛🍽️"
    },
    {
        id: 68,
        type: "proverb",
        factoid: "Baboons do not go far from the place of their birth.",
        emoji: "🐒🏞️🏠"
    },
    {
        id: 69,
        type: "proverb",
        factoid: "The chameleon looks in all directions before moving",
        emoji: "🦎👀🔄"
    },
    {
        id: 70,
        type: "proverb",
        factoid: "Lonely is one.",
        emoji: "🙍‍♂️🌌"
    },
    {
        id: 71,
        type: "proverb",
        factoid: "Time destroys all things.",
        emoji: "⌛🌱💥"
    },
    {
        id: 72,
        type: "proverb",
        factoid: "Little by little the bird builds its nest.",
        emoji: "🐦🏠🧱"
    },
    {
        id: 73,
        type: "proverb",
        factoid: "Numbers can achieve anything.",
        emoji: "🔢💪✨"
    },
    {
        id: 74,
        type: "proverb",
        factoid: "A brave man dies once, a coward a thousand times.",
        emoji: "🏹💀"
    },
    {
        id: 75,
        type: "proverb",
        factoid: "Only someone else can scratch your back.",
        emoji: "🤲🙌"
    },
    {
        id: 76,
        type: "proverb",
        factoid: "It’s like removing a hyena from a pit.",
        emoji: "🐆🕳️🚫"
    },
    {
        id: 77,
        type: "proverb",
        factoid: "He who does not seize opportunity today, will be unable to seize tomorrow’s opportunity.",
        emoji: "⌛🔑👨‍💼🔜"
    },
    {
        id: 78,
        type: "proverb",
        factoid: "Misfortune of soup made of shanks and feet.",
        emoji: "🥘🦵💔"
    },
    {
        id: 79,
        type: "proverb",
        factoid: "Rich people sometimes eat bad food.",
        emoji: "🤑🍽️🤢"
    },
    {
        id: 80,
        type: "proverb",
        factoid: "He who refuses to obey cannot command.",
        emoji: "🙅‍♂️⚖️❌👨‍⚖️"
    },
    {
        id: 81,
        type: "proverb",
        factoid: "The cow that bellows does so for all cows.",
        emoji: "🐮🔔🐄"
    },
    {
        id: 82,
        type: "proverb",
        factoid: "The chameleon changes color to match the earth, the earth doesn’t change colors to match the chameleon.",
        emoji: "🦎🌍🌈"
    },
    {
        id: 83,
        type: "proverb",
        factoid: "If an arrow has not entered deeply, then its removal is not hard.",
        emoji: "🎯🔄❌🔪"
    },
    {
        id: 84,
        type: "proverb",
        factoid: "Where error gets to, correction cannot reach.",
        emoji: "❌🚫✅"
    },
    {
        id: 85,
        type: "proverb",
        factoid: "We do not inherit the earth from our ancestors; we borrow it from our children.",
        emoji: "🌍🏞️👨‍👧‍👦"
    },
    {
        id: 86,
        type: "proverb",
        factoid: "If you damage the character of another, you damage your own.",
        emoji: "💔⚠️👥"
    },
    {
        id: 87,
        type: "proverb",
        factoid: "He who doesn’t clean his mouth before breakfast always complains that the food is sour.",
        emoji: "🍽️🤢🗣️"
    },
    {
        id: 88,
        type: "proverb",
        factoid: "You cannot climb to the mountain top without crushing some weeds with your feet.",
        emoji: "🏔️🚶‍♀️🌿🦶"
    },
    {
        id: 89,
        type: "proverb",
        factoid: "Even the best dancer on the stage must retire sometime.",
        emoji: "💃🎭⌛"
    },
    {
        id: 90,
        type: "proverb",
        factoid: "If you have no teeth, do not break the clay cooking pot.",
        emoji: "😬🚫🍲"
    },
    {
        id: 91,
        type: "proverb",
        factoid: "When the moon is not full, the stars shine more brightly.",
        emoji: "🌕🌟✨"
    },
    {
        id: 92,
        type: "proverb",
        factoid: "Leadership comes from God.",
        emoji: "👑🙏"
    },
    {
        id: 93,
        type: "proverb",
        factoid: "The friends of our friends are our friends.",
        emoji: "🤝👥"
    },
    {
        id: 94,
        type: "proverb",
        factoid: "The elders of the village are the boundaries.",
        emoji: "🧓🌳🌳"
    },
    {
        id: 95,
        type: "proverb",
        factoid: "Those who are born on top of the anthill take a short time to grow tall.",
        emoji: "🐜🌱📏"
    }
];
