import React, {useContext} from "react";

import { DYKData } from "../../zombiedata/didyouknow.js";
import BeatLoader  from "react-spinners/BeatLoader";
import { AncestorLoaderContext } from "../../helpers/context3.js";


function AncestorsLoader() {
    const randomIndex = Math.floor(Math.random() * DYKData.length);
    const randomObject = DYKData[randomIndex];
    const {ancestorLoading} = useContext(AncestorLoaderContext);

    return (

        <div className="flex flex-col justify-center items-center h-screen bg-white m-auto">
                <span className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center">🤲🏾</span>
            <h1 className="sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-headers font-semibold text-center uppercase text-green">Consulting the Ancestors</h1>
                <BeatLoader color={'#848717'} loading={ancestorLoading} size={10} />
            <div className="flex flex-col justify-center itmes-center mt-4 mx-4">
                <span className="text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center">{randomObject.emoji}</span>
            <h1 className="sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-headers font-semibold text-center">{randomObject.type === "dyk" ? `Did you know?` : `African Proverb`}</h1> 
                <p className="sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-body font-light text-center">{randomObject.factoid}</p>
            </div>
        </div>

    )

}


export default AncestorsLoader

