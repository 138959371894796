
import {useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {LearningMaterialsContext} from "../../../helpers/contexts.js";
import { LMContext } from "../../../helpers/context3"
import {SkillsData} from "../../../zombiedata/skills.js"

import { doc, updateDoc, getDoc, collection } from "firebase/firestore";
import {getAuth} from "firebase/auth";
import { db } from "../../../firebase/firebase.utils.js";

function Skills({unitId}) {
  const auth = getAuth();
  const user = auth.currentUser;
  const [enrollment, setEnrollment] = useState({});


const selectLearningMaterialText = () => {
    setLearningMaterialState("dialogue");
  };

  const {setLearningMaterialState} = useContext(LearningMaterialsContext);
  const {setLearningMaterialId, learningMaterialId} = useContext(LMContext);
  const currEnrolRef = collection(db, "users");

  useEffect(() => {
    const getCurrUser = async () => {
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          setEnrollment({ ...docSnap.data(), id: docSnap.id });
        } else {
          console.log("No such document!");
        }
      }
    };
    getCurrUser();
  }, [user]);
  

  return (
    <div className="flex flex-row flex-wrap justify-center items-center">
        {SkillsData.filter(skill => skill.unitId === unitId).map((item, index) => {
            const handleUpdate = async () => {
              const currentEnrollmentRef = doc(db, "users", `${auth.currentUser.uid}`);
              try {
                await selectLearningMaterialText();
                await setLearningMaterialId(item.id);
                
                if (learningMaterialId > enrollment.currentEnroll.id) {
                  await updateDoc(currentEnrollmentRef, {
                    currentEnroll: {
                      id: item.id,
                      title: item.name
                    }
                  });
                }
              } catch (err) {
                console.log(err);
              }
            };
            return (
              <div id={unitId} key={item.id} className="flex flex-col flex-wrap justify-center items-center mt-8 p-6 m-6">
                {enrollment && enrollment.currentEnroll && enrollment.currentEnroll.id && item.id <= enrollment.currentEnroll.id ? (
                  <Link to="/sandbox" onClick={handleUpdate}>
                    <button className="m-auto bg-white border-4 rounded border-green px-1 py-1 drop-shadow cursor-pointer transform rotate-45">
                      <h1 className="text-2xl px-4 py-3 font-bold rounded bg-green hover:bg-white hover:text-2xl transform -rotate-22">
                        <div className="transform -rotate-45">{item.emoji}</div>
                      </h1>
                    </button>
                  </Link>
                ) : (
                  <button className="m-auto bg-white border-4 rounded border-gray px-1 py-1 drop-shadow cursor-not-allowed transform rotate-45" disabled>
                    <h1 className="text-2xl px-4 py-3 font-bold rounded bg-gray hover:text-2xl grayscale-100 transform -rotate-22">
                      <div className="transform -rotate-45">{item.emoji}</div>
                    </h1>
                  </button>
                )}
                <div className={"mt-4 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-black text-center"}>{item.name}</div>
              </div>
            )
        })}
    </div>
  )
}

export default Skills