import React, { useState, useEffect } from "react";
import {motion} from "framer-motion";
import { doc, updateDoc } from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {db} from "../../firebase/firebase.utils.js";
import BeatLoader  from "react-spinners/BeatLoader";
import PuffLoader  from "react-spinners/PuffLoader";



function OnboardingStepTwo({onNext}) {
  const auth = getAuth();
  const [onboardingOption, setOnboardingOption] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const [transitionLoading, setTransitionLoading] = useState(false);

  useEffect(() => {
    setTransitionLoading(true)
    setTimeout(() => {
      setTransitionLoading(false)
    }, 1500)
  }, [])


const handleUpdate = async () => {
  const onboardingRef = doc(db, 'users', `${auth.currentUser.uid}`)
  try {
    await updateDoc(onboardingRef, {
      language: onboardingOption
    })
  } catch (err) {
    console.log(err)
  }
}

const handleContinue = () => {
  setLoading(true); // Set loading state to true

  // Simulate 2-second delay
  setTimeout(async () => {
    await handleUpdate();
    await onNext();
    setLoading(false); // Reset loading state
  }, 500);
};

const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    handleContinue();
  }
};

return (
<div className="flex flex-col h-full bg-white">

{

transitionLoading ? 
    <div className="flex flex-col justify-center items-center h-full min-h-[500px]">
      <PuffLoader color={'#848717'} loading={transitionLoading} size={50} /> 
    </div> :

  <motion.div     initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0 }}
  transition={{ delay: 0.2 }} className="min-h-[700px]">
  <div className="flex flex-col justify-center items-center flex-grow h-full min-h-[500px]">
  <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl">🤓📚👩🏽‍🏫</span>
    <h1 className="text-center font-headers font-semibold bg-white text-black text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mt-4">
      I want to know...
    </h1>
    <div className="flex flex-row flex-wrap justify-center items-center">
      {/* Image Block 1 */}
      <div className="flex flex-row flex-wrap text-center items-center justify-center">
        <div className="block rounded m-2 p-2 flex flex-col flex-wrap text-center items-center justify-center">
          <button
            type="button"
            onClick={() => setOnboardingOption("isiZulu")}
            onKeyDown={handleKeyDown}
            alt="flag"
            className="rounded mt-4 text-center w-32 md:w-64 lg:w-82 m-2 cursor-pointer border-transparent shadow-lg border-2 hover:border-black hover:border-2 focus:border-green focus:border-2"
          >
            <div className="rounded w-full text-7xl">🇿🇦</div>
          </button>
          <span className="capitalize font-body text-black text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-bold">
            isiZulu
          </span>
        </div>
      </div>
      
      {/* Image Block 2 */}
      <div className="flex flex-row flex-wrap text-center items-center justify-center">
        <div className="block rounded m-2 p-2 flex flex-col flex-wrap text-center items-center justify-center">
          <button
            type="button"
            onClick={() => setOnboardingOption("Chichewa")}
            onKeyDown={handleKeyDown}
            alt="flag"
            className="rounded mt-4 text-center w-32 md:w-64 lg:w-82 m-2 border-gray border-2 focus:border-gray focus:border-2 cursor-not-allowed"
            disabled
          >
            <div className="grayscale-100 rounded w-full text-7xl">🇲🇼</div>
          </button>
          <span className="capitalize font-body text-black text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-body font-bold">
            Chichewa
          </span>
        </div>
      </div>
      
      {/* Image Block 3 */}
      <div className="flex flex-row flex-wrap text-center items-center justify-center">
        <div className="block rounded m-2 p-2 flex flex-col flex-wrap text-center items-center justify-center">
          <button
            type="button"
            onClick={() => setOnboardingOption("Kiswahilli")}
            onKeyDown={handleKeyDown}
            alt="flag"
            className="rounded mt-4 text-center w-32 md:w-64 lg:w-82 m-2 border-gray border-2 focus:border-gray focus:border-2 cursor-not-allowed"
            disabled
          >
            <div className="grayscale-100 rounded w-full text-7xl">🇹🇿</div>
          </button>
          <span className="capitalize font-body text-black text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-body font-bold">
            Kiswahilli
          </span>
        </div>
      </div>
    </div>
  </div>

  {/* Bottom Portion */}
  <div className="border-t-2 border-darkgray min-h-[90px] w-full flex-grow-0">
    <div className="hidden flex flex-row justify-between w-2/3 m-auto">
      <button
        className={!onboardingOption? "mt-4 capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-gray font-bold hover:text-gray border border-darkgray rounded" : "mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-green font-bold hover:text-white border border-green hover:border-green hover:bg-green rounded"}
        disabled={!onboardingOption}
        onClick={() => { onNext() }}
      >
        Skip
      </button>
    </div>
    <div className="flex flex-row justify-between mx-auto w-2/3">
    <button
      className={!onboardingOption || loading ? "w-36 h-12 mt-4 capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block font-body text-center text-gray font-bold hover:text-gray border border-darkgray rounded flex items-center justify-center" : "w-36 h-12 mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block font-body text-center text-green font-bold hover:text-white border border-green hover:border-green hover:bg-green rounded flex items-center justify-center"}
      disabled={!onboardingOption || loading} // Disable if loading
      onClick={handleContinue} // Call the handleContinue function
    >
      {loading ? <BeatLoader color={'#848717'} loading={loading} size={6} /> : 'Continue'} {/* Show loading text if loading */}
    </button>
    </div>
  </div>
  </motion.div>
}
</div>
)
}

export default OnboardingStepTwo;
