import React, { useState, useEffect, useContext } from "react";
import { db } from "../../firebase/firebase.utils.js";
import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { InputContext } from "../../helpers/context3.js";
import BeatLoader from "react-spinners/BeatLoader";
import {motion} from "framer-motion";
import DictionaryFeedbackModal from "../modals/dictionary-feedback-modal.component.jsx";

function Results() {
  const { inputValue} = useContext(InputContext);
  const [searches, setSearches] = useState([]);
  const searchesRef = collection(db, "searches");
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    let isMounted = true; // To prevent state update after unmount
    const fetchSearchResults = async () => {
      if (isMounted) setLoading(true);
      
      try {
        if (inputValue) {
          const q = query(searchesRef, where("entry", "==", inputValue));
          const q2 = query(searchesRef, where("translation", "==", inputValue));
          const querySnapshot = await getDocs(q);
          const querySnapshot2 = await getDocs(q2);
  
          let docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
          querySnapshot2.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
  
          // Remove duplicates
          docs = [...new Set(docs.map(JSON.stringify))].map(JSON.parse);
  
          if (docs.length > 0 && isMounted) {
            setSearches(docs);
          }
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };
  
    if (inputValue && isMounted) {
      fetchSearchResults();
    }
  
    return () => { isMounted = false }; // Cleanup function
  
  }, [inputValue]); // Removed searchesRef to see if it stops the infinite loop
  
  

  const dictFilter = searches.filter((mean) => {
    return inputValue === mean.entry || inputValue === mean.translation;
  });
  
  return (

    <>
    { !inputValue && !loading ?

        <motion.div className="container mx-auto p-4 max-w-4xl min-h-3/5 w-2/3" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
          <div className="text-center">
            <h1 className="text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-semibold font-headers mt-4">
              Discover new words with our African dictionary!
            </h1>
            <p className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-normal font-body mt-4">
              Our dictionary is the perfect resource for anyone who wants to learn new words in African languages. We currently offer definitions and translations for isiZulu, but we are constantly expanding our database with new vocabulary.
            </p>
            <p className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-normal font-body mt-4">
              If you would like to help us by suggesting a new word in any African language, {<DictionaryFeedbackModal/>}
            </p>
            <p className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-normal font-body mt-4">
              We appreciate your help in making our dictionary the most comprehensive resource for African languages available.
            </p>
          </div>
        </motion.div> : null
    }

    { loading ? (<div className="container mx-auto p-4 mt-16 text-center"><BeatLoader className="" color={'#991616'} loading={loading} size={15} /></div>) : null }

    {
      inputValue && !loading && dictFilter.length > 0 ? (

        <motion.div className="container mx-auto p-4 mt-4 max-w-4xl min-h-2/5 w-2/3" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
          {dictFilter.map((mean) => (
            <div
              className="max-w-4xl dropshadow-2xl rounded hover:dropshadow-xl"
              key={mean.index}
            >
              <div className="">
                <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl mt-4 font-headers font-semibold">
                  {mean.entry}
                  <span className="text-red text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl p-2">
                    {mean.partofspeech}
                  </span>
                </h1>
                <h2 className="hidden mt-4">Save Word</h2>
                <span className="text-red text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
                  {mean.pronunciation}
                </span>
                <h3 className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-4 font-headers font-semibold">
                  Translations for{" "}
                  <span className="text-red">{mean.entry}</span>
                </h3>
                <ol type="1" className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">
                  <li>{mean.translation}</li>
                </ol>
                <h3 className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-4 font-headers font-semibold">
                  Definition of <span className="text-red">{mean.entry}</span>
                </h3>
                  <span className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl mt-2">{mean.definition}</span>
                <h3 className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-4 font-headers font-semibold">
                  Examples of <span className="text-red">{mean.entry}</span> in a sentence
                </h3>
                <ol type="1" className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl mt-2 list-inside">
                  <li className="list-decimal">{mean.example} <em>{mean.englishexample}</em></li>
                </ol>
                <h3 className="hidden text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-4 font-headers font-semibold">
                  First Known Use of <span className="text-red">{mean.entry}</span>
                </h3>
                <h3 className="hidden text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-4 font-headers font-semibold">
                  History and Etymology for{" "}
                  <span className="text-red">{mean.entry}</span>
                </h3>
              </div>
            </div>
          ))}
        </motion.div>
      ) : null
    }
    {
      inputValue && !loading && dictFilter.length === 0 ?
        (
          <motion.div className="container mx-auto p-4 mt-4 max-w-2xl w-2/3" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
            <div className="text-center">
              <h1 className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-semibold font-body mt-4">
                Hmm, it looks like we couldn't find any results for{" "}
                <span className="text-red">{inputValue}</span>
                <br />🤔🔍🧐
              </h1>
              <h3 className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-semibold font-body mt-4">
                If you would like to submit a missing entry{" "}
                  {<DictionaryFeedbackModal/>}
              </h3>
            </div>
          </motion.div>
        ) : null
    }
  </>

  )

}

export default Results;