import {default as faeezal} from "../assets/imgs/faeezal.jpg";
import {default as thirunan} from "../assets/imgs/thirunan.jpg";
import {default as bontlem} from "../assets/imgs/bontlem.jpg";
import {default as corne} from "../assets/imgs/corne.jpg";

export const TestimonialData = [
    {
        image: faeezal,
        quotes: "Before I started learning isiZulu a lot of native speakers would act a bit distant around me because they thought that I only speak Chinese. After I started with FFA people became more open with me.",
        customer: "Faeeza L",
        nationality: "🇿🇦 🇨🇳",
        product: "isiZulu Social™",
        year: 2019
    },
    {
        image: thirunan,
        quotes: "Creating spaces for diverse people to come together and learn something new is always hard but somehow FFA created a safe, engaging and playful environment for people to interact with my artwork.",
        customer: "Thiruna N",
        nationality: "🇿🇦",
        product: "OTR Live Sessions",
        year: "Host"
    },
    {
        image: bontlem,
        quotes: "Being African means being part of something greater than ones self. Being part of passages and ages of lineage and culture. The diversity that one has to learn and grow from is beyond measure.",
        customer: "Bontle M",
        nationality: "🇿🇦 ",
        product: "#WEAREAFRICA",
        year: "Interviewee"
    },
    {
        image: corne,
        quotes: "Ever since I started taking lessons with Fast Fluency Africa my conversations with other people have been so much richer! The language coaches are friendly and they make the sessions flow so efficiently.",
        customer: "Corne N",
        nationality: "🇿🇦",
        product: "SASL Social™",
        year: 2020
    },
]