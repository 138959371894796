import React, {useContext, useState, useEffect} from "react";
import OnboardingStepOne from "../../components/onboarding/onboarding-step-1.component";
import OnboardingStepTwo from "../../components/onboarding/onboarding-step-2.component";
import OnboardingStepThree from "../../components/onboarding/onboarding-step-3.component";
import OnboardingStepFour from "../../components/onboarding/onboarding-step-4.component";
import OnboardingStepFive from "../../components/onboarding/onboarding-step-5.component";
import {motion} from "framer-motion";
import { useNavigate } from "react-router-dom";
import { AncestorLoaderContext } from "../../helpers/context3";
import AncestorsLoader from "../../components/loaders/ancestors";
import { getAuth } from "firebase/auth";

function OnboardingPage() {
  const auth = getAuth();
  const user = auth.currentUser;
  const [onboardingState, setOnboardingState] = useState("one");
  const navigate = useNavigate();
  const {ancestorLoading, setAncestorLoading} = useContext(AncestorLoaderContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'Onboarding | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    if (onboardingState === "one") {
      setLoading(true); // Show the loader initially

      setTimeout(() => {
        setLoading(false); // Hide the loader after the delay
      }, 3000);
    }
  }, [onboardingState, setAncestorLoading]); // Trigger this effect when the component mounts and when onboardingState changes to "one"

  const moveToNextStep = (nextState) => {
    if (onboardingState === "one") {
      setAncestorLoading(true); // Show the loader initially for step 1
    }

    setTimeout(() => {
      if (nextState === "two") setAncestorLoading(false); // Hide the loader after moving to step 2
      setOnboardingState(nextState);
    }, 750);
  };

  const handleNextStep = () => {
    // Define your state machine with step transitions here
    const stateMachine = {
      one: "two",
      two: "three",
      three: "four",
      completed: "completed",
      // Add more steps and transitions as needed
    };

    moveToNextStep(stateMachine[onboardingState] || "completed");
  };

  useEffect(() => {
    if (onboardingState === "completed") {
      navigate("/dashboard");
    }
  }, [onboardingState, navigate]);

  // Function to handle the rendering logic
  const renderStep = () => {
    switch (onboardingState) {
      case "one":
        return loading ? <AncestorsLoader/> : <OnboardingStepOne onNext={handleNextStep} />;
      case "two":
        return <OnboardingStepTwo onNext={handleNextStep} />;
      case "three":
        return <OnboardingStepThree onNext={handleNextStep} />;
      case "four":
        return <OnboardingStepFour onNext={handleNextStep} />;
      case "completed":
        return JSON?.parse(user?.reloadUserInfo?.customAttributes)?.membership === "guest" ? (
          ancestorLoading ? <AncestorsLoader/> : <OnboardingStepFive onNext={handleNextStep} />
        ) : null; // Return null for this case to avoid rendering anything when navigation happens
      default:
        return null;
    }
  };

  return (
    <motion.div className="h-screen" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.2 }}>
      {renderStep()}
    </motion.div>
  );
}

export default OnboardingPage;
