
export const PricingData = [
    // {
    //     name: "George",
    //     description: "Get started with George plan",
    //     color: "Modules",
    //     features: ['Limited Texts', ' Limited Exercises', 'Limited Vocabulary', 'Limited Teardowns', 'Limited Grammar Guide(s)', 'Limited Dictionary Searches'],
    //     btnText: "Start your free plan",
    //     currency: "R",
    //     price: "0",
    //     frequency: "/month",
    //     path: "https://bit.ly/ffabetasurvey"
    // },
    {
        name: "Mabena",
        description: "Get started with Mabena plan",
        color: "Modules",
        features: ['Unlimted Texts', 'Unlimited Exercises', 'Unlimited Vocabulary', 'Unlimited Teardowns', 'Unlimited Grammar Guide(s)', 'Unlimited Dictionary Searches'],
        btnText: "Start your free plan",
        currency: "R",
        price: "247",
        frequency: "/month",
        path: "https://bit.ly/ffabetaresearch"
    },
]