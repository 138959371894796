import React from "react";

import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import {default as skyline} from "../../assets/imgs/skyline-africa.svg";
import {default as hero} from "../../assets/imgs/hero-2-min-beta.png";
import { useState } from "react";
import { UserAuth } from "../../helpers/UserAuthContextProvider";
import BeatLoader  from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";


function Hero() {

    const [hovered, setHovered] = useState(false); // Add hovered state
    const [guestLoading, setGuestLoading] = useState(false); // Add guestLoading state
    const [userLoading, setUserLoading] = useState(false); // Add guestLoading state
    const { guestLogin } = UserAuth();
    const [error, setError] = useState("");
    const navigate = useNavigate();


    const handleGuestLogin = async () => {
      try {
        await setGuestLoading(true);
        await guestLogin();
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        await setGuestLoading(false);
      }
    };

    const userSignIn = async () => {
        try {
            await setUserLoading(true);
            navigate("/login");
        } catch (err) {
            console.log(err);
            setError(err.message)
        } finally {
            await setUserLoading(false);
        }
    }

    return (
    <div>
        <div className="flex-col md:flex md:flex-row bg-gradient-to-b from-red to-gold">
            <div className="flex flex-col items-center justify-between">
                <div className=""></div>
                <div className="flex flex-col justify-center items-center relative">
                    <div className="w-full">
                        <NavBarPrimary />
                    </div>
                    <div className="flex justify-center items-center flex-col w-5/12 mt-10">
                        <img src={hero} alt="" className="w-full ms:w-2/3" />
                    </div>
                    <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                        <div className="justify-center items-center flex flex-col w-full">
                            <div className="text-white font-bold">
                            <h1 className="mx-4 text-lg sm:text-xl md:text-2xl font-semibold font-body text-center">The fun way to know African languages, cultures, and stories</h1>
                            <div className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-center font-semibold">
                            </div>
                                <div className="flex flex-col sm:flex-row w-full justify-center items-center">
                                    <div className="flex flex-col justify-center items-center min-w-xl sm:w-full">
                                        <button
                                            onMouseEnter={() => setHovered(true)}
                                            onMouseLeave={() => setHovered(false)}
                                            onClick={handleGuestLogin}
                                            className="mt-2 mx-1 px-6 py-2 min-w-[300px] border-1 rounded text-center cursor-pointer
                                            m-auto
                                            text-white font-bold hover:text-white border-2 hover:border-green border-white bg-green
                                            text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl
                                            leading-tight
                                            uppercase
                                            shadow-md
                                            hover:shadow-2xl
                                            focus:shadow-2xl focus:outline-none focus:ring-0
                                            active:shadow-2xl
                                            transition
                                            duration-150
                                            ease-in-out"
                                        >
                                            {guestLoading ? 
                                                <BeatLoader color={hovered ? '#FCFCEE' : '#FCFCEE'} loading={guestLoading} size={5} />
                                                : 
                                                "Get Started"}
                                        </button>
                                    </div>
                                    <div className="flex flex-col justify-center items-center min-w-xl sm:w-full">
                                        <button
                                            onMouseEnter={() => setHovered(true)}
                                            onMouseLeave={() => setHovered(false)}
                                            onClick={userSignIn}
                                            className="mt-2 mx-1 px-6 py-2 min-w-[300px] border-1 rounded text-center cursor-pointer
                                            m-auto
                                            text-white font-bold hover:text-black  border-2 hover:border-black border-white 
                                            text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl
                                            leading-tight
                                            uppercase
                                            shadow-md
                                            hover:shadow-2xl
                                            focus:shadow-2xl focus:outline-none focus:ring-0
                                            active:shadow-2xl
                                            transition
                                            duration-150
                                            ease-in-out"
                                        >
                                            {userLoading ? 
                                                <BeatLoader color={hovered ? '#FCFCEE' : '#000000'} loading={userLoading} size={5} />
                                                : 
                                                "Sign In"}
                                        </button>
                                    </div>
                                </div>

                            {/* <br /> */}
                            </div>
                            <br />
                        </div>
                    </div>
                    <img className=" w-full text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl" src={skyline} alt="" />
                </div>
            </div>
        </div>
    </div>
    )
}
export default Hero