import React, {useEffect} from "react";

import PricingTable from "../../components/cards/pricing-table.component"
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Footer from "../../components/footer/footer.component";

import {motion} from "framer-motion";


function Pricing() {

    useEffect(() => {
        document.title = 'Pricing | Fast Fluency Africa';
      }, []);

    return(
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
            <div className="bg-red">
                <NavBarPrimary />
            </div>
            <div className="flex flex-row justify-start items-center p-16 w-screen bg-gradient-to-b from-red to-black">
                <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                        <br />
                        <div className="flex flex-row justify-start items-center w-full">
                            <h1 className="text-white font-headers sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl uppercase font-bold">Pricing</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <PricingTable/>
            </div>
            <div className="bg-gradient-to-b from-white to-gold mt-16 w-full">
                <Footer/>
            </div>
        </motion.div>
    )
}

export default Pricing