import React from "react";

function ContactUsCard() {
    return (
        <div className="flex flex-col justify-center items-center mt-4 bg-white">
                <div className="flex flex-col justify-center items-center w-2/3">
                    <h1 className="text-left w-full font-bold font-headers text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">Contact Us</h1>
                    <div className="bg-white w-full">
                        <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Need help with your account? Get help fast via your favorite channel within 24hrs.</p>
                        <div className="mt-4">
                            <a href="https://wa.me/27836663487?text=Hello,%20I%20need%20help%20with" target="_blank" rel="noreferrer" className="flex flex-row justify-between items-center w-full pr-2 inline rounded-md box-border bg-white focus:outline-none">
                                <span className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
                                    <div>📞</div>
                                </span>
                                <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl p-2 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white">Start a <b className="underline">WhatsApp</b> conversation</p>
                            </a>  
                        </div>
                        <div className="mt-2">
                            <a href="mailto:support@fastfluencyafrica.com" target="_blank" rel="noreferrer" className="flex flex-row justify-between items-center mt-2 w-full">
                            <span className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">
                                <div>📧</div>
                            </span>
                            <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-1 p-2 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white text-underline">Email us at: <b className="underline">support@fastfluencyafrica.com</b></p>
                            </a>                        
                        </div>
                    </div>
                    <h1 className="text-left mt-4 w-full font-bold font-headers text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">FFAQs</h1>
                    <div className="bg-white w-full">
                        <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Check out our most frequently asked questions to find solutions and get the information you need.</p>
                        <div className="mt-4">
                            <div className="flex flex-col justify-between items-center w-full">
                                <p className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white font-semibold">The language I want to learn isn't available yet - what can I do?</p>
                                <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white">If your target language is currently unavailable you can always notify us so that we can gauge the existing demand for the language and if there is enough interest (usually at least 25 other requests) we will prepare a beta program just for you, with limited resources primarily centred around coaching sessions, for you to start learning the language you want as soon as you can. </p>
                            </div>                     
                            <div className="flex flex-col justify-between items-center w-full">
                                <p className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white font-semibold">Why is the Africa continent upside down in your logo?</p>
                                <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white">Is it? 🤯 </p>
                            </div>                     
                            <div className="flex flex-col justify-between items-center w-full">
                                <p className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white font-semibold">What do you mean by "edutainment?"</p>
                                <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white">This is a portmanteau of education and entertainment. Our interpretation means creating educational experiences which have entertainment value. This means that even in our purely pedagogical products we aspire to make the content as entertaining as possible so that even if you aren't interested in learning you'll still show up just for the entertainment value.</p>
                            </div>     
                            <div className="flex flex-col justify-between items-center w-full">
                                <p className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white font-semibold">For more information and answers</p>
                                <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl mt-1 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border bg-white">Visit our official <a href="/ffaqs" target="_blank"><span className="font-semibold underline text-red">FFAQs page</span></a></p>
                            </div>                
                        </div>
                        <div className="mt-4">
                            <a href="/privacy" target="_blank" className="flex flex-row justify-between items-center mt-2 w-full">
                            <span className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">
                                <div>🔒</div>
                            </span>
                            <p className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mt-1 p-2 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border font-semibold bg-white">Privacy</p>
                            </a>                        
                        </div>
                        <div className="mt-4">
                            <a href="/terms" target="_blank" className="flex flex-row justify-between items-center w-full pr-2 inline rounded-md box-border bg-white focus:outline-none">
                                <span className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">
                                    <div>📃</div>
                                </span>
                                <p className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mt-1 p-2 rounded-sm w-full focus:outline-none pt-2 pr-2 inline rounded-md box-border font-semibold bg-white">Terms & Conditions</p>
                            </a>  
                        </div>
                    </div>
                </div>
            <div className="flex flex-col flex-wrap justify-center items-center mt-4">
                
            </div>
        </div>
    )
}

export default ContactUsCard