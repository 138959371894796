import React from "react";

import PlanCards from "./plan-cards.component";

function PricingTable() {
    return(
        <div className="flex flex-col items-center bg-otherwhite p-4">
            <div className="mb-2 mt-12 text-center">
                <PlanCards />
            </div>
        </div>
    )
}

export default PricingTable