import React, {useState, useContext, useEffect} from "react";
import {db} from "../../../firebase/firebase.utils.js";
import {collection, getDocs, updateDoc, doc, onSnapshot} from "firebase/firestore";
import {LMContext, optionChosenContext, feedbackContext} from "../../../helpers/context3.js";
import * as Io5Icons from "react-icons/io5";
import ProgressBar from "../../progress-bar/progress-bar.component";
import { ModalContext } from "../../../helpers/modalcontext";
import BeatLoader from "react-spinners/BeatLoader";

import Dialogue from "../dialogues/dialogue-generic.component.jsx";
import FillInTheBlank from "./exercise-fill-in-the-blank.component.jsx";
import FillInTheBlankB from "./exercise-fill-in-the-blank.component-b.jsx";
import FillInTheBlankC from "./exercise-fill-in-the-blank.component-c.jsx";
import MultipleChoiceQuestions from "./exercise-mcq.component.jsx";
import MultipleChoiceQuestionsB from "./exercise-mcq.component-b.jsx";
import MultipleChoiceQuestionsC from "./exercise-mcq.component-c.jsx";
import TrueOrFalse from "./exercise-true-false.component.jsx";
import TrueOrFalseB from "./exercise-true-false.component-b.jsx";
import ExerciseSummary from "./exercise-completion.component.jsx";
import Matching from "./exercise-matching.component.jsx";
import MatchingB from "./exercise-matching.component-b.jsx";


import ExitModal from "../../modals/caution-exit-modal.component";
import {ScoreContext} from "../../../helpers/contexts.js";
import {LearningMaterialsContext} from "../../../helpers/contexts.js";
import {motion} from "framer-motion";

import FeedbackModal from "../../modals/feedback-modal.component.jsx";
import AnswerFeedbackModal from "../../modals/answer-feedback-modal.component.jsx";
import Joyride, { STATUS } from 'react-joyride';
import { getAuth } from "firebase/auth";

const Quiz = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const {feedback, setFeedback} = useContext(feedbackContext);
  const [loading, setLoading] = useState(false); // Added loading state
  const {score, setScore} = useContext(ScoreContext);
  const [optionChosen, setOptionChosen] = useState(null);
  const {modal, setModal} = useContext(ModalContext);
  const [hidden, setHidden] = useState(false);
  const {learningMaterialId} = useContext(LMContext);
  const [exercises, setExercises] = useState([]);
  const exercisesRef = collection(db, "exercises");
  const [hovered, setHovered] = useState(false); // Add hovered state

  const [exercisesCompleted, setExercisesCompleted] = useState(0);
  const [learningMaterialState, setLearningMaterialState] = useState("dialogue");
  const [learningMaterial, setLearningMaterial] = useState([]);
  const [exerciseIndex, setExerciseIndex] = useState(0);

  const ExerciseComponents = {
    "fillintheblank": FillInTheBlank,
    "fillintheblankb": FillInTheBlankB,
    "fillintheblankc": FillInTheBlankC,
    "matching": Matching,
    "matchingb": MatchingB,
    "mcq": MultipleChoiceQuestions,
    "mcqb": MultipleChoiceQuestionsB,
    "mcqc": MultipleChoiceQuestionsC,
    "trueorfalse": TrueOrFalse,
    "trueorfalseb": TrueOrFalseB,
  };

  const initialExercises = [
    {type: "fillintheblank"},
    {type: "fillintheblankb"},
    {type: "fillintheblankc"},
    {type: "matching"},
    {type: "matchingb"},
    {type: "mcq"},
    {type: "mcqb"},
    {type: "mcqc"},
    {type: "trueorfalse"},
    {type: "trueorfalseb"},
  ];

  const handleOnboardingUpdate = async () => {
    const onboardingRef = doc(db, 'users', `${auth.currentUser.uid}`)
    try {
      await updateDoc(onboardingRef, {
        dialogueOnboardingCompleted: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const [completedWalkthrough, setCompletedWalkthrough] = useState(false);

  useEffect(() => {
    const userDoc = doc(db, 'users', `${auth.currentUser.uid}`);
  
    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setCompletedWalkthrough(docSnapshot.data().dialogueOnboardingCompleted);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  const [run, setRun] = useState(null);
  const [steps, setSteps] = useState([
    {
      target: '#exit',
      content: (
        <>
          <h3><b>Exit Lesson</b></h3>
          <p>Want to leave the lesson? Click here to safely exit but be careful, as your progress won't be saved.</p>
        </>
      ),
    },
    {
      target: '#translations',
      content: (
        <>
          <h3><b>Get Translations</b></h3>
          <p>Stuck on a word? Use this feature to translate the entire Text to understand the full meaning instantly.</p>
        </>
      ),
    },
    {
      target: '#next',
      content: (
        <>
          <h3><b>Interactive Exercises</b></h3>
          <p>Ready to practice? Start your exercises here once you've reviewed the Text thoroughly. Don't worry, they're designed to reinforce what you've learned.</p>
        </>
      ),
    },
    {
      target: '#feedback',
      content: (
        <>
          <h3><b>We Value Your Feedback</b></h3>
          <p>Encounter a bug or have a suggestion? Click here to let us know. Your feedback helps us improve.</p>
        </>
      ),
    },
  ]);

const handleJoyrideCallback = (data) => {
  const { status } = data;

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setRun(false);
    handleOnboardingUpdate();
  }
};

const startTour = () => {
  setRun(true);
};

  useEffect(() => {
    const getExercises = async () => {
      const data = await getDocs(exercisesRef);
      setExercises(data.docs.map(doc => ({...doc.data(), id: doc.id})));
    };
    getExercises();
    // const newLearningMaterial = shuffleArray([...initialExercises]);
    // setLearningMaterial(newLearningMaterial);
    // setExerciseIndex(0);
    // setLearningMaterialState(newLearningMaterial[0].type);
  }, []
);

  function shuffleArray(originalArray) {
    const array = [...originalArray];
    let currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
  
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
  
    return array;
}

const checkQuestion = () => {
  let correctAnswerFound = false;
  
  exercises
    .filter(exercise => exercise.day === learningMaterialId)
    .forEach((exercise) => {
      if (exercise.answer === optionChosen) {
        correctAnswerFound = true;
      }
    });
  
  if (correctAnswerFound) {
    setFeedback("correct");
    setScore(prevScore => {
      return prevScore + 1;
    });
    setOptionChosen("disabled");
  } else {
    setFeedback("incorrect");
    setOptionChosen("disabled");
  }
};


  const skipQuestion = () => {
    setOptionChosen(null);
    setFeedback("incorrect");
    setOptionChosen("disabled");
  };

  const handleDiaNav = () => {
    if (learningMaterialState === "dialogue") {
      setExercisesCompleted(0);
      setScore(0);
      setFeedback(false);
      const newLearningMaterial = shuffleArray([...initialExercises]); // shuffle array
      setLearningMaterial(newLearningMaterial); // set learningMaterial first
      const newExerciseIndex = 0; // Use a temporary variable
      setExerciseIndex(newExerciseIndex);
      setLearningMaterialState(newLearningMaterial[newExerciseIndex].type); // Use the temporary variable
    } else if (exerciseIndex < learningMaterial.length - 1) {
      const newExerciseIndex = exerciseIndex + 1; // Use a temporary variable
      setExerciseIndex(newExerciseIndex);
      setLearningMaterialState(learningMaterial[newExerciseIndex].type); // Use the temporary variable
      setExercisesCompleted(exercisesCompleted + 1); // increment exercises completed
    } else {
      setLearningMaterialState("complete");
    }
  }


  const handleRightAnswer = () => {
    if (feedback === "correct") {
      setFeedback(false);
      setOptionChosen(false);
      if (learningMaterialState === "dialogue") {
        const newLearningMaterial = shuffleArray([...initialExercises]); // shuffle array
        setLearningMaterial(newLearningMaterial); // set learningMaterial first
        const newExerciseIndex = 0; // Use a temporary variable
        setExerciseIndex(newExerciseIndex);
        setLearningMaterialState(newLearningMaterial[newExerciseIndex].type); // Use the temporary variable
      } else if (exerciseIndex < learningMaterial.length - 1) {
        const newExerciseIndex = exerciseIndex + 1; // Use a temporary variable
        setExerciseIndex(newExerciseIndex);
        setLearningMaterialState(learningMaterial[newExerciseIndex].type); // Use the temporary variable
        setExercisesCompleted(exercisesCompleted + 1); // increment exercises completed
      } else {
        setLearningMaterialState("complete");
      }
    }
  };

  const handleRightContinue = () => {
    setLoading(true); // Set loading state to true
    // Simulate 2-second delay
    setTimeout(async () => {
      setLoading(false); // Reset loading state
      handleRightAnswer();
      setFeedback(false);
    }, 2000);
  };

  const handleWrongAnswer = () => {
    if (feedback === "incorrect") {
      if (learningMaterialState === "dialogue") {
        const newLearningMaterial = shuffleArray([...initialExercises]); // shuffle array
        setLearningMaterial(newLearningMaterial); // set learningMaterial first
        const newExerciseIndex = 0; // Use a temporary variable
        setExerciseIndex(newExerciseIndex);
        setLearningMaterialState(newLearningMaterial[newExerciseIndex].type); // Use the temporary variable
      } else if (exerciseIndex < learningMaterial.length - 1) {
        const newExerciseIndex = exerciseIndex + 1; // Use a temporary variable
        setExerciseIndex(newExerciseIndex);
        setLearningMaterialState(learningMaterial[newExerciseIndex].type); // Use the temporary variable
        setExercisesCompleted(exercisesCompleted + 1); // increment exercises completed
      } else {
        setLearningMaterialState("complete");
      }
    } 
    setFeedback(false);
    setOptionChosen(false);
  };

  const handleWrongContinue = () => {

    setLoading(true); // Set loading state to true
    // Simulate 2-second delay
    setTimeout(async () => {
      setLoading(false); // Reset loading state
      handleWrongAnswer();
      setFeedback(false);
    }, 2000);
  };

  const openModal = () => {
    setModal(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  return (
      <LearningMaterialsContext.Provider value={{learningMaterialState, setLearningMaterialState, exercisesCompleted, score, setScore}}>
        <optionChosenContext.Provider value={{optionChosen, setOptionChosen}}>
          <motion.div className={learningMaterialState === "dialogue" ? "flex flex-col items-center justify-around h-full" : "flex flex-col items-center justify-around h-screen"} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.1}}>
            <div id="section-1" className="w-full min-h-[30px]">
              <div className={modal === true ? "display h-screen" : "hidden"}>
                <ExitModal />
              </div>
              {
              <div className={modal !== true && learningMaterialState !== "dialogue" && learningMaterialState !== "complete" ? "text-2xl flex flex-row w-full justify-center items-center" : "hidden"}>
                <button className="cursor-pointer" onClick={() => {openModal()}}>
                    <Io5Icons.IoCloseSharp className="text-left text-3xl text-gray hover:text-green" />
                </button>
                <ProgressBar />
              </div>
              }
            </div>

            <div className="section-2">
              <div className={learningMaterialState === "dialogue" ? "z-10" : "hidden"}>
                {!completedWalkthrough ? <div id="onboarding-trigger" onClick={startTour} className="feedback-icon text-white flex flex-col items-center justify-center rounded w-12 h-12 fixed bottom-52 right-8 cursor-pointer hover:bg-green animate-bounce">
                  <span className='text-3xl' title='onboarding'>🚀</span>
                </div> : null}
                <FeedbackModal id="feedback"/>
              </div>
              {learningMaterialState === "dialogue" && <Dialogue onDialogueComplete={handleDiaNav} ids={["exit", "translations", "next"]}/>}
              {learningMaterialState !== "dialogue" && learningMaterialState !== "complete" &&
                React.createElement(ExerciseComponents[learningMaterial[exerciseIndex].type], { data: learningMaterial[exerciseIndex] })
              }
              {learningMaterialState === "complete" && <ExerciseSummary />}
              <Joyride
                steps={steps}
                run={run}
                continuous={true}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                callback={handleJoyrideCallback}
                styles={{
                  options: {
                    arrowColor: '#848717',
                    backgroundColor: '#FCFCEE',
                    overlayColor: 'rgba(79, 26, 0, 0.1)',
                    primaryColor: '#000',
                    textColor: '#000000',
                    width: 500,
                    zIndex: 1000,
                    fontFamily: "Nunito",
                  }
                }}
              />
            </div>

        {learningMaterialState !== "complete" && (learningMaterialState !== "dialogue" &&


            (!feedback 
              
              ?

              (<div id="answer-responses" className="border-t-2 border-darkgray min-h-[70px] w-full">
                <div className={hidden ? "hidden" : "flex flex-row justify-between w-2/3 m-auto"}>
                  <label>
                    <input
                      type="button"
                      value="SKIP"
                      className="mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-2 py-4 m-2 text-gray font-bold hover:text-gray border border-gray hover:border-darkgray hover:bg-darkgray rounded"
                      onClick={() => {skipQuestion()}}
                    ></input>
                  </label>
              {((learningMaterialState === "matching" || learningMaterialState === "matching-grammar")) ? (
                    <label>
                      <input
                        disabled={optionChosen}
                        type="button"
                        className={optionChosen ? "hidden mt-4 capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-gray font-bold hover:text-gray border border-darkgray rounded" : "hidden mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-green font-bold hover:text-white border border-green hover:border-green hover:bg-green rounded"}
                        value="CHECK"
                        onClick={() => { checkQuestion() }}
                      ></input>
                    </label>
                  ) 
                  
                  : 
                  
                  (
                    <label>
                      <input
                        disabled={!optionChosen}
                        type="button"
                        className={!optionChosen ? "mt-4 capitalize cursor-not-allowed bg-darkgray text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-gray font-bold hover:text-gray border border-darkgray rounded" : "mt-4 capitalize cursor-pointer bg-white text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-green font-bold hover:text-white border border-green hover:border-green hover:bg-green rounded"}
                        value="CHECK"
                        onClick={() => { checkQuestion() }}
                      ></input>
                    </label>
                  )}
                </div>
              </div>) 
              
              :

              feedback === "correct" 
              
              ? 
              
              (<div className="flex justify-center items-center border-t-2 border-darkgray min-h-[90px] w-full bg-green">
                <div className={hidden ? "hidden" : "flex flex-row justify-between w-4/5 m-auto"}>
                  <div className="flex flex-row justify-center items-center">
                    <div>
                      <Io5Icons.IoCheckmarkCircleSharp className="my-3 text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl text-white" />
                    </div>
                    <div>
                      <h3 className="font-headers font-bold text-white text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">Well done</h3>
                      <p className="font-body text-white text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg">That's more like it!</p>
                    </div>
                  </div>
                  <label className="flex flex-row justify-center items-center">
                    <button
                      onMouseEnter={() => setHovered(true)} // Set hovered to true on mouse enter
                      onMouseLeave={() => setHovered(false)} // Set hovered to false on mouse leave
                      disabled={feedback !== "correct"}
                      type="button"
                      className="mt-4 capitalize cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-white bg-green font-bold hover:text-green border border-white hover:border-green hover:bg-white rounded w-32 h-12"
                      onClick={() => {handleRightContinue()}}
                    >{loading ? <div className="px-8"><BeatLoader color={hovered ? '#848717' : '#FCFCEE'} loading={loading} size={6} /></div> : 'CONTINUE'} {/* Show loading text if loading */}
                    </button>
                  </label>
                </div>
              </div>) 
              
              :
              
              
              (<div className="flex justify-center items-center border-t-2 border-darkgray min-h-[90px] w-full bg-red">
                <div className={hidden ? "hidden" : "flex flex-row justify-between w-4/5 m-auto"}>
                  <div className="flex flex-row justify-center items-center">
                    <div>
                      <Io5Icons.IoCloseCircleSharp className="my-3 text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl text-white" />
                    </div>
                    <div>
                      <h3 className="font-headers font-bold text-white text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">Incorrect solution</h3>
                      <p className="font-body text-white text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg">Keep practicing until you get it right</p>
                      <AnswerFeedbackModal/>
                    </div>
                  </div>
                  <label className="flex flex-row justify-center items-center">
                    <button
                      onMouseEnter={() => setHovered(true)} // Set hovered to true on mouse enter
                      onMouseLeave={() => setHovered(false)} // Set hovered to false on mouse leave
                      disabled={!optionChosen}
                      type="button"
                      className="mt-4 capitalize cursor-pointer bg-red text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-4 py-4 m-2 text-white font-bold hover:text-red border border-white hover:border-red hover:bg-white rounded w-32 h-12"
                      value="CONTINUE"
                      onClick={() => {handleWrongContinue()}}
                    >{loading ? <div className="px-8"><BeatLoader color={hovered ? '#991616' : '#FCFCEE'} loading={loading} size={6} /></div> : 'CONTINUE'} {/* Show loading text if loading */}</button>
                  </label>
                </div>
              </div>)
            )
          )}
        </motion.div>
      </optionChosenContext.Provider>
    </LearningMaterialsContext.Provider>
    )
  }

export default Quiz
