import React from "react";
import {useContext} from "react";
import {LearningMaterialsContext} from "../../helpers/contexts";
import {motion} from "framer-motion";

function ProgressBar() {
    const { exercisesCompleted } = useContext(LearningMaterialsContext);

    const getWidth = () => {
        return `${exercisesCompleted}/9`;
    };

    return (
        <div className="bg-darkgray border-gray border-2 h-6 sticky top-0 left-0 w-10/12 rounded">
            <motion.div
                className={`h-5 bg-green w-${getWidth()} rounded-none`}
                initial={{ width: 0 }}
                animate={{ width: `calc(${getWidth()} * 100%)` }}
                exit={{ width: 0 }}
                transition={{ duration: 0.5 }}
            >
            </motion.div>
        </div>
    );
}

export default ProgressBar;
