import React from "react";

import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";

import ProfileCard from "../../components/profile/profile-card.component.jsx";

import {motion} from "framer-motion";

function ProfilePage() {
    return(
        <motion.div className="bg-white h-screen" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
            <NavigationBar />
            <div className="m-auto w-9/12">
                <ProfileCard />
            </div>
        </motion.div>
    )
}

export default ProfilePage