import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getAuth} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils";
import { BeatLoader } from 'react-spinners';


const CurrentEnrolmentsCard = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [enrollment, setEnrollment] = useState([]);


  useEffect(() => {
    const getCurrUser = async () => {
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          setEnrollment({ ...docSnap.data(), id: docSnap.id });
        } else {
          console.log("No such document!");
        }
      }
    };
    getCurrUser();
  }, [user]);


  return (
    <div className="mt-4 bg-white border border-darkgray shadow-2xl w-full h-2/6 m-auto p-8 rounded text-right">
      <span className="hidden bg-white border-white border rounded-2xl font-body text-right pl-4 pr-4 text-xs">
        {null}
      </span>
      <div>
        <h3 className="text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-center font-headers font-bold pl-4 pr-4">isiZulu Social™</h3>
        <div className="flex flex-col pl-4 pr-4 text-sm items-center justify-between">
          <span className="font-body text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">{enrollment && enrollment.currentEnroll ? enrollment.currentEnroll.title : <BeatLoader color="#000000" size={5} />}</span>
        </div>
        <div className="pl-4 pr-4 text-center">{null}</div>
          <div className="w-1/2 mt-4  border-1 rounded  text-center m-auto block pt-2 pb-2 pl-4 pr-4 text-red font-bold hover:text-white border-2 border-red hover:border-white hover:bg-red text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out cursor-pointer">
            <Link to="/content" className="flex flex-col justify-center items-center">
                Start
            </Link>
          </div>
      </div>
    </div>
  );
};

export default CurrentEnrolmentsCard;

// <span className="font-body">{null}</span>
