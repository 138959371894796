import React from "react";
import { Link } from "react-router-dom";
import africanPattern from "../../assets/imgs/african-patterns.png" // Replace with the actual image path

const RegistrationCard = () => {
  const cardStyle = {
    backgroundImage: `url(${africanPattern})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  
    return (
      <div className="mt-4 w-full m-auto p-8 rounded-lg text-right border border-white" style={cardStyle}>
        <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">🔥☄️</div>
        <div className="pt-1 pr-2 ">
          <h1 className="font-headers text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-white text-center">
            <span className="font-semibold uppercase">Unlock African Edutainment</span>
          </h1>
          <h3 className="font-regular text-sm sm:text-md md:text-lg lg:text-xl text-white text-center font-italic font-light">
            Embark on an Enriching Journey to Discover Vibrant Cultures and Rich Vocabulary. 🌱🚀
          </h3>
        </div>
        <div className="w-1/2 mt-4 border-1 rounded-xl text-center m-auto block pt-2 pb-2 pl-4 pr-4 text-white font-bold hover:text-white border-2 border-white hover:border-white hover:bg-green text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out cursor-pointer">
          <Link to="/register" className="flex flex-col justify-center items-center">
            Sign Up Now
          </Link>
        </div>
      </div>
    );
  };
  
  export default RegistrationCard;
