import React, { useState } from 'react';
import {  collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { db } from "../../firebase/firebase.utils";
import { serverTimestamp } from 'firebase/firestore';


const partsOfSpeech = ["Noun", "Pronoun", "Verb", "Adjective", "Adverb", "Preposition", "Other…"]

const DictionaryFeedbackModal = () => {
  const auth = getAuth();
  const [isOpen, setIsOpen] = useState(false);
  const timestamp = serverTimestamp();
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Additional form fields
  const [feedbackData, setFeedbackData] = useState({
    language: "",
    entry: "",
    pronunciationWritten: "",
    partOfSpeech: "",
    englishTranslations: "",
    definitions: "",
    examplesInASentence: "",
    synonyms: "",
    antonyms: "",
  });

  const handleInputChange = (event) => {
    setFeedbackData({
      ...feedbackData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const feedbackRef = collection(db, 'feedback');
      const newFeedbackData = {
        userId: `${auth.currentUser.uid}`,
        learningMaterialState: "dictionary",
        timestamp: timestamp,
        ...feedbackData
      };
      await addDoc(feedbackRef, newFeedbackData);  
      setFeedbackData({
        language: "",
        entry: "",
        pronunciationWritten: "",
        partOfSpeech: "",
        englishTranslations: "",
        definitions: "",
        examplesInASentence: "",
        synonyms: "",
        antonyms: "",
      });
      setSubmitMessage('Thank you! Your feedback has been received!');
    } catch (error) {
      console.error('Error creating feedback:', error);
      setSubmitMessage('Thank you! Your feedback has been received!');
    }
  };

  const formSubmission = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
  
    try {
      await handleSubmit(event);  // Submit the form and wait for it to complete
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  
    setTimeout(() => {
      setLoading(false);  // Stop loading
      setSubmitMessage('');
      closeModal();     // Close the modal
    }, 750);  // 2 seconds delay
  }
  

  // Render function for form fields
  return (
    <div className=''>
      <div
        className="feedback-icon rounded cursor-pointer"
        onClick={openModal}
      >
        <span className="font-body text-red hover:text-green text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl mt-4 font-semibold ">complete this form here.</span>
      </div>

      {isOpen && (

        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded p-6 max-w-md m-auto">
          <span 
            className="absolute top-4 right-4 text-black text-2xl bg-white cursor-pointer border-2 border-black rounded px-3"
            onClick={closeModal}
          >
            &times;
          </span>
          {!submitMessage ?
          <form onSubmit={(event) => formSubmission(event)} className="">
            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Relevant Language</label>
              <p className="text-black text-left text-xs font-light">Specify which language you are making a submission for.</p>
              <input type="text" name="language" onChange={handleInputChange} value={feedbackData.language} className="w-full mt-1 p-2 border border-gray-300 rounded"/>
            </div>
            {/* Repeat similar div structure for other input fields */}
            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Entry</label>
              <p className="text-black text-left text-xs font-light">Provide the written African word, proverb, experssion, or otherwise that you would like us to consider.</p>
                <input type="text" name="entry" onChange={handleInputChange} value={feedbackData.entry} className="w-full mt-1 p-2 border border-gray-300 rounded" />
            </div>
            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Pronunciation (Written)</label>
              <p className="text-black text-left text-xs font-light">Add a written pronounciation in the form that emphasizes the sounds of each syllable i.e. for "isizulu" → "ee-see-zoo-loo".</p>
              <input type="text" name="pronunciationWritten" onChange={handleInputChange} value={feedbackData.pronunciationWritten} className="w-full mt-1 p-2 border border-gray-300 rounded" />
            </div>
            
            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Part of Speech</label>
              <p className="text-black text-left text-xs font-light">Where the entry can be multiple parts of speech please select 'Other'.</p>
              <select name="partOfSpeech" onChange={handleInputChange} value={feedbackData.partOfSpeech}className="text-sm w-full mt-1 p-2 border border-gray-300 rounded" >
                {partsOfSpeech.map(part => (
                  <option key={part} value={part}>{part}</option>
                ))}
              </select>
            </div>
            
            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">English Translations</label>
              <p className="text-black text-left text-xs font-light">Provide at least two different one word English equivalent translations for the entry.</p>
                <input type="text" name="englishTranslations" onChange={handleInputChange} value={feedbackData.englishTranslations} className="w-full mt-1 p-2 border border-gray-300 rounded" />
            </div>
            
            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Definitions</label>
              <p className="text-black text-left text-xs font-light">Please give at least two short one sentence definitions in English for the entry.</p>
                <input type="text" name="definitions" onChange={handleInputChange} value={feedbackData.definitions} className="w-full mt-1 p-2 border border-gray-300 rounded" />
            </div>
            
            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Examples in a Sentence</label>
              <p className="text-black text-left text-xs font-light">Use the entry in at least two simple sentences.</p>

                <input type="text" name="examplesInASentence" onChange={handleInputChange} value={feedbackData.examplesInASentence} className="w-full mt-1 p-2 border border-gray-300 rounded" />
            </div>
            
            {/* <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Synonyms (optional)</label>
              <p className="text-black text-left text-xs font-light">This section is not compulsory.</p>
                <input type="text" name="synonyms" onChange={handleInputChange} value={feedbackData.synonyms} className="w-full mt-1 p-2 border border-gray-300 rounded" />
            </div>

            <div className="text-left font-semibold">
              <label className="text-sm text-black text-left mt-2">Antonyms (optional)</label>
              <p className="text-black text-left text-xs font-light">This section is not compulsory.</p>
                <input type="text" name="antonyms" onChange={handleInputChange} value={feedbackData.antonyms} className="w-full mt-1 p-2 border border-gray-300 rounded" />
            </div> */}
            
            <input 
              type="submit" 
              value={loading ? "Loading..." : "Submit"}
              className='disabled:text-white disabled:bg-gray disabled:cursor-not-allowed mt-2 min-w-1/3 capitalize cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl block pt-2 pb-2 pl-4 pr-4 font-body text-center px-2 py-2 m-2 font-bold disabled:border-gray text-white bg-green rounded' 
              disabled={!feedbackData.language || !feedbackData.entry /* Add similar conditions for other fields */}
            />
          </form> : <div className="message text-green-500">{submitMessage}</div>}
        </div>
      </div>
      
      )}
    </div>
  );
};

export default DictionaryFeedbackModal;
