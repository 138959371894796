import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import Footer from "../../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../../components/navigation-bar/navbar-primary.component";

import {motion} from "framer-motion";

import {default as phrases} from "../../../assets/imgs/blog/phrases.png";

import { Link } from "react-router-dom";


function GuideToCommonPhrases() {

  useEffect(() => {
    document.title = 'Guide to Common Phrases in isiZulu | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">Guide to Common Phrases in isiZulu</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container max-w-5xl mx-auto mt-16">
            <div className="ps-media mt-6">
                <div className="flex items-center justify-center flex-col">
                    <div className="ps-media-img-container">
                    <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                    </a>
                    </div>
                    <div id="about-us mt-6 p-8 flex items-center justify-center">
                    <p className="m-auto w-12/12 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl px-2 font-headers font-bold">
                    Guide to Common Phrases in isiZulu
                    </p>
                    <p className="w-12/12 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-2 font-body font-semibold my-3 text-right">Tribe Contributor | October 21st, 2022 ⏱️ 5 min</p>
                    </div>
                    <img src={phrases} alt="" width="75%" className="m-auto mt-2" />                    
                    <span className="hidden font-bold  mt-2 sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg 3xl:text-xl italic">Placeholder</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap justify-center items-center">
                <div className="mt-4">
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Learning a new language is an exciting journey, and if you've chosen to dive into isiZulu, you're opening a door to a rich and vibrant culture. isiZulu is one of South Africa's 11 official languages and is spoken by approximately 23% of the country's population. Whether you're learning isiZulu for travel, business, or personal enrichment, mastering common phrases is a great starting point. In this guide, we'll go through essential phrases to get you through everyday situations.</p>
                </div>
            </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Greetings</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Greetings are important in Zulu culture and are often a precursor to other social interactions.</p>
                        <ul className="mt-2 text-sm sm:text-sm md:text-md lg:text-lg">
                            <li>Hello: Sawubona (to one person), Sanibonani (to multiple people)</li>
                            <li>Good Morning: Sawubona</li> 
                            <li>Good Afternoon: Sawubona</li>
                            <li>Good Evening: Sawubona</li>
                            <li>Goodbye: Hamba kahle (when someone is leaving), Sala kahle (when you are leaving)</li>
                        </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Basic Polite Phrases</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Being polite is crucial in any language, and isiZulu is no exception.</p>
                        <ul className="mt-2 text-sm sm:text-sm md:text-md lg:text-lg">
                            <li>Please: Ngicela</li>
                            <li>Thank You: Ngiyabonga</li> 
                            <li>Yes: Yebo</li>
                            <li>No: Cha</li>
                            <li>Excuse Me: Uxolo</li>
                            <li>I'm Sorry: Ngiyaxolisa</li>
                        </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Questions and Responses</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Questions form the basis of many interactions, so it's good to know some basics.</p>
                        <ul className="text-sm sm:text-sm md:text-md lg:text-lg">
                            <li>What is your name?: Ngubani igama lakho?</li>
                            <li>My name is [Your Name]: Igama lami ngu [Your Name]</li>
                            <li>How are you?: Unjani?</li>
                            <li>I am fine, thank you: Ngiyaphila, ngiyabonga</li>
                            <li>Where is the bathroom?: Igumbi lokugezela likuphi?</li>
                        </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Numbers</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Numbers are important for shopping, giving directions, and many other activities.</p>
                        <ul className="text-sm sm:text-sm md:text-md lg:text-lg">
                            <li>Zero: Iqanda</li>
                            <li>One: Kunye</li>
                            <li>Two: Kubili</li>
                            <li>Three: Kuthathu</li>
                            <li>Four: Kune</li>
                            <li>Five: Kuhlanu</li>
                        </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Food and Dining</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you're in a Zulu-speaking area, you'll likely find yourself dining out or shopping for food.</p>
                        <ul className="text-sm sm:text-sm md:text-md lg:text-lg">
                            <li>I'm hungry: Ngilambile</li>
                            <li>I'm thirsty: Ngomile</li>
                            <li>Water: Amanzi</li>
                            <li>Meat: Inyama</li>
                            <li>Fruit: Isithelo</li>
                            <li>How much is it?: Kubiza malini? or Malini?</li>
                        </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Emergency Phrases</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">While we hope you won't need these, it's better to be prepared.</p>
                        <ul className="text-sm sm:text-sm md:text-md lg:text-lg">
                            <li></li>
                            <li>I am hurt: Ngilimeje</li>
                            <li>There has been an accident: Kuvele ingozi</li>
                            <li>Can you help me please? : Ngicela ungisize?</li>
                            <li>I am lost: Ngidukile</li>
                            <li>My car has broken down: Imoto yamiifile</li>
                        </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Common Verbs</h2>
                        <ul className="text-sm sm:text-sm md:text-md lg:text-lg">
                            <li>To Go: Ukuhamba</li>
                            <li>To Eat: Ukudla</li>
                            <li>To Drink: Ukuphuza</li>
                            <li>To Sleep: Ukulala</li>
                            <li>To Visit: Ukuvakasha</li>
                        </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-xl sm:text-xl font-headers font-semibold" id="section-two">Final Thoughts</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Learning isiZulu can be a challenging yet rewarding experience. While this guide is just the tip of the iceberg, mastering these phrases will give you a strong foundation for daily interactions. In Zulu culture, making an effort to speak the language is often greatly appreciated and can lead to more meaningful engagements with native speakers.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">As you continue your journey in learning isiZulu, remember that practice and consistency are key. Engage with native speakers, watch Zulu films, or join language groups online to refine your skills. The beauty of language lies in its ability to connect people, so use these phrases as a starting point to deeper, more enriching conversations.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you want to access more comprehensive phrases and words, make sure to <Link to="/login" className="text-red font-semibold underline cursor-pointer">sign in to our Edutainment Vault</Link> for <b>free</b> and make full use of our African languages dictionary!</p>
                </div>
        </div>
      </body>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
    )
}

export default GuideToCommonPhrases;