import React, {useState} from "react";
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import {UserAuth } from "../../helpers/UserAuthContextProvider"

function IngoziCard() {
    const [profileStatus, setProfileStatus] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();
    const {deleteCurrentUser} = UserAuth();

    const promptForCredentials = () => {
        const email = prompt('Please enter your email:');
        const password = prompt('Please enter your password:');
        return EmailAuthProvider.credential(email, password);
    };

    const reauthenticateGoogle = async () => {
        const auth = getAuth();
        const googleProvider = new GoogleAuthProvider();
        
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            await reauthenticateWithCredential(auth.currentUser, credential);
            // User has been successfully reauthenticated
        } catch (error) {
            // An error occurred during reauthentication
            console.error('Error during reauthentication:', error);
        }
    };

    const reauthenticateUser = async () => {
        try {
            // Prompt the user for their credentials
            const credential = promptForCredentials();

            // Reauthenticate the user with the provided credentials
            await reauthenticateWithCredential(user, credential);
        } catch (error) {
            console.error('Error during reauthentication:', error);
        }
    };

    const confirmDelete = () => {
        const firstConfirmation = window.confirm("Are you sure you want to delete your account?");
        if (!firstConfirmation) return false;

        const secondConfirmation = window.confirm("This is irreversible! Are you absolutely sure?");
        return secondConfirmation;
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form from refreshing the page
        setIsSubmitted(true);
        
        if (!user) return;
        
        if (!confirmDelete()) {
            // If user cancels the deletion, we just return without doing anything
            return;
        }
        
        try {
            await deleteCurrentUser(user);
            setProfileStatus('Your profile has successfully been deleted!');
            navigate("/login")
        } catch (error) {
            if (error.code === "auth/requires-recent-login") {
                if (user.providerData.some(data => data.providerId === "google.com")) {
                    reauthenticateGoogle();
                } else {
                    reauthenticateUser();
                }
            } console.error('Error deleting profile:', error);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center mt-4">
            <div className="flex flex-col justify-center items-center w-2/3">
                {/* <img src="https://ui-avatars.com/api/?background=random&name=Isi+Zulu&color=#000000" alt="" className="rounded-full" /> */}
                <h1 className="font-headers text-left mt-4 w-full font-bold text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">Ingozi</h1>
                    <p className="text-left w-full text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Delete your user information and permanently remove all your personal and associated account information from our servers. Please note that this is irreversible!</p>
                        <form action="" className="w-full" onSubmit={handleSubmit}>
                            <div className="mt-4 flex flex-col items-center justify-center">
                                <button type="submit"
                                value="delete profile" className="mt-4 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-4/6 pt-2 pr-2 inline rounded box-border px-6 py-2 border-1 text-center cursor-pointer w-min-xl
                                m-auto pb-2 pl-4 font-bold hover:text-white border-2 border-red bg-white hover:border-red hover:bg-red  text-red
                                leading-tight
                                uppercase
                                shadow-md
                                hover:shadow-lg
                                focus:shadow-lg focus:outline-none focus:ring-0
                                active:shadow-lg
                                transition
                                duration-150
                                ease-in-out" placeholder="Delete profile">Delete profile</button>
                                {isSubmitted ? <span className="mt-4 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-green font-semibold">{profileStatus}</span> : null}
                            </div>
                        </form>
            </div>
            <div className="flex flex-col flex-wrap justify-center items-center mt-4">
            </div>
        </div>
    )
}

export default IngoziCard