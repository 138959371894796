// InviteFriends.js
import React, { useState, useEffect } from 'react';
import generateInviteLink from './generateInviteLink.component';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { IoLogoFacebook, IoLogoWhatsapp, IoLogoTwitter } from "react-icons/io";

const InviteFriends = () => {
  const [inviteLink, setInviteLink] = useState('');
  const [copyButtonText, setCopyButtonText] = useState('Copy Link');
  const defaultShareText = "Come join me at Fast Fluency Africa, the fun way to know African languages, cultures, and stories!";


  useEffect(() => {
    const getLink = async () => {
      const link = `fastfluencyafrica.com`;
      if (link) {
        setInviteLink(`https://${link}`);
      }
    };
    
    getLink();
  }, []);

  const handleCopyClick = () => {
    setCopyButtonText('Copied!');
    setTimeout(() => {
      setCopyButtonText('Copy Link');
    }, 2000);  // Reset back to "Copy" after 2 seconds
  };

  return (
    <div className="bg-white w-full mt-4 border border-darkgray shadow-2xl h-2/6 m-auto p-8 rounded text-right">
      <div className="text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">📢🧘🏾🔊</div>
      <h3 className='font-headers font-semibold text-center text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl'>Let your friends and family know that mastering a new language with Fast Fluency Africa is fun!</h3>
      <input 
        type="text" 
        readOnly 
        value={inviteLink} 
        className="border rounded p-2 w-full mb-4 mt-4 text-ms sm:text-xs md:text-sm lg:text-md xl:text-lg outline-none"
      />
      <CopyToClipboard text={inviteLink}>
        <button onClick={handleCopyClick} className="w-full box-border mt-2 border-1 text-center w-min-2xl
        m-auto
        px-6
        py-2.5
        cursor-pointer text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green  text-black
        leading-tight
        uppercase
        shadow-md
        hover:shadow-lg
        focus:shadow-lg focus:outline-none focus:ring-0
        active:shadow-lg
        transition
        duration-150
        ease-in-out">
          {copyButtonText}
        </button>
      </CopyToClipboard>

      <div className="flex space-x-2 mt-4 flex-row">
        <h3 className='text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md text-left'>Or share via..</h3>
        <FacebookShareButton url={inviteLink} quote={defaultShareText}>
          <button className="w-full box-border mt-2 border-1 text-center w-min-xl
            m-auto
            px-6
            py-2.5
            cursor-pointer text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green  text-black
            leading-tight
            uppercase
            shadow-md
            hover:shadow-lg
            focus:shadow-lg focus:outline-none focus:ring-0
            active:shadow-lg
            transition
            duration-150
            ease-in-out">
            <IoLogoFacebook />
          </button>
        </FacebookShareButton>
        <TwitterShareButton url={inviteLink} title={defaultShareText}>
          <button className="w-full box-border mt-2 border-1 text-center w-min-xl
            m-auto
            px-6
            py-2.5
            cursor-pointer text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green  text-black
            leading-tight
            uppercase
            shadow-md
            hover:shadow-lg
            focus:shadow-lg focus:outline-none focus:ring-0
            active:shadow-lg
            transition
            duration-150
            ease-in-out">
            <IoLogoTwitter/>
          </button>
        </TwitterShareButton>
        <WhatsappShareButton url={inviteLink} title={defaultShareText}>
          <button className="w-full box-border mt-2 border-1 text-center w-min-xl
            m-auto
            px-6
            py-2.5
            cursor-pointer text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green  text-black
            leading-tight
            uppercase
            shadow-md
            hover:shadow-lg
            focus:shadow-lg focus:outline-none focus:ring-0
            active:shadow-lg
            transition
            duration-150
            ease-in-out">
            <IoLogoWhatsapp/>
          </button>
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default InviteFriends;
