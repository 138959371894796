import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import Footer from "../../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../../components/navigation-bar/navbar-primary.component";
import { Link } from "react-router-dom";

import {motion} from "framer-motion";

import {default as culture} from "../../../assets/imgs/blog/moshoeshoe-fabric-patterns-digital-art.png";



function ImportanceOfLanguageLearning() {

  useEffect(() => {
    document.title = 'Importance of Learning African Languages | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">The Importance of Learning African Languages in the Modern World</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container max-w-5xl mx-auto mt-16">
            <div className="ps-media mt-6">
                <div className="flex items-center justify-center flex-col">
                    <div className="ps-media-img-container">
                    <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                    </a>
                    </div>
                    <div id="about-us mt-6 p-8 flex items-center justify-center">
                    <p className="m-auto w-12/12 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl px-2 font-headers font-bold">
                        The Importance of Learning African Languages in the Modern World
                    </p>
                    <p className="w-12/12 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-2 font-body font-semibold my-3 text-right">Tribe Contributor | December 4th, 2023 ⏱️ 5 min</p>
                    </div>
                    <img src={culture} alt="" width="75%" className="m-auto mt-2" />                    
                    <span className="hidden font-bold  mt-2 sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg 3xl:text-xl italic">Placeholder</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap justify-center items-center">
                <div className="mt-4">
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">In an increasingly interconnected world where English often takes center stage, the value of learning other languages can sometimes be overlooked. Yet, the significance of studying African languages in particular—such as Swahili, Zulu, or Yoruba—holds untold potential for broadening our cultural horizons and fostering global solidarity. In this article, we'll explore why learning African languages should be a priority for individuals and institutions alike.</p>
                </div>
            </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-one">Enhancing Cross-Cultural Communication</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Africa is a continent of immense diversity, both culturally and linguistically. It is estimated that there are between 1250 and 2,100 languages spoken across the continent, representing a wealth of cultures, histories, and traditions. Learning an African language can open up new avenues for cross-cultural communication, breaking down barriers that often exist due to language limitations. This can be particularly beneficial for diplomats, international organizations, and businesses that operate on a global scale (Thiong'o, 1986).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">Economic Opportunities</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Africa is emerging as a global economic powerhouse, with some of the world's fastest-growing economies located on the continent. Countries in East Africa such as Kenya are becoming hubs for technology, finance, and entrepreneurship. Knowing an African language can provide a competitive edge for professionals seeking opportunities in these booming markets (AfDB, 2019).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">Academic and Research Contributions</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Many African languages have rich oral traditions and written texts that have not yet been fully explored by academia. By learning these languages, researchers can access a wealth of untapped knowledge and contribute to fields like anthropology, linguistics, history, and social sciences. This is particularly crucial for 'decolonizing' academic disciplines that have historically been Eurocentric (Mbembe, 2015).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">Preservation of Culture and Heritage</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Languages are living embodiments of culture, encapsulating history, folklore, and social norms. When languages go extinct, a part of human heritage is lost forever. Learning African languages is a way to contribute to their preservation, thereby helping to maintain the richness and diversity of the global linguistic landscape (Makoni & Meinhof, 2003).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">Empowerment and Social Justice</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The colonial legacy in Africa has often marginalized local languages in favor of European languages like English or French. This can perpetuate social inequities, as proficiency in a colonial language is often associated with higher social status and greater economic opportunities. By promoting the learning and usage of African languages, we can contribute to a more equitable society where everyone's linguistic heritage is valued (Alexander, 2003).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">Personal Growth and Cognitive Benefits</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Learning any new language has cognitive benefits, such as improved memory, multitasking skills, and even a delay in the onset of diseases like Alzheimer's (Bialystok et al., 2007). Additionally, learning a language from a culture vastly different from one's own can lead to greater cognitive flexibility and openness to new experiences (Dewaele, 2013).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">Promoting Regional Solidarity</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">In a world where divisive rhetoric often makes headlines, the act of learning another's language is an expression of respect and an invitation for mutual understanding. As the African proverb goes, "Wisdom is like a baobab tree; no one individual can embrace it." In learning African languages, we open our arms wider to embrace the expansive tree of continental wisdom.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">Conclusion</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The learning of African languages is not just an academic exercise, but a deeply enriching experience that can improve communication, boost economic prospects, and contribute to academic knowledge. Most importantly, it can play a crucial role in the preservation of culture, the promotion of social justice, and the fostering of global solidarity. As Africa continues to rise as a significant player on the world stage, the importance of understanding its languages will only grow, and the time to <Link to="/login" className="text-red font-semibold underline cursor-pointer">start learning is now.</Link></p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">References</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Thiong'o, N. (1986). "Decolonising the Mind: The Politics of Language in African Literature". <em>Heinemann Educational Books.</em></p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">African Development Bank [AfDB]. (2019). "African Economic Outlook 2019".</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Mbembe, A. (2015). "Decolonizing Knowledge and the Question of the Archive". <em>Wits Institute for Social and Economic Research.</em></p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Makoni, S., & Meinhof, U. H. (2003). "African Literacies: Ideologies, Scripts, Education". <em>Cambridge University Press.</em></p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Alexander, N. (2003). "The African Renaissance and the Use of African Languages in Tertiary Education". <em>PRAESA Occasional Papers.</em></p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Bialystok, E., et al. (2007). "Bilingualism as a Protection Against the Onset of Symptoms of Dementia". <em>Neuropsychologia.</em></p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Dewaele, J. M. (2013). "Emotions in Multiple Languages". <em>Palgrave Macmillan.</em></p>
                </div>
        </div>
      </body>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
    )
}

export default ImportanceOfLanguageLearning;