import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import Footer from "../../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../../components/navigation-bar/navbar-primary.component";

import {motion} from "framer-motion";

import {default as brain} from "../../../assets/imgs/blog/brain-digital-art.png";
import { Link } from "react-router-dom";



function LanguageLearningBrainBenefits() {

  useEffect(() => {
    document.title = 'How Learning a New Language Benefits Your Brain | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">How Learning a New Language Benefits Your Brain</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container max-w-5xl mx-auto mt-16">
            <div className="ps-media mt-6">
                <div className="flex items-center justify-center flex-col">
                    <div className="ps-media-img-container">
                    <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                    </a>
                    </div>
                    <div id="about-us mt-6 p-8 flex items-center justify-center">
                    <p className="m-auto w-12/12 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl px-2 font-headers font-bold">
                    How Learning a New Language Benefits Your Brain
                    </p>
                    <p className="w-12/12 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-2 font-body font-semibold my-3 text-right">Tribe Contributor | April 15th, 2023 ⏱️ 5 min</p>
                    </div>
                    <img src={brain} alt="" width="75%" className="m-auto mt-2" />                    
                    <span className="hidden font-bold  mt-2 sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg 3xl:text-xl italic">Placeholder</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap justify-center items-center">
                <div className="mt-4">
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The journey of learning a new language is often framed as a practical pursuit, offering you a valuable skill set that can bolster your professional opportunities, enrich travel experiences, and facilitate cross-cultural conversations. However, the cognitive and neurological implications of acquiring a new language extend far beyond these utilitarian gains. This article aims to shed light on the myriad ways in which language learning positively influences your brain.</p>
                </div>
            </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Cognitive Flexibility and Multitasking</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Mastering a new language involves your brain seamlessly shifting between various grammatical rules, syntactic structures, and vocabulary sets. This ongoing adaptation enhances your cognitive flexibility, better equipping you to juggle multiple tasks and process diverse streams of information concurrently. Such mental agility is increasingly valuable in our multitasking-oriented society (Linck, Jaensch, & Kroll, 2021).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Enhanced Memory Function</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The rigors of learning new vocabulary and internalizing grammatical structures offer a robust mental workout. This strenuous cognitive activity fortifies your brain's memory capacity, elevating your aptitude for retaining not only linguistic data but also various other daily details (Schlegel et al., 2012).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Improved Decision-Making Abilities</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Studies indicate that bilingual or multilingual people often make more rational choices. This is possibly due to the emotional detachment that comes from thinking in a non-native language, offering a more objective lens for evaluating options. The cognitive gymnastics involved in mental translation can also encourage a more thoughtful decision-making approach, curbing impulsivity (Keysar, Hayakawa, & An, 2012).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Enhanced Problem-Solving Skills</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The challenges of language acquisition resemble solving a complicated puzzle. You're constantly making connections between words, implementing rules, and crafting coherent sentences. This hones your problem-solving abilities, equipping you with the skills to tackle and resolve a diverse array of challenges (Lehtonen et al., 2018).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Delay in Cognitive Aging and Dementia</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">One of the most compelling benefits of learning languages is its potential to delay cognitive aging and lower the risk of neurodegenerative diseases such as Alzheimer's and dementia. Research reveals that bilingual individuals generally experience the onset of dementia several years later than those who speak only one language (Bak et al., 2014).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Emotional Well-Being and Empathy</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Gaining proficiency in a new language broadens your exposure to different cultures and viewpoints, thereby boosting your emotional intelligence. Being able to articulate thoughts and feelings in an alternative language expands your emotional lexicon, even in your native language, leading to a heightened sense of empathy (Dewaele, 2013).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Increased Focus and Attention Span</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Learning to sift through the noise of unfamiliar sounds and meanings in a new language hones your brain's ability to focus on critical information while disregarding the irrelevant. This skill is readily applicable in other areas requiring keen attention to detail (Colzato et al., 2008).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Building a Resilient Brain</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The inherent cycle of mistakes and corrective learning during language acquisition fosters mental resilience, enhancing your ability to cope with errors and setbacks in other aspects of life (Dweck, 2006).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Conclusion</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The undertaking of learning a new language serves as a comprehensive training program for your brain, boosting its performance and resilience across a multitude of domains. Regardless of your age or linguistic background, adding another language to your skill set is more than a resume booster—it's a long-term investment in your cognitive and emotional health. If you're still undecided about diving into this intellectually rewarding experience, rest assured: your brain will be grateful. <Link to="/login" className="text-red font-semibold underline cursor-pointer">Give it a shot today!</Link></p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">References</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Bak, T. H., Nissan, J. J., Allerhand, M. M., & Deary, I. J. (2014). Does bilingualism influence cognitive aging? Annals of Neurology, 75(6), 959-963.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Colzato, L. S., Bajo, M. T., van den Wildenberg, W., Paolieri, D., Nieuwenhuis, S., La Heij, W., & Hommel, B. (2008). How does bilingualism improve executive control? A comparison of active and reactive inhibition mechanisms. Journal of Experimental Psychology: Learning, Memory, and Cognition, 34(2), 302.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Dewaele, J.-M. (2013). The link between foreign language classroom anxiety and psychoticism, extraversion, and neuroticism among adult Bi- and Multilinguals. The Modern Language Journal, 97(3), 670–684.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Dweck, C. S. (2006). Mindset: The new psychology of success. Random House.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Keysar, B., Hayakawa, S. L., & An, S. G. (2012). The foreign-language effect: Thinking in a foreign tongue reduces decision biases. Psychological Science, 23(6), 661-668.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Lehtonen, M., Soveri, A., Laine, A., Järvenpää, J., de Bruin, A., & Antfolk, J. (2018). Is bilingualism associated with enhanced executive functioning in adults? A meta-analytic review. Psychological Bulletin, 144(4), 394-425.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Linck, J. A., Jaensch, V. O., & Kroll, J. F. (2021). Multitasking in bilinguals: The role of cognitive control and language-specific skills. Cognitive Science, 45(3), e12955.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Schlegel, A. A., Rudelson, J. J., & Tse, P. U. (2012). White matter structure changes as adults learn a second language. Journal of Cognitive Neuroscience, 24(8), 1664-1670.</p>
                </div>
        </div>
      </body>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
    )
}

export default LanguageLearningBrainBenefits;