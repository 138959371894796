import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import Article from "../../components/cards/articles.component";
import Footer from "../../components/footer/footer.component.jsx";
import MailOptinForm from "../../components/cards/mailoptinform.component";
import {motion} from "framer-motion";


function Articles() {

  useEffect(() => {
    document.title = 'Articles | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto h-full w-screen mb-10">
          <div className="relative flex-col md:flex md:flex-row">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black shadow-2xl">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl uppercase font-bold">Articles</h1>
                      </div>
                    </div>
                  </div>
                </div>
          <Article />
          </div>
        </div>
      </main>
      <div className="mt-10 flex flex-row flex-wrap justify-center items-center">
        <MailOptinForm /> 
      </div>
      <footer className="bg-gradient-to-b from-white to-gold mt-32 w-full">
      <Footer />
      </footer>
    </motion.div>
    )
}

export default Articles;