import React, {useState, useEffect} from "react";
import { PuffLoader } from "react-spinners";
import {motion} from "framer-motion";
import SideNavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";
import AccountCard from "../../components/settings/account-card.component.jsx";
import ContactUsCard from "../../components/settings/contact-us-card.component.jsx";
import CreateProfileCard from "../../components/settings/create-profile-card.component.jsx";
import IngoziCard from "../../components/settings/ingozi-card.component.jsx";
import SubscriptionsCard from "../../components/settings/subscriptions-card.component.jsx";
import { getAuth } from "firebase/auth";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase.utils.js";

function Settings() {
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;

  const [membership, setMembership] = useState(null);

  useEffect(() => {
    document.title = 'Settings | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    const userDoc = doc(db, 'users', `${auth?.currentUser?.uid}`);

    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Assuming `membership` is a field in the user's Firestore document
        setMembership(docSnapshot.data().membership);
      }
    });

    // Cleanup
    return () => {
      unsubscribe();
    };
  }, []);
  
  useEffect(() => {
    // Make sure to handle this asynchronously
    const fetchMembership = async () => {
      if (user && user?.reloadUserInfo?.customAttributes) {
        const membership = JSON.parse(user?.reloadUserInfo?.customAttributes)?.membership
        setMembership(membership);
      } else {
        return null
      }
    }
  fetchMembership()

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [user]);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])


  return (
    loading ? 
    <div className="flex flex-row justify-center items-center h-screen">
      <PuffLoader color={'#991616'} loading={loading} size={100} /> 
    </div>
    : 

<motion.div className="bg-white h-screen" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.2 }}>
  <SideNavigationBar />
  {membership === "guest" ? <CreateProfileCard/> : <AccountCard />}
  {membership === "mabena" ? <SubscriptionsCard/> : null}
  <ContactUsCard />
  {membership === "guest" ? null : <IngoziCard/>}
</motion.div>


  )
}

export default Settings