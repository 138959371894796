import React from "react";
import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

const ProtectedRoute = ({ children }) => {
  const auth = getAuth();
  const user = auth.currentUser

  // Check if the user is authenticated from the context or from localStorage
  const isAuthenticated = user || localStorage.getItem("isAuthenticated") === "true";

  return (isAuthenticated ? children : <Navigate to="/login" />);
};

export default ProtectedRoute;
