import React, { useState, useEffect} from "react";

import "./App.css";

import "./index.css";

import {BrowserRouter} from "react-router-dom";
import {LearningMaterialsContext} from "./helpers/contexts.js";
import { OnboardingContext } from "./helpers/contexts.js";
import { AuthContextProvider, UserAuth } from "./helpers/UserAuthContextProvider";
import { InputContext } from "./helpers/context3.js";
import { LoaderContext } from "./helpers/context3.js";
import {ModalContext} from "./helpers/modalcontext";
import { ScoreContext } from "./helpers/contexts.js";
import {LMContext} from "./helpers/context3.js";
import {optionChosenContext} from "./helpers/context3.js";
import {feedbackContext} from "./helpers/context3.js";
import AnimatedRoutes from "./components/animated-routes/animated-routes.component";
import { AncestorLoaderContext } from "./helpers/context3.js";
import { CheckoutContext } from "./helpers/context3.js";
import PuffLoader from "react-spinners/PuffLoader";

function App() {
  const [onboardingState, setOnboardingState] = useState("one");
  const [learningMaterialState, setLearningMaterialState] = useState("");
  const [learningMaterialId, setLearningMaterialId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [score, setScore] = useState(0);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [optionChosen, setOptionChosen] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [ancestorLoading, setAncestorLoading] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  return (
  <BrowserRouter>  
    <div className="App">
      {
        loading ? 
        <div className="flex flex-row justify-center items-center h-screen bg-white">
          <PuffLoader color={'#991616'} loading={loading} size={100} /> 
        </div>
        : 
        <AuthContextProvider>
          <InputContext.Provider value={{inputValue, setInputValue, resultsLoading, setResultsLoading}}>
            <LoaderContext.Provider value={{loader, setLoader}}>
              <ScoreContext.Provider value={{score, setScore}}>
              <LearningMaterialsContext.Provider
                value={{
                  learningMaterialState,
                  setLearningMaterialState
                }}
              >
                <LMContext.Provider
                  value={{
                    learningMaterialId,
                    setLearningMaterialId
                  }}
                >
                    <ModalContext.Provider value={{modal, setModal}}>
                        <OnboardingContext.Provider value={{onboardingState, setOnboardingState}}>
                          <optionChosenContext.Provider value={{optionChosen, setOptionChosen}}>
                            <feedbackContext.Provider value={{feedback, setFeedback}}>
                              <AncestorLoaderContext.Provider value={{ancestorLoading, setAncestorLoading}}>
                                <CheckoutContext.Provider value={{checkout, setCheckout}}>
                                  <AnimatedRoutes />
                                </CheckoutContext.Provider>
                              </AncestorLoaderContext.Provider>
                            </feedbackContext.Provider>
                          </optionChosenContext.Provider>
                        </OnboardingContext.Provider>
                    </ModalContext.Provider>
                </LMContext.Provider>
              </LearningMaterialsContext.Provider>
              </ScoreContext.Provider>
            </LoaderContext.Provider>
          </InputContext.Provider>
        </AuthContextProvider>
      }
      </div>
    </BrowserRouter>
  );
}

export default App;
