import React from "react";
import {default as rsa} from "../../assets/imgs/flags/rsa.png"

import {UserAuth} from "../../helpers/UserAuthContextProvider";

function ProfileCard() {
    const { logout } = UserAuth();

    const handleLogout = async () => {
        try {
          await logout();
        } catch (e) {
          console.log(e.message);
        }
      };

    return (
        <div className="flex flex-col justify-center items-center mt-4">
                <div className="flex flex-col justify-center items-center">
                    {/* <img src="https://ui-avatars.com/api/?background=random&name=Isi+Zulu&color=#000000" alt="" className="rounded-full" /> */}
                    {/* <div className="mt-4">
                        <span>Joined December 2016</span>
                    </div> */}
                    <div className="flex flex-col justify-center items-center mt-4">
                        <img src={rsa} alt="" className="rounded-2xl mt-4 w-1/3 shadow-lg text-center m-2" />
                        <span>isiZulu</span>
                    </div>
                </div>
            <div className="flex flex-col flex-wrap justify-center items-center mt-4">
                <div className="flex w-full flex-row justify-around items-center">
                    <button
                        type="submit"
                        text="upgrade"
                        className="w-1/2 mx-1 mt-2 px-6 py-2 border-1 rounded-xl text-center w-min-xl
                        m-auto
                        px-6
                        py-2.5
                        text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-black hover:border-red hover:bg-black hover:border-black rounded-full text-black
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                        hover:shadow-lg
                        focus:shadow-lg focus:outline-none focus:ring-0
                        active:shadow-lg
                        transition
                        duration-150
                        ease-in-out cursor-not-allowed">
                        <span>
                            Upgrade
                        </span>
                    </button>
                    <button
                        type="submit"
                        text="settings"
                        className="w-1/2 mx-1 mt-2 px-6 py-2 border-1 rounded-xl text-center w-min-xl
                        m-auto
                        px-6
                        py-2.5
                        text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-black hover:border-red hover:bg-black hover:border-black rounded-full text-black
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                        hover:shadow-lg
                        focus:shadow-lg focus:outline-none focus:ring-0
                        active:shadow-lg
                        transition
                        duration-150
                        ease-in-out cursor-not-allowed">
                            Settings
                    </button>
                </div>  
                <button 
                onClick={handleLogout}
                className="mt-4 px-6 py-2 border-1 rounded-xl w-full text-center w-min-xl
                        m-auto
                        px-6
                        py-2.5
                        cursor-pointer text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg block pt-2 pb-2 pl-4 pr-4 font-bold rounded hover:text-white border-2 border-red hover:border-red hover:bg-red hover:border-black rounded-full text-black
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        rounded
                        shadow-md
                        hover:shadow-lg
                        focus:shadow-lg focus:outline-none focus:ring-0
                        active:shadow-lg
                        transition
                        duration-150
                        ease-in-out cursor-pointer">
                    Logout
                </button>
            </div>
        </div>
    )
}

export default ProfileCard