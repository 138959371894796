import React from "react";
import { Link } from "react-router-dom";
import africanPattern from "../../assets/imgs/patterns-2.png" // Replace with the actual image path

const BetaCard = () => {
  const cardStyle = {
    backgroundImage: `url(${africanPattern})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  
    return (
      <div className="mt-4 w-full m-auto p-8 rounded-lg text-right border border-darkgray shadow-2xl " style={cardStyle}>
        <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl"><span className="rotate-180">🌍</span>📚</div>
        <div className="pt-1 pr-2 ">
          <h1 className="font-headers text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-black text-center">
            <span className="font-semibold uppercase">Fast Fluency Africa Beta Version</span>
          </h1>
          <h3 className="font-regular text-sm sm:text-md md:text-lg lg:text-xl text-black text-center font-italic font-light">
            Welcome to the beta version of our application! As we fine tune and constantly make improvements to the overall user experience and functionality of the application we also want to give you an opportunity to get early access to new features so that you can give us feedback and help us cater directly to your needs.
          </h3>
        </div>
      </div>
    );
  };
  
  export default BetaCard;
