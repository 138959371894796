import React from "react";
import { PricingData } from "../../zombiedata/pricing.js";
import { CheckCircleIcon } from "@heroicons/react/solid";

function PlanCards() {
  return (
    <div className="h-full flex justify-center items-center px-6 py-12">
      <div className="grid lg:grid-cols-2 gap-12 lg:gap-2">
        {PricingData.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-other border border-other border-opacity-10 rounded-md shadow-xl cursor-pointer"
            >
              <div className="px-6 py-12 border-b-2 border-white">
                <h1 className="font-headers uppercase text-3xl font-semibold text-center mb-4">
                  {item.name}
                </h1>
                <div className="flex justify-center items-center">
                  <div className="flex items-start text-green">
                    <p className="text-4xl font-medium">{item.currency}</p>
                    <p className="text-7xl font-bold">{item.price}</p>
                  </div>
                  <p className="text-2xl text-black">{item.frequency}</p>
                </div>
              </div>
              <div className="p-12 bg-white">
                <ul className="space-y-3">
                  {item.features.map((feature, index) => (
                    <li key={index} className="flex items-center space-x-4">
                      <CheckCircleIcon className="w-6 h-6 text-green flex-shrink-0" />
                      <p className="text-lg text-black">{feature}</p>
                    </li>
                  ))}
                </ul>
                <form
                  name="PayFastPayNowForm"
                  action="https://www.payfast.co.za/eng/process"
                  method="post"
                  className="mt-4 w-full m-auto flex justify-center"
                >
                  <input required type="hidden" name="cmd" value="_paynow" />
                  <input
                    required
                    type="hidden"
                    name="receiver"
                    pattern="[0-9]"
                    value="15483789"
                  />
                  <input
                    type="hidden"
                    name="return_url"
                    value="https://fastfluencyafrica.com/pricing"
                  />
                  <input
                    type="hidden"
                    name="cancel_url"
                    value="https://fastfluencyafrica.com"
                  />
                  <input required type="hidden" name="amount" value="247" />
                  <input
                    required
                    type="hidden"
                    name="item_name"
                    maxLength="255"
                    value="Mabena Membership"
                  />
                  <input
                    type="hidden"
                    name="item_description"
                    maxLength="255"
                    value="The subscription for learners who just want to get by. Get unlimited and direct access to our Edutainment Vault with interactive learning content."
                  />
                  <input
                    required
                    type="hidden"
                    name="subscription_type"
                    pattern="1"
                    value="1"
                  />
                  <input
                    type="hidden"
                    name="recurring_amount"
                    value="247"
                  />
                  <input required type="hidden" name="cycles" pattern="[0-9]" value="0" />
                  <input
                    required
                    type="hidden"
                    name="frequency"
                    pattern="[0-9]"
                    value="3"
                  />
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan="2" align="center">
                          <input
                            type="image"
                            src="https://my.payfast.io/images/buttons/Subscribe/Light-Large-Subscribe.png"
                            alt="Subscribe"
                            title="Subscribe with Payfast"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PlanCards;
