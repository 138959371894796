import {default as modules} from "../assets/imgs/home/modules.jpg";
import {default as content} from "../assets/imgs/home/content.jpg";
import {default as texts} from "../assets/imgs/home/texts.jpg";
import {default as exercises} from "../assets/imgs/home/exercises.jpg";
import {default as vocabulary} from "../assets/imgs/home/vocabulary.jpg";
import {default as sessions} from "../assets/imgs/home/sessions.jpg";
import {default as units} from "../assets/imgs/home/units.jpg";

import * as IoIcons from "react-icons/io5";

export const Features = [
    // {
    //     image: modules,
    //     icon: <IoIcons.IoAlbumsSharp/>,
    //     heading: "Modules",
    //     description: "Keep up with structured learning materials curated for your every need."
    // },
    {
        image: units,
        icon: <IoIcons.IoBookOutline/>,
        heading: "Lessons",
        description: "Bite sized lessons covering key language concepts."
    },
    {
        image: texts,
        icon: <IoIcons.IoReader/>,
        heading: "Texts",
        description: "Leaf through our texts with English translations."
    },
    {
        image: exercises,
        icon: <IoIcons.IoBarbellSharp/>,
        heading: "Exercises",
        description: "Fast track fluency gains with interactive activities."
    },
    {
        image: vocabulary,
        icon: <IoIcons.IoList/>,
        heading: "Vocabulary",
        description: "Expand your vocabulary with our comprehensive lists."
    },
    {
        image: sessions,
        icon: <IoIcons.IoPeople/>,
        heading: "Sessions",
        description: "Book sessions with qualified Mother Tongue speakers."
    },    
]