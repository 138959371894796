import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import Footer from "../../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../../components/navigation-bar/navbar-primary.component";

import {motion} from "framer-motion";

import {default as clock} from "../../../assets/imgs/blog/clock.png";

import { Link } from "react-router-dom";


function IncorporatingLanguageLearning() {

  useEffect(() => {
    document.title = 'Incorporating Language Learning Into Your Busy Schedule | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">Incorporating Language Learning Into Your Busy Schedule</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container max-w-5xl mx-auto mt-16">
            <div className="ps-media mt-6">
                <div className="flex items-center justify-center flex-col">
                    <div className="ps-media-img-container">
                    <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                    </a>
                    </div>
                    <div id="about-us mt-6 p-8 flex items-center justify-center">
                    <p className="m-auto w-12/12 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl px-2 font-headers font-bold">
                    Incorporating Language Learning Into Your Busy Schedule
                    </p>
                    <p className="w-12/12 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-2 font-body font-semibold my-3 text-right">Tribe Contributor | August 21st, 2022 ⏱️ 5 min</p>
                    </div>
                    <img src={clock} alt="" width="75%" className="m-auto mt-2" />                    
                    <span className="hidden font-bold  mt-2 sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg 3xl:text-xl italic">Placeholder</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap justify-center items-center">
                <div className="mt-4">
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Learning a new language is an immensely rewarding experience that can enhance your cognitive abilities, improve your career prospects, and open doors to new cultures and friendships (1). However, the common obstacle most people face is finding the time to incorporate language learning into their already hectic schedules. If you find yourself in this boat, fret not. There are several strategies that can help you learn a new language without upending your life.</p>
                </div>
            </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Prioritize</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The first step to successfully integrating language learning into your daily routine is prioritizing it. If something is important to you, you'll find a way; if not, you'll find an excuse (2). Recognize that language learning is not just another task but an investment in your personal and professional growth (3). Once you prioritize it, you’ll start seeing pockets of time you didn't realize were there.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Time-Blocking</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Time-blocking is an effective way to designate specific chunks of time for particular activities (4). Instead of vaguely planning to practice during free moments, schedule explicit intervals on your calendar. This minimizes procrastination and ensures that you are dedicating quality time to language learning.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">The Bite-Sized Approach</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">You don't need hours each day to make significant progress. Studies have shown that breaking down study material into smaller chunks can improve retention and understanding (5). You can employ this tactic in language learning by dedicating just 15-20 minutes per day to a targeted lesson, like vocabulary or grammar.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Use Dead Time Wisely</h2>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Dead time is the time you spend waiting or commuting. Rather than scrolling aimlessly on your phone, use this time for language practice (6). You can listen to podcasts, go through flashcards, or even try speaking exercises.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">App Recommendations</h3>
                    <ul>
                        <li>Duolingo: Offers quick, game-like lessons in multiple languages (7).</li>
                        <li>Pimsleur: Excellent for auditory learning during commutes (8).</li>
                    </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Incorporate It Into Your Lifestyle</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">Learning doesn't have to be a separate, isolated activity. You can incorporate it into your daily life in the following ways:</h3>
                        <h4 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-semibold">Cooking</h4>
                        <p>Try following a recipe in your target language. This not only expands your vocabulary but also exposes you to the culture through cuisine (9).</p>
                        <h4 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-semibold">Social Media</h4>
                        <p>Follow accounts, blogs, or forums in your target language. This keeps you engaged and exposes you to colloquial expressions and slangs (10).</p>
                        <h4 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-semibold">Family Time</h4>
                        <p>If your family members are also interested in learning, make it a family activity. Children especially benefit from early exposure to languages (11).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Digital Tools for Reminders and Motivation</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Set reminders on your phone or use apps that track your study habits to maintain a consistent routine. Apps like Forest let you set focused intervals and grow a virtual tree while you study. It’s a fun way to measure productivity and stay motivated (12).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Accountability</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Staying accountable can make or break your language learning journey. Some effective ways to do this include:</h3>
                    <ul className="mt-2">
                        <li>Finding a Study Partner: A study partner can help you stay motivated and provide practice in conversation (13).</li>
                        <li>Language Meetups: Many cities have language exchange events. Websites like Meetup.com are good places to find them (14).</li>
                        <li>Online Communities: Websites like Reddit and language learning forums offer a place to share tips, ask questions, and find practice partners (15).</li>
                    </ul>
                        {/* <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">.</p> */}
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Leverage Technology</h2>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">There are a multitude of apps and online resources to assist you in language learning. Utilize them to reinforce what you've learned, practice speaking, or even get real-time corrections (16).</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl font-semibold mt-4">App Recommendations for Advanced Learning</h3>
                    <ul>
                        <li>Italki: Offers 1-on-1 lessons with native speakers (17).</li>
                        <li>Anki: A flashcard app that uses spaced repetition to enhance memory (18).</li>
                    </ul>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Realistic Goals and Celebrating Small Wins</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl text-justify mt-4">It’s easy to get overwhelmed if you aim for fluency right away. Start with smaller, attainable goals like mastering basic conversational phrases or learning 50 new words in a week (19). Celebrate these milestones; they keep you motivated and mark your progress (20).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Flexibility</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">It’s important to be flexible. You may not always be able to stick to your designated language learning time. In such cases, don’t consider the day a lost cause. Even a quick 5-minute review can be beneficial (21).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Final Thoughts</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl text-justify mt-4">Incorporating language learning into your busy schedule is not just feasible, but it can also be enjoyable and deeply rewarding. All it requires is a little planning, a dash of creativity, and a sprinkle of commitment (22). By following the strategies outlined in this article, you'll find that you can make steady progress in your language learning journey without compromising your existing obligations (23). <br /> <br /> So why wait? <Link to="/login" className="text-red underline font-semibold cursor-pointer">Start your language learning journey today</Link> and unlock the numerous benefits that come with being multilingual.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">References</h2>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">1. Bialystok, E., Craik, F. I. M., & Luk, G. (2012).Bilingualism: consequences for mind and brain. Trends in Cognitive Sciences.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">2. Covey, Stephen. (1989). The 7 Habits of Highly Effective People.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">3. Newport, Cal. (2016). Deep Work: Rules for Focused Success in a Distracted World.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">4. Morgenstern, Julie. (2000). Time Management from the Inside Out.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">5. Karpicke, J.D., & Roediger, H.L. (2007). Expanding retrieval practice promotes short-term retention, but equally spaced retrieval enhances long-term retention. Journal of Experimental Psychology.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">6. Reddy, S. (2018). Why Dead Time Is So Deadly For Your Day. Wall Street Journal.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">7. Duolingo website. <a href="www.duolingo.com" target="_blank">[www.duolingo.com]</a></p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">8. Pimsleur website. <a href="https://www.pimsleur.com/" target="_blank">[https://www.pimsleur.com/]</a></p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">9. Cervantes, J., & Rodriguez, M. (2019). Cuisine and Culture: A Case Study in Spain. International Journal of Gastronomy and Food Science.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">10. Tagg, Caroline. (2015). Exploring Digital Communication: Language in Action.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">11. Byers-Heinlein, K., & Lew-Williams, C. (2013). Bilingualism in the Early Years: What the Science Says. LEARNing Landscapes.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">12. Forest App website. <a href="https://www.forestapp.cc/" target="_blank">[https://www.forestapp.cc/]</a></p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">13. Burgoon, J.K., & Le Poire, B.A. (1993). Effects of communication expectancies, actual communication, and expectancy disconfirmation on evaluations of communicators and their communication behavior. Human Communication Research.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">14. Meetup website. <a href="https://www.meetup.com/" target="_blank">[https://www.meetup.com/]</a></p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">15. Reddit Language Learning subreddit. <a href="https://www.reddit.com/r/languagelearning/" target="_blank">[https://www.reddit.com/r/languagelearning/]</a></p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">16. Kukulska-Hulme, A., & Shield, L. (2008). An overview of mobile assisted language learning: From content delivery to supported collaboration and interaction. ReCALL.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">17. Italki website. <a href="https://www.italki.com/" target="_blank">[https://www.italki.com]</a></p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">18. Anki website. <a href="https://apps.ankiweb.net/" target="_blank">[https://apps.ankiweb.net/]</a></p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">19. Dörnyei, Z. (2001). Motivational Strategies in the Language Classroom.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">20. Locke, E.A., & Latham, G.P. (2006). New Directions in Goal-Setting Theory. Current Directions in Psychological Science.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">21. Bjork, R.A., & Kroll, J.F. (2015). Desirable Difficulties in Vocabulary Learning. The American Journal of Psychology.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">22. Sprenger, M. (2013). Teaching the Critical Vocabulary of the Common Core.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">23. Duckworth, A. (2016). Grit: The Power of Passion and Perseverance.</p>
                    <p class="text-md sm:text-md md:text-lg lg:text-xl mt-4">24. Cummins, J. (2000). Language, Power and Pedagogy: Bilingual Children in the Crossfire.</p>
                </div>
        </div>
      </body>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
    )
}

export default IncorporatingLanguageLearning;