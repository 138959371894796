import React, { useState, useEffect } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import { FFAQData } from './module-data';
import Footer from "../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../components/navigation-bar/navbar-primary.component";
import {motion} from "framer-motion";


const FFAQ = () => {
  useEffect(() => {
    document.title = 'FFAQs | Fast Fluency Africa';
  }, []);
  
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

  return (

  loading ? 
  <div className="flex flex-row justify-center items-center h-screen">
    <PuffLoader color={'#991616'} loading={loading} size={100} /> 
  </div>
  : 
    <>
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.5}}>
    <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">FFAQs</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <div className="mx-auto p-4 w-full flex flex-col justify-between items-center">
        <div className='container w-full'>
          <h2 className="text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mb-2 font-body text-left font-semibold">A comprehensive list of our most frequently asked questions</h2>
          <div className="space-y-4 flex flex-col items-center justify-center ">
            {FFAQData.map((item, index) => (
              <div key={index} className={openIndex !== index ? "transition-all duration-300 ease-in-out bg-white border-2 border-darkgray shadow-2xl rounded p-4 w-full mt-4" : "transition-all duration-300 ease-in-out bg-white border-2 border-green shadow-2xl rounded p-4 w-full mt-4"}>
                <div className="flex justify-between items-center">
                  <h2 className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl font-body font-normal">{item.question}</h2>
                  <button onClick={() => toggleAccordion(index)}>
                    {openIndex === index ? '➖' : '➕'}
                  </button>
                </div>
                <div className={`transition-all duration-300 ease-in-out ${openIndex === index ? 'max-h-64 opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}>
                  <p className="mt-2 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl font-light">{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>

    </motion.div>
    </>
  );
};

export default FFAQ;
