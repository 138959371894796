import React from "react";

import { Link } from "react-router-dom";

function CreateProfileCard() {

    return (
        <div className="flex flex-col justify-center items-center mt-4">
            <div className="flex flex-col justify-center items-center w-2/3">
                    {/* <img src="https://ui-avatars.com/api/?background=random&name=Isi+Zulu&color=#000000" alt="" className="rounded-full" /> */}
                <h1 className="font-headers text-left mt-4 w-full font-bold text-md sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">Create An Account</h1>
                    <p className="text-left w-full text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl">Sign up to access more detailed user settings.</p>
                    <Link to="/register">
                        <div className="mt-4 flex flex-col items-center justify-center">
                            <button type="submit"
                            value="update profile" className="mt-4 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl w-4/6 pt-2 pr-2 inline rounded box-border px-6 py-2 border-1 text-center cursor-pointer w-min-xl
                            m-auto cursor:pointer pb-2 pl-4 font-bold  hover:text-white border-2 border-green bg-white hover:border-green hover:bg-green text-black
                            leading-tight
                            uppercase
                            shadow-md
                            hover:shadow-lg
                            focus:shadow-lg focus:outline-none focus:ring-0
                            active:shadow-lg
                            transition
                            duration-150
                            ease-in-out" placeholder="Update profile">Sign Up To Create An Account</button>
                        </div>
                    </Link>
                    </div>
                <div className="flex flex-col flex-wrap justify-center items-center mt-4"> 
            </div>
        </div>
    )
}

export default CreateProfileCard