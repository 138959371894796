import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import {default as fluencypace} from "../../../assets/imgs/fluency.jpg";

import {default as fluencymap} from "../../../assets/imgs/fluency-map.jpg"

import MailOptinForm from "../../../components/cards/mailoptinform.component";
import Footer from "../../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../../components/navigation-bar/navbar-primary.component";

import { Link } from "react-router-dom";

import {motion} from "framer-motion";

import {default as aki} from "../../../assets/gifs/aki.gif";
import {default as lerato} from "../../../assets/imgs/lerato.jpg"

function ZuluRoadMap() {

  useEffect(() => {
    document.title = 'Why is Learning isiZulu So Easy? | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">Why is Learning isiZulu So <span className="line-through decoration-4">Hard</span> Easy?</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container max-w-5xl mx-auto mt-16">
            <div className="ps-media mt-6">
                <div className="flex items-center justify-center flex-col">
                    <div className="ps-media-img-container">
                    <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                    </a>
                    </div>
                    <div id="about-us mt-6 p-8 flex items-center justify-center">
                    <p className="m-auto w-12/12 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl px-2 font-headers font-bold">
                        Why is Learning isiZulu So <span className="line-through decoration-4">Hard</span> Easy?
                    </p>
                    <p className="w-12/12 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-2 font-body font-semibold my-3 text-right">Tribe Contributor | August 8th, 2022 ⏱️ 5 min</p>
                    </div>
                    <img src={aki} alt="" width="75%" className="m-auto mt-2" />                    
                    <span className="font-bold  mt-2 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl italic">Sawubo..sanibon...saboon...eish..mara</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap justify-center items-center">
                <div className="mt-4">
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify"> There you are just minding your own business not trying to suffer too much today on God's Green Earth.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Naturally, as is often the case throughout the course of the day, you hear the stomach rumblings and consider getting yourself something to eat.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">So you pop by your local grocery store and gather the necessary ingredients for what is sure to be a 💣💥🔥 meal.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Finally, after waiting in the queue for what felt like an eternity you get to the till.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">The moment of truth arrives.</p>
                    <div className="flex flex-col justify-center items-center">
                        <img src={lerato} alt="" className="mauto mt-6" />
                        <span className="font-bold mt-2 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-center">Notice how Lerato's confidence slowly diminishes with each speech bubble</span>
                    </div>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">What started out as a regular day for Lerato turns into a nightmare very quickly.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">But what if it didn't have to be like this?</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">If you're reading this right now then the little anecdote we shared above is probably one that you can relate to.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">I hope this helps you get started with your journey towards making those embarrassing moments at the till turn into smooth transactions that would make Gogo proud.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Before we begin I'd like to give you a brief overview of what to expect in this article.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Feel free to start with the relevant sections that jump out at you the most.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">You can always revisit the remaining sections as and when they become relevant to you.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">In this guide we are going to address the following questions:</p>
                    <ul className="italic list-inside list-decimal text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">
                       <a href="#section-one"><li className="text-red font-semibold hover:underline">What is fluency?</li></a>
                       <a href="#section-two"><li className="text-red font-semibold hover:underline">How long does it take to become fluent?</li></a>
                       <a href="#section-three"><li className="text-red font-semibold hover:underline">How much does it cost to become fluent?</li></a>
                       <a href="#section-four"><li className="text-red font-semibold hover:underline">What are the prerequisites for fluency?</li></a>
                       <a href="#section-five"><li className="text-red font-semibold hover:underline">What does the roadmap look like?</li></a>
                    </ul>
                </div>
            </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-one">I - What is fluency?</h2>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Fluency is a term that’s often misinterpreted. It doesn't mean knowing every word in the dictionary or being able to recite poetry from memory. Fluency means being able to navigate through social situations, understanding, and being understood. It's about being comfortable in the skin of another language.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-semibold" id="section-two">II - How long does fluency take?</h2>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Generally speaking the answer is: <b className="font-bold">It depends.</b></p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">
                      Many academics have researched this question under varying conditions and the consensus is that it really is up to you as an individual and how much time you spend deliberately practicing your target language.
                    </p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Additionally, it is also important to note that to the best of our knowledge, there is no specific research available as it relates to African language acquisition for non-native speaking teenagers and adults.</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Having acknowledged that we have still presented the following data below which is a summary of research conducted by Cambridge with respect to English acquisition in non-native speakers</p>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Taken from our 2021 Edutainment Curriculum based on a Cambridge study focusing on the CEFR model with Adult, Teenage, and Children learners with access to adequate resources</p>
                    <div className="flex flex-col justify-center items-center">
                        <img src={fluencypace} alt="" className="rounded m-auto mt-4 border-2 border-white dropshadow-2xl rounded" />
                        <span className="text-center font-bold mt-2 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl">Taken from our 2021 Edutainment Curriculum based on a Cambridge study focusing on the CEFR model with Adult, Teenage, and Children learners with access to adequate resources</span>
                    </div>
                </div>
                <div className="mt-4">
                  <h2 className="text-2xl font-headers font-semibold" id="section-four">III - What are the prerequisites for fluency?</h2>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">1. Accountability</h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Setting goals and periodically reviewing them isn't a recommendation; it's a necessity. You'll face forks in the road, moments when you'll want to switch directions or maybe even abandon the journey altogether. Your set goals will act as your compass, keeping you oriented and reminding you why you set forth in the first place. Without it, you risk wandering aimlessly, possibly even giving up.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">2. Subject Matter Experts (SMEs)</h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Consider these people your interpreters and guides. They understand the landscape better than anyone else; they can lead you through tricky terrains, warn you of common pitfalls, and help you avoid them. Surrounding yourself with native speakers and linguistic experts offers an authentic experience that no book or online course can provide.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Resources</h3>
                      <ul>
                        <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.italki.com/"><li>iTalki</li></a>
                      </ul>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">3. Support / Community</h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Journeying alone is perilous. It's easier to get lost, harder to find motivation, and you miss out on the joy of shared experiences. A support community, whether it's a language learning group or a forum online, provides emotional support, answers to common questions, and an avenue for practice. These companions keep your spirits high and your resolve strong.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Resources</h3>
                      <ul>
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.internations.org/"><li>InterNations</li></a>
                      </ul>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">4. Specific Solutions & Model Answers</h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Learning a language isn't a one-size-fits-all ordeal. Different people have different learning styles, strengths, and weaknesses. Tailored solutions could involve focusing on the elements of the language that are most relevant to you, or studying from sources that align with your interests to maintain enthusiasm. Model answers serve as excellent examples to emulate in specific situations.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Resources</h3>
                      <ul>
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.amazon.com/s?k=zulu+phrasebook&crid=2EZLB7C5VRZ4S&sprefix=zulu+phraseboo%2Caps%2C406&ref=nb_sb_noss"><li>Phrasebooks</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.amazon.com/s?k=zulu+dictionary&crid=M1T8B13MSRYC&sprefix=zulu+dictionary%2Caps%2C404&ref=nb_sb_ss_fb_1_15_retrain-deeppltr"><li>Dictionaries</li></a> 
                      {/* <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href=""><li>Theasauri</li></a>  */}
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.isizulu.net"><li>isiZulu.net</li></a> 
                      </ul>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">5. Learning Materials </h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">This includes dictionaries, phrasebooks, listening materials, and other sources of linguistic nutrition. A well-curated list of resources like radio stations such as UkhoziFM, or language specific Spotify Playlists, or periodicals such as Isolezwe, and Ilanga ensures you have the right tools at your disposal, and it should be dynamic—constantly updated to reflect your evolving needs.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Resources</h3>
                      <ul>
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://vamboacademy.com/"><li>Vambo Academy</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://zululessons.com/"><li>Zulu Lessons with Thando</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.ambaniafrica.com/"><li>Ambani Africa</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://angula.app/home"><li>Angula</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://uthini.co.za/"><li>Uthini</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.amazon.com/Learn-Zulu-C-L-Nyembezi/dp/0796002371"><li>CLS Nyembezi</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.poemhunter.com/llm-mbatha/poems/"><li>LLM Mbatha</li></a> 
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://mnyandu.com/publications"><li>Phiwokuhle Mnyandu</li></a> 
                      </ul>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">6. Risk Reversals / Guaranteed Success </h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Engaging with proven methods and courses reduces the risk of failure. Learning a language is tricky enough without having to worry about the efficacy of your chosen materials. Safety nets offer a way to experiment without severe consequences. Find courses with money-back guarantees or try out various methods that have been proven to work.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Resources</h3>
                      <ul>
                        <><li className="text-red text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md">As of yet, we haven't been able to find any programs or resources that have a <b>100%</b>  guaranteed success rate. We'll update this once we do.</li></>
                      </ul>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">7. Logical Structure</h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">This is the roadmap, the outline that guides you through the chaos. Having a structured approach makes it easier to track progress, measure milestones, and allocate resources effectively. It's like having a detailed itinerary for a long trip—you may divert from it occasionally, but it's invaluable for keeping you on track.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Resources</h3>
                      <ul>
                      <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.education.gov.za/Curriculum/CurriculumAssessmentPolicyStatements(CAPS).aspx"><li>South African Curriculum Assessment Policy Statements for African Home Languages</li></a> 
                      </ul>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">8. Immersive Learning Environment</h3>
                      <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Immersing yourself in the target language makes the process more natural and effective. It means creating an environment where you're constantly exposed to the language through music, films, podcasts, or conversations. This approach helps solidify what you've learned, filling in the gaps and reinforcing your linguistic scaffolding.</p>
                    <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Resources</h3>
                    <ul>
                     <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.ukhozifm.co.za/"><li>UkhoziFM</li></a>
                     <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://open.spotify.com/playlist/1JEwAQINvw5g4E3op5pOY2?si=OA_EhLMZTK2SX5XY_hyOew&utm_source=whatsapp"><li>Spotify Playlists</li></a> 
                     <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://www.isolezwe.co.za/"><li>Isolezwe</li></a>
                     <a className="text-red hover:underline text-md sm:text-md md:text-md lg:text-md xl:text-md 2xl:text-md" target="_blank" rel="noreferrer" href="https://ilanganews.co.za/"><li>Ilanga</li></a> 
                    </ul>
                </div>
                <div className="mt-4">
                  <h2 className="text-2xl font-headers font-semibold" id="section-five">IV - What does the roadmap look like?</h2>
                  <img src={fluencymap} alt="" width="75%" className="m-auto mt-2" />                    
                  <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">George</h3>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">This is where the excitement and challenge coexist in a volatile mix. You feel the weight of each mistake, the embarrassing stumbles, but you're also exhilarated by the possibilities. This phase is critical as it lays the foundation and tests your commitment..</p>
                  <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Mabena</h3>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">This is the plateau, where your understanding surpasses your speaking ability. It’s an uphill climb, and the weight of your previous excitement starts to wane. The struggle here is real, but crucial for deeper internalization.</p>
                  <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Morena</h3>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Here, you start operating with a newfound confidence. You can communicate effectively, though not perfectly. You start to build theories about language structures, observe patterns, and put them into practice.</p>
                  <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">Chief Morena</h3>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">At this stage there is no doubt about your proficiency in your target language. You are more selective and conscious of the words you use regardless of your lingering accent, when you begin a conversation you finish it with the same confidence that you use in your mother tongue. </p>
                  <h3 className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify font-bold">So, what are you waiting for? </h3>
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Start your Pan-African Pilgrimage today for <Link to="/login" className="text-red font-semibold underline cursor-pointer">free</Link> inside our Edutainment Vault.</p>
                </div>
        </div>
      </body>
      <div>
        {/* <MailOptinForm /> */}
      </div>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
      <Footer />
      </footer>
    </motion.div>
    )
}

export default ZuluRoadMap;