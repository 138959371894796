import NavigationBar from "../../components/navigation-bar-2/side-navigation-bar.component.jsx";
import { useEffect, useState } from "react";
import {motion} from "framer-motion";
import { PuffLoader } from "react-spinners";
import Skills from "../../components/content/skills/skills-primary.component.jsx"
import Units from "../../components/content/units/units.component"
import Joyride, { STATUS } from 'react-joyride';
import {db} from "../../firebase/firebase.utils.js";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { set } from "lodash";


function ContentPage() {

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    document.title = 'Vault | Fast Fluency Africa';
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const handleOnboardingUpdate = async () => {
    const onboardingRef = doc(db, 'users', `${auth.currentUser.uid}`)
    try {
      await updateDoc(onboardingRef, {
        vaultOnboardingCompleted: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const [completedWalkthrough, setCompletedWalkthrough] = useState(false);

  useEffect(() => {
    const userDoc = doc(db, 'users', `${auth.currentUser.uid}`);
    console.log(userDoc);
  
    const unsubscribe = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setCompletedWalkthrough(docSnapshot.data().vaultOnboardingCompleted);
      } 
    });
  
    return () => {
      unsubscribe();
    };
  }, []);


  const [run, setRun] = useState(null);
  const [steps, setSteps] = useState([
    {
      target: '#units',
      content: (
        <>
          <h3><b>Welcome to the Language Vault!</b></h3>
          <p>Dive into bite sized language lessons organized in units. Each unit covers specific topics to help you master the language.</p>
        </>
      ),
    },
    {
      target: '#lessons',
      content: (
        <>
          <h3><b>Level Up!</b></h3>
          <p>Hone your language skills here. Whether it's vocabulary or grammar, each lesson is designed to get you fluent faster.</p>
        </>
      ),
    },
  ]);

const handleJoyrideCallback = (data) => {
  const { status } = data;

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setRun(false);
    handleOnboardingUpdate();
  }
};

const startTour = () => {
  setRun(true);
};


  const goFFAQ = (route) => {
    const win = window.open(route, "_blank");
    win.focus();
  }

  return (
    loading ? 
    <div className="flex flex-row justify-center items-center h-screen">
      <PuffLoader color={'#991616'} loading={loading} size={100} /> 
    </div>
    : 
    <motion.div className="bg-white h-screen" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      {!completedWalkthrough ? <div id="onboarding-trigger" onClick={startTour} className="feedback-icon text-white flex flex-col items-center justify-center rounded w-12 h-12 fixed z-10 bottom-40 right-8 cursor-pointer hover:bg-green animate-bounce">
        <span className='text-3xl' title='onboarding'>🚀</span>
      </div> : null}
      <div
        className="feedback-icon text-white flex flex-col items-center justify-center rounded w-12 h-12 fixed bottom-28 right-8 z-10 cursor-pointer hover:bg-green"
        onClick={() => {goFFAQ('/ffaqs')}}
      >
        <span className='text-3xl' title='ffaqs'>🙋🏾‍♀️</span>
      </div>
      <NavigationBar className="" />
      <div className="flex flex-col bg-white">
        <section className="">
          <div className="">
            <h1 className="font-bold font-display text-center bg-green text-white border-green border-2">isiZulu Social™</h1>
          </div>
        </section>
        <section className="m-auto w-12/12 xl:w-10/12">
          <Units id="units" />
        </section>
      </div>
      <Joyride
          steps={steps}
          run={run}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              arrowColor: '#848717',
              backgroundColor: '#FCFCEE',
              overlayColor: 'rgba(79, 26, 0, 0.1)',
              primaryColor: '#000',
              textColor: '#000000',
              width: 500,
              zIndex: 1000,
              fontFamily: "Nunito",
            }
          }}
        />
    </motion.div>
  );
}

export default ContentPage;
