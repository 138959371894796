import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import PuffLoader from "react-spinners/PuffLoader";


import Hero from "../../components/cards/hero.component";
import Feature from "../../components/cards/features.component";
import Testimonial from "../../components/cards/testimonials.component";
// import Article from "../../components/cards/articles.component";
import ArticleHero from "../../components/cards/articles-hero.component"
import MailOptinForm from "../../components/cards/mailoptinform.component";
import Footer from "../../components/footer/footer.component.jsx";
import Notification from "../../components/notifications/push-notification.component"

function HomePage() {
  useEffect(() => {
    document.title = 'Fast Fluency Africa - The fun way to know African languages, cultures, and stories';
  }, []);
  const [loading, setLoading] = useState(false);


  // Create separate useInView hooks for each section
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

  return (
    loading ? 
    <div className="flex flex-row justify-center items-center h-screen">
      <PuffLoader color={'#991616'} loading={loading} size={100} /> 
    </div>
    : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
        <Notification />
        <Hero />
        <div ref={ref1} className="flex flex-row flex-wrap justify-center items-center overflow-hidden w-screen">
          <motion.h1 
            initial={{opacity: 0}}
            animate={{opacity: inView1 ? 1 : 0}}
            transition={{delay: inView1 ? 0.25 : 0}}
            className="mt-12 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8
            ">
            Achieve <span className="text-red italic">fluency fast</span> inside our Edutainment Vault
          </motion.h1>
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: inView1 ? 1 : 0}}
            transition={{delay: inView1 ? 0.25 : 0}}
            className='mt-6 flex flex-row flex-wrap justify-center items-center overflow-hidden w-screen'>
            <Feature />
          </motion.div>
        </div>
        <div ref={ref2} className="section2">
          <motion.h1 
            initial={{opacity: 0}}
            animate={{opacity: inView2 ? 1 : 0}}
            transition={{delay: inView2 ? 0.25 : 0}}
            className="mt-12 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8
            ">
            Get started <span className="text-red italic">for free</span> with some of our best content
          </motion.h1>
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: inView2 ? 1 : 0}}
            transition={{delay: inView2 ? 0.25 : 0}}
            className='mt-6 flex flex-row flex-wrap justify-center items-center overflow-hidden w-screen'>
            <ArticleHero />
          </motion.div>
        </div>
        <div ref={ref3} className="section3">
          <motion.h1 
            initial={{opacity: 0}}
            animate={{opacity: inView3 ? 1 : 0}}
            transition={{delay: inView3 ? 0.25 : 0}}
            className="mt-12 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8
            ">
            Here's what <span className="text-red">some people</span> think about us
          </motion.h1>
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: inView3 ? 1 : 0}}
            transition={{delay: inView3 ? 0.25 : 0}}
            className='mt-6 flex flex-row flex-wrap justify-center items-center overflow-hidden w-screen'>
            <Testimonial />
          </motion.div>
        </div>
        <div ref={ref4} className="section4">
          <motion.h1 
            initial={{opacity: 0}}
            animate={{opacity: inView4 ? 1 : 0}}
            transition={{delay: inView4 ? 0.25 : 0}}
            className="mt-12 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-center font-headers font-bold mx-8
            ">
            <span className="text-red">Stay connected</span> with your <span>family and friends...</span> <span className="text-red">the fun way</span>
          </motion.h1>
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: inView4 ? 1 : 0}}
            transition={{delay: inView4 ? 0.25 : 0}}
            className='mt-6 flex flex-row flex-wrap justify-center items-center overflow-hidden w-screen'>
            <MailOptinForm />
          </motion.div>
        </div>
      </main>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
  );
}

export default HomePage;
