import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";

import Footer from "../../../components/footer/footer.component.jsx";
import NavBarPrimary from "../../../components/navigation-bar/navbar-primary.component";

import {motion} from "framer-motion";

import {default as space} from "../../../assets/imgs/blog/earth-from-space.png";

import { Link } from "react-router-dom";


function LanguageLearningCulturalPreservation() {

  useEffect(() => {
    document.title = 'The Role of Language in Preserving African Cultures | Fast Fluency Africa';
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [])

    return (
      loading ? 
      <div className="flex flex-row justify-center items-center h-screen">
        <PuffLoader color={'#991616'} loading={loading} size={100} /> 
      </div>
      : 
    <motion.div className="flex flex-col justify-between items-center m-auto" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.2}}>
      <main className="flex flex-col justify-between items-center m-auto">
          <div className="relative flex-col md:flex md:flex-row shadow-2xl">
              <div className="flex flex-col items-center justify-between h-2/5">
              <div className="bg-red w-full">
                  <NavBarPrimary />
                </div>
                <div className="flex flex-row justify-start items-center p-8 w-screen bg-gradient-to-b from-red to-black">
                  <div className="flex flex-col justify-center items-center sm:w-5/6 md:w-4/6 lg:w-3/6 xl:2/6 2xl:w-1/6">
                    <div className="justify-center items-center flex flex-col w-full">
                      <br />
                      <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-white font-headers sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl uppercase font-bold">The Role of Language in Preserving African Cultures</h1>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      <body className="mx-10">
        <div className="container max-w-5xl mx-auto mt-16">
            <div className="ps-media mt-6">
                <div className="flex items-center justify-center flex-col">
                    <div className="ps-media-img-container">
                    <a href="https://omny.fm/shows/powertalk-archive/thought-council-discussing-african-knowledge-fourt" target="_blank" rel="noreferrer">
                    </a>
                    </div>
                    <div id="about-us mt-6 p-8 flex items-center justify-center">
                    <p className="m-auto w-12/12 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl px-2 font-headers font-bold">
                    The Role of Language in Preserving African Cultures
                    </p>
                    <p className="w-12/12 text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl px-2 font-body font-semibold my-3 text-right">Tribe Contributor | February 7th, 2023 ⏱️ 5 min</p>
                    </div>
                    <img src={space} alt="" width="75%" className="m-auto mt-2" />                    
                    <span className="hidden font-bold  mt-2 sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg 3xl:text-xl italic">Placeholder</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap justify-center items-center">
                <div className="mt-4">
                    <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Language is not merely a functional tool for communication; it also serves as a rich vault of culture, history, traditions, and personal and collective identities. This is particularly evident in Africa, a continent that is a linguistic mosaic with an estimated 1,250 to 2,100 distinct languages. In a world increasingly flattened by globalization, safeguarding indigenous languages becomes an imperative for the survival of diverse cultural heritages. This article delves into the pivotal role that language plays in the preservation of African cultures.</p>
                </div>
            </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-one">Language as a Foundation of Culture</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xlmt-4 text-justify">In numerous African communities, language acts as a foundational element of culture. It is the channel through which oral traditions—ranging from folk tales and myths to songs and proverbs—are conveyed from one generation to the next. These narrative forms are not mere stories; they encapsulate the philosophies, ethical guidelines, and societal norms of a community. The extinction of a language often results in the loss of the communal wisdom and distinctive worldview it carries (Crystal, 2000).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Language and Cultural Identity</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Language is fundamentally linked to one's cultural and communal identity. For instance, the Yoruba of Nigeria, the Igbo of Eastern Nigeria, and the Zulu of South Africa each have their own unique languages that serve as markers of their ethnicity. Speaking one's indigenous language becomes an assertion of membership, a linkage to ancestral roots, and a mode of expressing an identity deeply rooted in cultural heritage (Fishman, 1991).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Battling Language Attrition</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">Colonial legacies have had a damaging impact on African languages, as colonial tongues like English, French, and Portuguese were enforced as mediums for education and governance. This has contributed to the marginalization of native languages, often relegating them to informal settings and sometimes even stigmatizing them as 'lesser' or 'inferior' (Ngũgĩ wa Thiong'o, 1986).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Education and Language Conservation</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xlmt-4 text-justify">The education system holds significant promise as a medium for language conservation. In countries like South Africa, where 11 languages hold official recognition, initiatives exist to educate children in their native languages during the early educational stages. This not only aids in effective learning but also serves to preserve languages for future generations (Heugh, 2002).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Efforts in Linguistic Revitalization</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4 text-justify">In the face of looming language endangerment, numerous efforts aimed at language revitalization are underway. These include documenting languages, creating written forms for primarily oral languages, and leveraging modern technology to produce resources for language learning. These efforts often involve collaborations among non-governmental organizations, cultural bodies, and technology companies.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Role of the Diaspora</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xlmt-4 text-justify">The African diaspora contributes significantly to language preservation. Overseas African communities frequently form closely-knit cultural networks where traditional practices, including language, are faithfully transmitted to the younger generation. The internet also offers unprecedented opportunities for keeping languages alive, through social media platforms, online courses, and virtual communities (Smolicz, 1999).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Language and Economic Viability</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xlmt-4 text-justify">There is also a pragmatic dimension to language preservation. When adequately developed and promoted, indigenous languages can serve as vehicles for economic growth. They can find applications in local media, literature, and arts, thus generating employment and enriching the economy. Additionally, promoting local languages can enhance tourism, attracting visitors interested in experiencing unique cultural elements (Grin, 2003).</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Obstacles and Future Directions</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xlmt-4 text-justify">Nevertheless, challenges persist. Resource limitations, political inertia, and the pressures of globalization pose significant hurdles. Moreover, younger generations, striving to adapt to a global landscape, often prioritize international languages, sometimes to the detriment of their native languages. However, it's crucial to recognize that mastering a global language should not necessitate the abandonment of one's mother tongue.</p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">Concluding Remarks</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xlmt-4 text-justify">Language is an invaluable component of cultural heritage. Its preservation acts not just as a historical safeguard but as a mechanism to sustain a multifaceted and lively global culture. Especially in Africa, with its wide array of languages mirroring its diverse ecosystems, the urgency is palpable. As an African saying goes, "Wisdom is like a baobab tree; no one individual can embrace it." The wisdom of Africa resides in its languages, and safeguarding them is tantamount to preserving the intricate fabric of the continent's diverse cultures. You can support us in playing our small part in this group effort by using our <Link to="/login" className="text-red font-semibold underline cursor-pointer">free Edutainment Vault today!</Link> </p>
                </div>
                <div className="mt-4">
                    <h2 className="text-2xl font-headers font-bold" id="section-two">References</h2>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Crystal, D. (2000). Language Death. Cambridge University Press.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Fishman, J. A. (1991). Reversing Language Shift. Multilingual Matters.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Grin, F. (2003). Language Policy and Economics: The Econolinguistic Dimensions of Globalization. International Journal of the Sociology of Language, 159, 15-32.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Heugh, K. (2002). The Case Against Bilingual and Multilingual Education in South Africa. PRAESA Occasional Papers, No. 6.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Ngũgĩ wa Thiong'o. (1986). Decolonising the Mind: The Politics of Language in African Literature. Heinemann.</p>
                        <p className="text-md sm:text-md md:text-lg lg:text-xl mt-4">Smolicz, J. J. (1999). Education and Culture. Oxford University Press</p>
                </div>
        </div>
      </body>
      <footer className="bg-gradient-to-b from-white to-gold mt-16 w-full">
        <Footer />
      </footer>
    </motion.div>
    )
}

export default LanguageLearningCulturalPreservation;