import React, {useState, useEffect} from "react";

import {Link} from "react-router-dom";
import {UserAuth} from "../../helpers/UserAuthContextProvider";

import {motion} from "framer-motion";

import { getAuth } from "firebase/auth";

function NavigationBar() {
  const [open, setOpen] = useState(false);
  const [membership, setMembership] = useState(null);

  const auth = getAuth();
  const user = auth.currentUser;

  const { logout } = UserAuth();

  const handleLogout = async () => {
      try {
        await logout();
      } catch (e) {
        console.log(e.message);
      }
    };
  
    useEffect(() => {
      // Make sure to handle this asynchronously
      const fetchMembership = async () => {
        if (user && user?.reloadUserInfo?.customAttributes) {
          const membership = JSON.parse(user?.reloadUserInfo?.customAttributes)?.membership
          setMembership(membership);
        } else {
          return null
        }
      }
    fetchMembership()
  
  }, [user])
  


  return (
    <div>
      <header className=" bg-white shadow-md drop-shadow-xl p-2 text-lg  md:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl cursor-pointer sm:w-5/5 sm:flex sm:justify-around h-12 sm:h-14 md:h-16 lg:h-18 xl:h-20 2xl:h-22 3xl:h-24">
        <div className="flex items-center justify-center cursor-pointer">
          <div className={open ? "sm:hidden flex flex-row justify-between items-center cursor-pointer" : "sm:hidden flex flex-row justify-between items-center cursor-pointer w-full"}>
            <button
              onClick={() => setOpen(!open)}
              type="button"
              className="text-black hover:text-green focus:outline-none cursor-pointer"
            >
              <svg
                className="h-6 w-6 fill-current"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {open ? (
                  <path d="M32.3839 12.6875L23.8979 21.1715L15.4139 12.6875L12.5859 15.5155L21.0699 23.9995L12.5859 32.4835L15.4139 35.3115L23.8979 26.8275L32.3839 35.3115L35.2119 32.4835L26.7279 23.9995L35.2119 15.5155L32.3839 12.6875Z" />
                ) : (
                  <path d="M42 36H6V32H42V36ZM42 26H6V22H42V26ZM42 16H6V12H42V16Z" />
                )}
              </svg>
            </button>
            {open ? null : <p className="text-sm md:text-md xl:text-lg 2xl:text-xl 3xl:text-2xl"></p>}
          </div>
      <motion.div className={`flex flex-row justify-between w-5/5 cursor-pointer sm:flex block m-auto max-h-max block  ${open ? "block" : "hidden"
            } `} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={ {delay: 0.1}}
        >
        <div className="flex flex-row justify-around">
          <div className={`flex flex-row justify-between w-5/5 cursor-pointer sm:flex block m-auto max-h-max block  ${
              open ? "block" : "hidden"
            } `}>
            <div className="flex flex-col justify-center items-center">
              <Link to="/dashboard" className="text-center">
                <button href="#" className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <div className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">🏠</div>
                  <span className="text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-semibold hover:text-green">Home</span>
                </button>
              </Link>  
            </div>
            {/* <div className="flex flex-col justify-center items-center">
              <Link to="/courses" className="text-center">
                <button href="#" className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <Io5Icons.IoLibraryOutline />
                  <div>📚</div>
                  <span className="text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg hover:text-green">Courses</span>
                </button>
              </Link>
            </div> */}
            <div className="flex flex-col justify-center items-center">
              <Link to="/content" className="text-center">
                <button href="#" className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <div className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">🔐</div>
                  <span className="text-center text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md  2xl:text-lg font-semibold hover:text-green">Vault</span>
                </button>
              </Link>
            </div>
            {/* <div className="flex flex-col justify-center items-center">
              <Link to="/sessions" rel="noreferrer"  className="text-center">
                <button className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <div className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">💬</div>
                  <span className="text-center text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-light hover:text-green">Sessions</span>
                </button>
              </Link>
            </div> */}
            <div className="flex flex-col justify-center items-center">
              <Link to="/dictionary"  className="text-center">
                <button className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <div className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">🔍</div>
                  <span className="text-center text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-semibold hover:text-green">Dictionary</span>
                </button>
              </Link>
            </div>
            {/* {membership === "guest" || membership === "george" ? <div className="2xl:hidden flex flex-col justify-center items-center">
              <Link to="/checkout"  className="text-center">
                <button className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <div className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">🔥</div>
                  <span className="text-center text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-light hover:text-green">Upgrade</span>
                </button>
              </Link>
            </div> : null} */}
            <div className="flex flex-col justify-center items-center">
              <Link to="/settings"  className="text-center">
                <button className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <div className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">⚙️</div>
                  <span className="text-center text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-semibold hover:text-green">Settings</span>
                </button>
              </Link>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="text-center">
                <button onClick={handleLogout} className="flex flex-col items-center justify-center block px-2 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-black font-semibold rounded hover:text-green cursor-pointer">
                  <div className="text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">✌️</div>
                  <span className="text-center text-ms sm:text-ms md:text-xs lg:text-sm xl:text-md 2xl:text-lg font-semibold hover:text-green">Logout</span>
                </button>
              </div>
            </div>
          </div>
          {/* <div className="2xl:hidden flex flex-col justify-center items-center">
            <Link to="checkout">
              <button className="px-2 py-2 rounded-xl text-center cursor-pointer text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl leading-tight uppercase shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out font-body font-bold bg-green text-white hover:text-white border-green hover:border-greenthree hover:bg-greenthree">
                  Upgrade
              </button>
            </Link>
          </div> */}
        </div>
      </motion.div>
    </div>
      </header>
    </div>
  );
}

export default NavigationBar;
